// @flow
import { resetAppState } from '../global'
import { removeJwtTokens } from './localStorage'
import { clearJwtTimer } from './clearJwtTimer'
import type { DispatchFunc, GetStateFunc, Thunk } from '../../types'

export const SESSION_EXPIRED = 'SESSION_EXPIRED'

export function sessionExpired (): Thunk<void> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    removeJwtTokens()
    dispatch(clearJwtTimer())
    dispatch(resetAppState())
    dispatch({ type: SESSION_EXPIRED })
  }
}
