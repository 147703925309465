// @flow
import * as React from 'react'
import Button from '../../../../../components/button'
import styles from '../../Manage.module.css'
import SelectField from '../../../../../components/input/SelectField'
import TextField from '../../../../../components/input/TextField'
import RelatedList from './RelatedList'
import { NO_SELECTION } from '../../../../../data/constants'

type Props = {|
  names: string[],
  className?: string,
  updateChoice: (value: string) => void,
  addRelated: (e: Event) => void,
  pattern: string,
  patternTitle: string,
  selected: string,
  related: string[],
  isNumber?: boolean,
  headerText: string,
  buttonText: string,
  listText: string,
  listHeaderText: string
|}

export default function RelatedForm (props: Props) {
  const regex = new RegExp(props.pattern)
  const isValueValid = regex.test(props.selected)
  return (
    <div>
      <div>
        <header className={`${styles.inputGroupHeader} ${styles.addPaddingTop} `}>
          <label>{props.headerText}</label>
        </header>
      </div>

      <div className={styles.relatedContainer}>
        <div className={`${styles.relatedSessionColumn} ${styles.inputGroup}`}>
          {props.names.length !== 0 && <SelectField value={props.selected} onChange={(e) => props.updateChoice(e.target.value)}>
            <option value={NO_SELECTION}>Please choose...</option>
            {props.names.map(name => <option value={name} key={name}>{name}</option>)}
          </SelectField>}

          {props.names.length === 0 && <TextField
            id='name'
            type={props.isNumber ? 'number' : 'text'}
            pattern={props.pattern}
            title={props.patternTitle}
            value={props.selected}
            onChange={(e) => props.updateChoice(e.target.value)}
            className={styles.searchBox}
            autoCorrect='off'
            min={props.isNumber ? 0 : ''}
            autoCapitalize='off'
          />}
        </div>
        <div className={styles.addSessionButton}>
          <Button
            type='submit'
            className={props.className}
            onClick={props.addRelated}
            disabled={props.selected === '' || !isValueValid}
            tabIndex='1' >
            {props.buttonText}
          </Button>
        </div>
      </div>

      <div className={styles.sessionList}>
        <RelatedList names={props.related} text={props.listText} headerText={props.listHeaderText} />
      </div>
    </div>
  )
}
