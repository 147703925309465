// @flow

import * as React from 'react'
import styles from './Alert.module.css'
import cx from 'classnames'

type Props = {|
  children: React.Element,
  type: 'success' | 'error',
  className?: string
|}

class Alert extends React.Component<Props> {
  static defaultProps = { type: 'error' }

  render () {
    return (
      <div
        className={cx(
          styles.alert,
          this.props.className, {
            [styles.success]: this.props.type === 'success'
          })}
        role='alert'>
        {this.props.children}
      </div>
    )
  }
}

export default Alert
