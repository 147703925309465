// @flow
import type { Action } from '../types'
import {
  NOT_SCANNED_INTO_SESSION_MESSAGES,
  SCAN_ERROR_MESSAGES,
  WRONG_PREFIX_START,
  BULK_SCAN_FAILED
} from '../data/constants'
import { END_SESSION_ERROR } from '../actions/scan'

export function mapScanErrorMessage (action: Action): string {
  if (action.payload && action.payload.response) {
    if (action.payload.response.message) {
      const response = action.payload.response
      const message = response.message

      const isNotScannedIntoSessionError = NOT_SCANNED_INTO_SESSION_MESSAGES
        .some(error => message.includes(error))

      if (isNotScannedIntoSessionError) {
        return SCAN_ERROR_MESSAGES.NOT_SCANNED_INTO_SESSION
      } else if (message.includes(WRONG_PREFIX_START) || message.includes(BULK_SCAN_FAILED)) {
        return message
      }
    }
  }

  if (action.type === END_SESSION_ERROR) {
    return 'Failed to end session. Please try again'
  }

  return SCAN_ERROR_MESSAGES.DEFAULT
}
