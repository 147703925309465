// @flow
import React, { Component } from 'react'
import DetailPanel from '../../../../../components/layout/DetailPanel'
import RelatedRow from './RelatedRow'
import styles from './RelatedForm.module.css'

type Props = {|
  names: string[],
  text: string,
  headerText: string
|}

class RelatedList extends Component<Props> {
  render () {
    const names = this.props.names || {}
    return (
      <DetailPanel title={this.props.headerText} className={styles.mainTable}>
        {names.length > 0 &&
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={`${styles.header} ${styles.grow} ${styles.left}`}>{this.props.text}</th>
            </tr>
          </thead>
          <tbody>
            {names.map(row => <RelatedRow key={row} name={row} />)}
          </tbody>
        </table>}
      </DetailPanel>
    )
  }
}

export default RelatedList
