// @flow
import React, { Component } from 'react'
import styles from './Scan.module.css'
import TextField from '../../components/input/TextField'
import TextArea from '../../components/input/TextArea'
import Button from '../../components/button'
import cx from 'classnames'
import type { ScanFormProps } from './types'

class ScanForm extends Component<ScanFormProps> {
  render () {
    return (
      <div className={this.props.className}>
        <form id='scan-form' className={styles.form} onSubmit={this.props.onAddScan}>
          <div className={styles.inputGroup}>
            <label>Employee ID</label>
            <TextField
              onKeyPress={this.props.scanId}
              onChange={e => this.props.updateEmployeeId(e.target.value)}
              value={this.props.employeeId}
              type='text'
              id='id'
              pattern='^[Hh]{1}[A-Za-z0-9]+$'
              title='[hH][0-9A-Za-z] only, e.g. h12a34b5'
              autoCorrect='off'
              autoCapitalize='off'
              spellCheck={false}
              tabIndex='1'
              booleanFocus={true}
              required
            />
          </div>
          <div className={styles.inputGroup}>
            <label>Scanned Barcode</label>
            <TextField
              onKeyPress={this.props.scanBarcode}
              onChange={e => this.props.updateScannedBarcode(e.target.value)}
              value={this.props.scannedBarcode}
              type='text'
              id='barcode'
              pattern='^[OSEAosea]{1}[0-9A-Za-z]+$'
              title='[OSEAosea][0-9A-Za-z] only, e.g. o1a23b45'
              autoCorrect='off'
              autoCapitalize='off'
              spellCheck={false}
              tabIndex='2'
              required
            />
          </div>
          <div className={styles.inputGroup}>
            <header className={styles.inputGroupHeader}>
              <label>Note</label>
            </header>
            <TextArea
              onChange={e => this.props.updateNote(e.target.value)}
              value={this.props.note}
              className={styles.noteStyle}
              type='note'
              id='note'
              tabIndex='3'
            />
          </div>
          {this.props.showBulkTextbox && <div className={cx(styles.inputGroup, styles.total)}>
            <header className={styles.inputGroupHeader}>
              <label>Number of items completed</label>
            </header>
            <TextField
              id='total'
              type='number'
              pattern='^\\d+$'
              className={styles.total}
              title='0-9 only'
              value={this.props.totalOrders}
              onChange={(e) => this.props.updateTotalOrders(parseInt(e.target.value))}
              autoCorrect='off'
              autoCapitalize='off'
              min={1}
              required
              tabIndex='4'
            />
          </div>}
          <div className={cx(styles.checkboxGroup, { [styles.hideOption]: !this.props.showCheckbox })}>
            <header className={styles.inputGroupHeader}>
              <label>Scan all order items on this worksheet:</label>
            </header>
            <input
              id='scanAll'
              type='checkbox'
              tabIndex='4'
              className={styles.checkbox}
              value={this.props.scanAllOrderItems}
              onChange={this.props.updateCheckbox}
              checked={this.props.scanAllOrderItems} />
          </div>
        </form>

        <Button
          primary
          type='submit'
          form='scan-form'
          tabIndex='4'
          disabled={!this.props.canClickButton}
        >
          Add
        </Button>
      </div>
    )
  }
}

export default ScanForm
