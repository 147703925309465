// @flow
import { RSAA } from 'redux-api-middleware'
import { selectAuthServiceUrl } from '../../selectors/appSettings'
import type { DispatchFunc, GetStateFunc, Thunk, GetJwtResult } from '../../types'
import { PRODUCTION_ADMIN, PRODUCTION_AGENT } from '../../data/auth'
import { decodePwintyJwt } from '../../helpers/jwt'

export const REQUEST_JWT = 'REQUEST_JWT'
export const JWT_SUCCESS = 'JWT_SUCCESS'
export const JWT_ERROR = 'JWT_ERROR'
export const SET_USER_TYPE = 'SET_USER_TYPE'

export function getJwt (username: string, password: string): Thunk<GetJwtResult> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    return dispatch({
      [RSAA]: {
        endpoint: selectAuthServiceUrl() + '/api/auth',
        method: 'POST',
        body: JSON.stringify({username, password}),
        headers: { 'Content-Type': 'application/json' },
        types: [REQUEST_JWT, JWT_SUCCESS, JWT_ERROR]
      }
    })
  }
}

export function getUserDetailsFromJwt (accessToken: string) {
  const decoded = decodePwintyJwt(accessToken)

  const roles = decoded.userPrincipal.roles
  const isAdmin = roles.includes(PRODUCTION_ADMIN)

  return {
    type: SET_USER_TYPE,
    userType: isAdmin ? PRODUCTION_ADMIN : PRODUCTION_AGENT,
    userId: decoded.userPrincipal.id
  }
}
