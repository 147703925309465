// @flow
import { createSelector } from 'reselect'
import type { AppState } from '../types'
import { identity } from './helpers'

export function selectIsScanning (state: AppState): boolean {
  return state.scan.scanning
}

export function selectScanFeedback (state: AppState): string {
  return state.scan.scanFeedback
}

export function selectIsScanError (state: AppState): boolean {
  return state.scan.scanError
}

export function selectSessionConfirmed (state: AppState): boolean {
  return state.scan.sessionConfirmed
}

export const getIsScanningBarcode = createSelector(selectIsScanning, identity)
export const getScanFeedback = createSelector(selectScanFeedback, identity)
export const getIsScanError = createSelector(selectIsScanError, identity)
export const getSessionConfirmed = createSelector(selectSessionConfirmed, identity)
