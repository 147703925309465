// @flow

import { SCAN_PREFIX_TYPE } from '../data/scan'

export function getBarcodeAndType (barcode: string) {
  const prefix = barcode.charAt(0)
  const id = barcode.substring(1)

  const scanType = SCAN_PREFIX_TYPE.find(scan => scan.prefix === prefix)
  const type = scanType && scanType.type ? scanType.type : prefix

  return [ type, id ]
}

export function getBarcodeWithoutPrefix (barcode: string) {
  return barcode.substring(1)
}

export function removePrefix (barcode: string) {
  const first = barcode.charAt(0)

  if (!/\d/.test(first)) {
    return getBarcodeWithoutPrefix(barcode)
  } else {
    return barcode
  }
}

export function getSessionIcon (name: string) {
  switch (name) {
    case 'Print':
      return 'print'
    case 'Stickers':
      return 'puzzle-piece'
    case 'Rolling':
      return 'file'
    case 'Cards/Journals':
      return 'book'
    case 'Framing':
      return 'square-o'
    case 'Kitting':
      return 'gavel'
    case 'Canvas Stretching':
      return 'photo'
    case 'Acrylics/Magnets':
      return 'th-large'
    case 'Packaging':
      return 'envelope'
    case 'Warehouse':
      return 'industry'
    case 'Office':
      return 'building'
    case 'Dispatch':
      return 'truck'
    default:
      return 'industry'
  }
}
