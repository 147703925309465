// @flow

export const ORDER_ITEM_DETAILS = 'orderItemDetails'
export const EQUIPMENT_DETAILS = 'equipmentDetails'
export const SESSION_ORDER_ITEMS = 'sessionOrderItems'
export const ITEM_SCAN_SUMMARY = 'itemScanSummary'

export const SPLASH_ACTION_IDS = {
  ORDER_ITEM_DETAILS: ORDER_ITEM_DETAILS,
  EQUIPMENT_DETAILS: EQUIPMENT_DETAILS,
  SESSION_ORDER_ITEMS: SESSION_ORDER_ITEMS,
  ITEM_SCAN_SUMMARY: ITEM_SCAN_SUMMARY
}
