// @flow
import {
  IDLE,
  LOADING
} from '../data/rsaa'
import {
  SET_RSAA_STATUS,
  RESET_RSAA_STATUS,
  SAVE_IN_FLIGHT_REQUEST_CONTROLLER,
  HARD_RESET_RSAA_STATUS
} from '../actions/rsaa'

import type { RsaaState, Action } from '../types'

const defaultStatus = { status: IDLE, statusMessage: '', ignore: false }

const refs = {
  auth: [
    'logIn',
    'labAlias',
    'revokeToken'
  ],
  search: [
    'orderSearch',
    'sessionDetails',
    'currentLocation',
    'currentActivity',
    'activitySession',
    'orderItemScanCheck'
  ],
  employee: [
    'details'
  ],
  scan: [
    'endUserSession',
    'addNewScan'
  ],
  splash: [
    'orderItemDetails',
    'equipmentUpdate',
    'ordersBySession',
    'itemScanSummary'
  ]
}

function getLoggingState () {
  let initialState = {}

  for (const type in refs) {
    initialState[type] = {}
    refs[type].forEach(x => {
      initialState[type][x] = defaultStatus
    })
  }
  return initialState
}

const initialState = {
  ...getLoggingState(),
  inFlightRequestControllers: {}
}

function rsaa (state: RsaaState = initialState, action: Action): RsaaState {
  switch (action.type) {
    case SET_RSAA_STATUS: {
      const ref = action.ref
      const statusMessage = action.statusMessage
      const description = action.description
      const reducerName = action.reducerName

      return {
        ...state,
        [reducerName]: {
          ...state[reducerName],
          [ref]: {
            status: description,
            statusMessage: statusMessage || state[reducerName][ref].statusMessage
          }
        }
      }
    }

    case RESET_RSAA_STATUS: {
      const ref = action.ref
      const reducer = findRsaaReducerName(state, action.ref)

      if (!reducer) {
        return state
      }

      if (state[reducer][ref].status !== LOADING) {
        return {
          ...state,
          [reducer]: {
            ...state[reducer],
            [ref]: defaultStatus
          }
        }
      }

      return {
        ...state,
        [reducer]: {
          ...state[reducer],
          [ref]: {
            ...state[reducer][ref],
            ignore: true
          }
        }
      }
    }

    case SAVE_IN_FLIGHT_REQUEST_CONTROLLER: {
      return {
        ...state,
        inFlightRequestControllers: {
          ...state.inFlightRequestControllers,
          [action.requestType]: action.abortController
        }
      }
    }

    case HARD_RESET_RSAA_STATUS: {
      if (state[action.ref]) {
        return {
          ...state,
          [action.ref]: defaultStatus
        }
      }
      return state
    }

    default: {
      return state
    }
  }
}

function findRsaaReducerName (state: RsaaState, actionRef: string): ?string {
  return Object.keys(state).find(x => state[x].hasOwnProperty(actionRef))
}

export default rsaa
