// @flow
import React, { PureComponent } from 'react'
import type { Node } from 'react'
import cx from 'classnames'

import styles from './Modal.module.css'

export type ModalProps = {|
  close: () => void, children: Node,
  className?: string,
  backgroundClassName?: string
|}

export const ESCAPE_KEY_CODE = 27

export default class Modal extends PureComponent<ModalProps> {
  componentDidMount = () => {
    document.addEventListener('keydown', this.onKeyDown)

    if (document.body) {
      document.body.style.overflow = 'hidden'
    }
  }

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.onKeyDown)

    if (document.body) {
      document.body.style.overflow = 'auto'
    }
  }

  onKeyDown = (e: KeyboardEvent) => e.which === ESCAPE_KEY_CODE && this.props.close()

  onContainerClick = (e: SyntheticMouseEvent<HTMLInputElement>) => {
    const isCursorPointingAtScrollbar = e.clientX > e.currentTarget.clientWidth

    if (!isCursorPointingAtScrollbar) {
      this.props.close()
    }
  }

  onContentClick = (e: MouseEvent) => e.stopPropagation()

  render () {
    return (
      <React.Fragment>
        <div className={cx(styles.background, this.props.backgroundClassName)} />
        <div className={styles.container} onMouseDown={this.onContainerClick}>
          <div className={cx(styles.content, this.props.className)} onMouseDown={this.onContentClick}>
            {this.props.children}
          </div>
        </div>
      </React.Fragment>
    )
  }
}
