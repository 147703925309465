// @flow
import React from 'react'
import WhitePanel from '../../../../components/layout/WhitePanel'
import styles from '../Splash.module.css'
import type { EquipmentStatus as Status } from '../../../../types'
import EquipmentStatusRow from './equipmentStatusRow'

type Props = {|
  equipment: Status[]
|}

export default function EquipmentStatus ({ equipment }: Props) {
  return (
    <div className={styles.margin}>
      <WhitePanel heading='Equipment Status'>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={`${styles.header} ${styles.left} `}>Equipment Name</th>
              <th className={`${styles.header} ${styles.left}`}>Status</th>
              <th className={`${styles.header} ${styles.left}`}>Last Updated</th>
              <th className={`${styles.header} ${styles.left}`}>Note</th>
            </tr>
          </thead>
          <tbody>
            {equipment.map(row => <EquipmentStatusRow key={row.equipmentName} data={row} />)}
          </tbody>
        </table>
      </WhitePanel>
    </div>
  )
}
