// @flow
import { LOADING, ERROR, SUCCESS } from '../data/rsaa'
import type { RsaaStatus, RsaaStatusProps, AbortController, DefaultRsaaStatus } from '../types'

export const SET_RSAA_STATUS = 'SET_RSAA_STATUS'
export const RESET_RSAA_STATUS = 'RESET_RSAA_STATUS'
export const SAVE_IN_FLIGHT_REQUEST_CONTROLLER = 'SAVE_IN_FLIGHT_REQUEST_CONTROLLER'
export const HARD_RESET_RSAA_STATUS = 'HARD_RESET_RSAA_STATUS'

export function setRsaaStatus (status: RsaaStatus) {
  return {
    type: SET_RSAA_STATUS,
    ref: status.ref,
    description: status.description,
    statusMessage: status.statusMessage,
    reducerName: status.reducerName
  }
}

export function resetRsaaStatus (ref: string) {
  return {
    type: RESET_RSAA_STATUS,
    ref
  }
}

export function mapRsaaStateToProps (rsaa: DefaultRsaaStatus): RsaaStatusProps {
  return {
    loading: rsaa.status === LOADING,
    error: rsaa.status === ERROR,
    success: rsaa.status === SUCCESS,
    statusMessage: rsaa.statusMessage || ''
  }
}

export function saveInFlightRequestController (requestType: string, abortController: AbortController) {
  return {
    type: SAVE_IN_FLIGHT_REQUEST_CONTROLLER,
    requestType,
    abortController
  }
}

export function hardResetRsaaStatus (ref: string) {
  return {
    type: HARD_RESET_RSAA_STATUS,
    ref
  }
}
