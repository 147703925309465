// @flow
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import type { AppState, DispatchFunc } from '../../types'
import type { ComponentType } from 'react'
import { getHasLabAlias } from '../../selectors/auth'
import { getRsaaStatus } from '../../selectors/rsaa'
import LoadingIndicator from '../LoadingIndicator'
import { getUserLab } from '../../actions/auth'
import { RSAA_STATUS } from '../../data/rsaa'

import styles from './WithLabAlias.module.css'

type DispatchProps = {|
  fetchLabAlias: () => void
|}

type StateProps = {|
  hasLabAlias: boolean,
  labAliasRsaaStatus: string
|}

type WithLabAliasProps = {|
  ...DispatchProps,
  ...StateProps,
|}

export default function withLabAlias (WrappedComponent: ComponentType<*>): ComponentType<*> {
  class WithLabAlias extends PureComponent<WithLabAliasProps> {
    componentDidMount () {
      if (this.props.labAliasRsaaStatus === RSAA_STATUS.IDLE) {
        this.props.fetchLabAlias()
      }
    }

    render () {
      const { hasLabAlias, labAliasRsaaStatus, ...restProps } = this.props

      if (this.props.hasLabAlias) {
        return <WrappedComponent {...restProps} />
      }

      return (
        <div className={styles.container}>
          <LoadingIndicator />
        </div>
      )
    }
  }

  function mapStateToProps (state: AppState): StateProps {
    return {
      labAliasRsaaStatus: getRsaaStatus(state, 'auth', 'labAlias').status,
      hasLabAlias: getHasLabAlias(state)
    }
  }

  function mapDispatchToProps (dispatch: DispatchFunc): DispatchProps {
    return {
      fetchLabAlias: () => dispatch(getUserLab())
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithLabAlias)
}
