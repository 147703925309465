// @flow
import { setRsaaStatus, resetRsaaStatus } from '../actions/rsaa'
import { auth } from '../reducers/rsaa/auth'
import { search } from '../reducers/rsaa/search'
import { scan } from '../reducers/rsaa/scan'
import { splash } from '../reducers/rsaa/splash'
import type { MiddlewareAPI, Dispatch } from 'redux'
import type { RsaaStatus, AppState, Action } from '../types'

export default (store: MiddlewareAPI<AppState, Action>) => (next: Dispatch<Action>) => (action: Action) => {
  const dispatch = store.dispatch
  const state = store.getState()

  const status = reduce(
    action,
    state,
    auth,
    scan,
    search,
    splash
  )

  if (status && Object.keys(status).length > 0) {
    const ignore = state.rsaa[status.reducerName][status.ref].ignore
    const nexRsaaAction = next(action)

    dispatch(setRsaaStatus(status))
    if (ignore) {
      dispatch(resetRsaaStatus(status.ref))
    }

    return nexRsaaAction
  }

  return next(action)
}

function reduce (action, state, ...reducers) {
  return reducers.map((x, i) => formatResult(x(action, state), state)).reduce((x, y) => !x ? y : x)
}

function formatResult (action: ?string[], state: AppState): ?RsaaStatus {
  if (!action) {
    return null
  }

  const rsaaState = state.rsaa
  const rsaaRef = action[0]
  const reducer = Object.keys(rsaaState).find(reducer => Boolean(rsaaState[reducer] && rsaaState[reducer][rsaaRef]))

  if (!reducer) {
    throw new Error(`Reducer not found. Action: ${JSON.stringify(action)}`)
  }

  return {
    ref: action[0],
    description: action[1],
    statusMessage: action[2] || '',
    reducerName: reducer
  }
}
