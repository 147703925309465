// @flow

import React, { Component } from 'react'
import styles from './ScanTimers.module.css'

type Props = {|
  session: string,
  sessionTime: string
|}

class ScanTimersView extends Component<Props> {
  render () {
    return (
      <div>
        <p className={styles.heading}>
          You are currently scanned into the following session:
        </p>

        <p className={styles.subTitle}>Session: {this.props.session}</p>
        <p>Duration: {this.props.sessionTime}</p>
      </div>
    )
  }
}

export default ScanTimersView
