// @flow
import React from 'react'
import Button from '../../../../components/button'
import type { Dictionary, EmployeeDetails } from '../../../../types'
import WhitePanel from '../../../../components/layout/WhitePanel'
import styles from '../Manage.module.css'
import TextField from '../../../../components/input/TextField'
import ChooseLabAlias from '../ChooseLabAlias'

type Props = {|
  employee: EmployeeDetails,
  labs: Dictionary<string>,
  addEmployee: (employee: EmployeeDetails) => void,
  updateAdd: (name: string, value: string) => void
|}

export default function AddEmployee ({ employee, labs, addEmployee, updateAdd }: Props) {
  return (
    <div className={styles.addPadding}>
      <WhitePanel heading='Add Agent'>
        <form id='add-form' onSubmit={addEmployee}>
          <div className={styles.content}>
            <div className={styles.inputGroup}>
              <header className={styles.inputGroupHeader}>
                <label>Name</label>
              </header>
              <TextField
                id='name'
                type='text'
                pattern='^[A-Za-z0-9 ]+$'
                title='A-Z, a-z and 0-9 only'
                value={employee.name}
                onChange={(e) => updateAdd('name', e.target.value)}
                className={styles.searchBox}
                autoCorrect='off'
                autoCapitalize='off'
                required
              />
            </div>

            <ChooseLabAlias
              labs={labs}
              labAlias={employee.labAlias}
              updateLabAlias={(value: string) => updateAdd('labAlias', value)} />
          </div>
        </form>
        <div>
          <Button primary type='submit' form='add-form' tabIndex='1' >
            Add Employee
          </Button>
        </div>
      </WhitePanel>
    </div>
  )
}
