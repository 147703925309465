// @flow
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'
import SearchController from './SearchController'
import type { DispatchFunc } from '../../../types'
import { compose } from 'redux'
import WithLabAlias from '../../../components/hoc/WithLabAlias'

function mapDispatchToProps (dispatch: DispatchFunc) {
  return {
    redirectAction: (address: string) => {
      dispatch(routerActions.replace(address))
    }
  }
}

export default compose(
  WithLabAlias,
  connect(null, mapDispatchToProps)
)(SearchController)
