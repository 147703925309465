// @flow
import React, { Component } from 'react'
import styles from '../Search.module.css'
import Button from '../../../../components/button'
import TextField from '../../../../components/input/TextField'
import type { EmployeeSearchDetails, Feedback as FeedbackStatus } from '../../../../types'
import Feedback from '../../../../components/layout/Feedback'
import SearchTable from './SearchTable'

type Props = {|
  employeeDetails: EmployeeSearchDetails,
  searchFeedback: FeedbackStatus,
  employeeSearch: string,
  isSuccess: boolean,
  updateQuery: (query: string) => void,
  searchEmployee: (e: SyntheticInputEvent<HTMLInputElement>) => void
|}

class EmployeeSearchView extends Component<Props> {
  render () {
    const { employeeSearch, searchFeedback, isSuccess, employeeDetails } = this.props

    const hasDetails = employeeDetails.name !== ''

    return (
      <div className={styles.content}>
        <form
          id='employee-search'
          onSubmit={this.props.searchEmployee}
          className={styles.leftColumn}>
          <div className={styles.inputGroup}>
            <TextField
              type='text'
              pattern='^[A-Za-z0-9 ]+$'
              title='A-Z, a-z and 0-9 only'
              value={employeeSearch}
              onChange={(e) => this.props.updateQuery(e.target.value)}
              className={styles.searchBox}
              placeholder='Please enter an employee name'
              autoCorrect='off'
              autoCapitalize='off'
              required
            />
          </div>
          <Button
            className={styles.button}
            primary
            type='submit'
            form={'employee-search'}
            tabIndex='1' >
            Search
          </Button>
        </form>
        {searchFeedback && searchFeedback.message === ''
          ? isSuccess && hasDetails && <SearchTable employeeDetails={employeeDetails} />
          : <div className={styles.feedback}>
            <Feedback message={searchFeedback.message} hasScanError={searchFeedback.hasError} />
          </div>}
      </div>
    )
  }
}

export default EmployeeSearchView
