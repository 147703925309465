// @flow
import React, { Component } from 'react'
import Page from '../../../components/layout/Page'
import Tabs from '../../../components/Tabs'
import type { TabItem } from '../../../components/Tabs'
import { Route } from 'react-router-dom'
import Employee from './Employee'
import Session from './Session'
import Equipment from './Equipment'
import Activity from './Activity'
import Order from './Order'
import type { Match } from '../../../types'

type Props = {|
  match: Match,
  tabs: TabItem[]
|}

class ManageView extends Component<Props> {
  render () {
    const { match, tabs } = this.props

    return (
      <Page title='Manage Database'>
        <Tabs items={tabs} />

        <React.Fragment>
          <Route path={`${match.url}/employee`} render={() => <Employee />}/>
          <Route path={`${match.url}/session`} render={() => <Session />}/>
          <Route path={`${match.url}/equipment`} render={() => <Equipment />}/>
          <Route path={`${match.url}/activity`} render={() => <Activity />}/>
          <Route path={`${match.url}/order`} render={() => <Order />}/>
        </React.Fragment>
      </Page>
    )
  }
}

export default ManageView
