// @flow
import { RSAA } from 'redux-api-middleware'
import type { DispatchFunc, GetStateFunc } from '../../types'
import { selectUserId, getLabAlias } from '../../selectors/auth'

export const FETCH_USER_LAB = 'FETCH_USER_LAB'
export const FETCH_USER_LAB_SUCCESS = 'FETCH_USER_LAB_SUCCESS'
export const FETCH_USER_LAB_ERROR = 'FETCH_USER_LAB_ERROR'

export function getUserLab () {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const userLab = getLabAlias(getState())

    if (userLab === '') {
      const userId = selectUserId(getState())
      const endpoint = process.env.REACT_APP_TRACKING_SERVICE || ''

      try {
        await dispatch({
          [RSAA]: {
            endpoint: `${endpoint}/search/getLabAlias?id=${userId}`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [ FETCH_USER_LAB, FETCH_USER_LAB_SUCCESS, FETCH_USER_LAB_ERROR ]
          }
        })
      } catch (err) {
        console.log(err)
      }
    }
  }
}
