// @flow
import React from 'react'
import Button from '../../../../components/button'
import WhitePanel from '../../../../components/layout/WhitePanel'
import styles from '../Manage.module.css'
import TextField from '../../../../components/input/TextField'
import RelatedActivityForm from '../RelatedForm/RelatedActivityForm'
import ChooseLabAlias from '../ChooseLabAlias'
import type { Dictionary } from '../../../../types'
import { NO_SELECTION } from '../../../../data/constants'

type Props = {|
  labs: Dictionary<string>,
  equipment: string,
  addEquipment: (e: any) => void,
  activityNames: string[],
  updateActivityChoice: (value: string) => void,
  updateAdd: (value: string) => void,
  addRelatedActivity: (e: Event) => void,
  activity: string,
  relatedActivities: string[],
  labAlias: string,
  updateLabAlias: (value: string) => void
|}

export default function AddEquipment (props: Props) {
  const disableButton = props.labAlias === '' ||
    props.labAlias === NO_SELECTION ||
    props.equipment === '' ||
    props.relatedActivities.length === 0

  return (
    <div className={styles.addPadding}>
      <WhitePanel heading='Add Equipment'>
        <form id='add-form' onSubmit={props.addEquipment}>
          <div className={styles.content}>
            <div className={styles.inputGroup}>
              <header className={styles.inputGroupHeader}>
                <label>Equipment Name</label>
              </header>
              <TextField
                id='equipmentName'
                type='text'
                pattern='^[0-9A-Za-z ]+$'
                title='A-Z, a-z and 0-9 only'
                value={props.equipment}
                onChange={(e) => props.updateAdd(e.target.value)}
                className={styles.searchBox}
                autoCorrect='off'
                autoCapitalize='off'
                required
              />
            </div>

            <ChooseLabAlias
              labAlias={props.labAlias}
              updateLabAlias={props.updateLabAlias}
              labs={props.labs} />

            <RelatedActivityForm
              activityNames={props.activityNames}
              updateActivityChoice={props.updateActivityChoice}
              addRelatedActivity={props.addRelatedActivity}
              activity={props.activity}
              relatedActivities={props.relatedActivities} />
          </div>
        </form>
        <div>
          <Button primary type='submit' form='add-form' tabIndex='1' disabled={disableButton}>
            Add Equipment
          </Button>
        </div>
      </WhitePanel>
    </div>
  )
}
