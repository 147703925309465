// @flow
import { RSAA } from 'redux-api-middleware'
import type { DispatchFunc, EmployeeDetails } from '../types'
import { PRODUCTION_AGENT_TYPE } from '../data/auth'

export const ADD_EMPLOYEE = 'ADD_EMPLOYEE'
export const ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS'
export const ADD_EMPLOYEE_ERROR = 'ADD_EMPLOYEE_ERROR'
export const REMOVE_EMPLOYEE = 'REMOVE_EMPLOYEE'
export const REMOVE_EMPLOYEE_SUCCESS = 'REMOVE_EMPLOYEE_SUCCESS'
export const REMOVE_EMPLOYEE_ERROR = 'REMOVE_EMPLOYEE_ERROR'

export function addEmployee (employee: EmployeeDetails) {
  return async (dispatch: DispatchFunc) => {
    const endpoint = process.env.REACT_APP_TRACKING_SERVICE || ''

    try {
      await dispatch({
        [RSAA]: {
          endpoint: `${endpoint}/update/addEmployee`,
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            name: employee.name,
            labAlias: employee.labAlias,
            employeeType: PRODUCTION_AGENT_TYPE
          }),
          types: [ ADD_EMPLOYEE, ADD_EMPLOYEE_SUCCESS, ADD_EMPLOYEE_ERROR ]
        }
      })
    } catch (err) {
      console.log(err)
    }
  }
}

export function removeEmployee (employeeBarcode: string) {
  return async (dispatch: DispatchFunc) => {
    const endpoint = process.env.REACT_APP_TRACKING_SERVICE || ''

    try {
      await dispatch({
        [RSAA]: {
          endpoint: `${endpoint}/update/removeEmployee`,
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ employeeBarcode }),
          types: [ REMOVE_EMPLOYEE, REMOVE_EMPLOYEE_SUCCESS, REMOVE_EMPLOYEE_ERROR ]
        }
      })
    } catch (err) {
      console.log(err)
    }
  }
}
