// @flow

import React from 'react'
import Radium from 'radium'

const primaryStyle = {
  border: '1px solid grey',
  boxSizing: 'border-box',
  display: 'inline-block',
  fontStyle: 'normal',
  fontWeight: 'normal',
  backgroundColor: 'var(--color-white)',
  paddingTop: '0.5em',
  paddingBottom: '0.5em',
  paddingLeft: '0.5em',
  paddingRight: '0.5em',
  outline: 0,
  width: '100%',
  height: '4rem',
  WebkitAppearance: 'none',
  resize: 'none',
  ':focus': {
    border: '1px solid var(--default-blue)',
    outline: '1px solid var(--default-blue)'
  }
}

const upper = {
  textTransform: 'uppercase'
}

type Props = {|
  style: any,
  uppercase: boolean,
  name?: string,
  placeholder?: string,
  type: string
|}

type State = {|
  id: string
|}

function getId ({ name, placeholder, type, ...rest }: Props): string {
  return `${(name || placeholder || 'no_name').toLowerCase().replace(/\s+/g, '_')}_${type || 'text'}_field`
}

class TextArea extends React.Component<Props, State> {
  state = { id: getId(this.props) }

  render () {
    const { style, uppercase, ...otherProps } = this.props
    return <textarea
      id={this.state.id}
      spellCheck={false}
      type='text'
      style={[primaryStyle, style, uppercase ? upper : {}]} {...otherProps} />
  }
}
export default Radium(TextArea)
