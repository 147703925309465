// @flow
import React, { Component } from 'react'
import type { ChildrenArray, Element } from 'react'
import styles from './DetailPanel.module.css'

type Props = {|
  children: ChildrenArray,
  title: Element,
  summary?: string,
  className?: string,
|}

class DetailPanel extends Component<Props> {
  render () {
    return (
      <div className={styles.container} >
        <div className={styles.header}>
          <h6 className={styles.title}>{this.props.title}</h6>
        </div>
        {this.props.summary &&
          <div>
            {this.props.summary}
          </div>
        }
        <div className={this.props.className ? this.props.className : styles.body}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default DetailPanel
