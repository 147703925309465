// @flow
import React, { Component } from 'react'
import type { EquipmentStatus } from '../../../../types'
import styles from '../Splash.module.css'
import StatusIcon from '../../../../components/StatusIcon'
import { getDateTimeInLocal } from '../../../../helpers/date'

type Props = {|
  data: EquipmentStatus
|}

export default class EquipmentStatusRow extends Component<Props> {
  render () {
    const { data } = this.props
    const dateTime = data.date + ' ' + data.time
    const formatted = getDateTimeInLocal(dateTime)

    return (
      <tr className={styles.row} key={data.equipmentName}>
        <td className={`${styles.data} ${styles.left}`}>{data.equipmentName}</td>
        <td className={`${styles.data} ${styles.middle}`}>
          <StatusIcon color={data.statusColour} />
        </td>
        <td className={`${styles.data} ${styles.left} ${styles.date}`}>{formatted.time} {formatted.date}</td>
        <td className={`${styles.data} ${styles.left} ${styles.fixedWidth}`}>{data.note}</td>
      </tr>
    )
  }
}
