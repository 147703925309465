// @flow
import type { Action, AppState } from '../../types'
import { LOADING, ERROR, SUCCESS } from '../../data/rsaa'
import {
  FETCH_ORDER_SEARCH,
  FETCH_ORDER_SEARCH_SUCCESS,
  FETCH_ORDER_SEARCH_ERROR,
  FETCH_EMPLOYEE_SEARCH,
  FETCH_EMPLOYEE_SEARCH_SUCCESS,
  FETCH_EMPLOYEE_SEARCH_ERROR,
  FETCH_CURRENT_LOCATION,
  FETCH_CURRENT_LOCATION_SUCCESS,
  FETCH_CURRENT_LOCATION_ERROR,
  FETCH_ACTIVITY_SESSION,
  FETCH_ACTIVITY_SESSION_SUCCESS,
  FETCH_ACTIVITY_SESSION_ERROR,
  FETCH_ORDER_SCAN_CHECK,
  FETCH_ORDER_SCAN_CHECK_SUCCESS,
  FETCH_ORDER_SCAN_CHECK_ERROR
} from '../../actions/search'
import { FETCH_SESSION, FETCH_SESSION_SUCCESS, FETCH_SESSION_ERROR } from '../../actions/session'

export function search (action: Action, state: AppState): ?string[] {
  switch (action.type) {
    case FETCH_ORDER_SCAN_CHECK: {
      return ['orderItemScanCheck', LOADING]
    }

    case FETCH_ORDER_SCAN_CHECK_SUCCESS: {
      return ['orderItemScanCheck', SUCCESS]
    }

    case FETCH_ORDER_SCAN_CHECK_ERROR: {
      return ['orderItemScanCheck', ERROR]
    }

    case FETCH_ACTIVITY_SESSION: {
      return ['activitySession', LOADING]
    }

    case FETCH_ACTIVITY_SESSION_SUCCESS: {
      return ['activitySession', SUCCESS]
    }

    case FETCH_ACTIVITY_SESSION_ERROR: {
      return ['activitySession', ERROR]
    }

    case FETCH_CURRENT_LOCATION: {
      return ['currentLocation', LOADING]
    }

    case FETCH_CURRENT_LOCATION_SUCCESS: {
      return ['currentLocation', SUCCESS]
    }

    case FETCH_CURRENT_LOCATION_ERROR: {
      return ['currentLocation', ERROR]
    }

    case FETCH_ORDER_SEARCH: {
      return ['orderSearch', LOADING]
    }

    case FETCH_ORDER_SEARCH_SUCCESS: {
      return ['orderSearch', SUCCESS]
    }

    case FETCH_ORDER_SEARCH_ERROR: {
      return ['orderSearch', ERROR]
    }

    case FETCH_SESSION: {
      return ['sessionDetails', LOADING]
    }

    case FETCH_SESSION_SUCCESS: {
      return ['sessionDetails', SUCCESS]
    }

    case FETCH_SESSION_ERROR: {
      return ['sessionDetails', ERROR]
    }

    case FETCH_EMPLOYEE_SEARCH: {
      return ['details', LOADING]
    }

    case FETCH_EMPLOYEE_SEARCH_SUCCESS: {
      return ['details', SUCCESS]
    }

    case FETCH_EMPLOYEE_SEARCH_ERROR: {
      return ['details', ERROR]
    }

    default: {
      return null
    }
  }
}
