// @flow
import React from 'react'
import { Redirect } from 'react-router-dom'

type Props = {|
  isAdmin: boolean
|}

export function getHomePagePath (isAdmin: boolean): string {
  if (isAdmin) {
    return '/splash'
  } else {
    return '/scan'
  }
}

class HomePage extends React.Component<Props> {
  render () {
    return <Redirect to={getHomePagePath(this.props.isAdmin)} />
  }
}

export default HomePage
