// @flow
import * as React from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import FontAwesome from 'react-fontawesome'
import styles from './Navigation.module.css'
import { getHomePagePath } from '../homePage/HomePage'

type Props = {|
  isMobileExpanded: boolean,
  onClickMobileToggle: () => void,
  onShouldCollapseMobile: (name: string) => void,
  isAdmin: boolean
|}

function NavigationHeader (props: Props) {
  const navigationLogo = props.isMobileExpanded ? '/img/prodigi-logo-mark-white.svg' : '/img/prodigi-logo-mark.svg'

  return (
    <header className={styles.header}>
      <div>
        {props.isMobileExpanded &&
          <button className={cx(styles.headerAction, styles.closeAction)} onClick={props.onClickMobileToggle}>
            <img className={styles.menuCloseIcon} src='/img/close-white.svg' alt=''/>
          </button>
        }
        {(!props.isMobileExpanded) &&
          <button className={styles.headerAction} onClick={props.onClickMobileToggle}>
            <FontAwesome name='bars' size='lg' />
          </button>
        }
      </div>
      <Link className={styles.link} to={getHomePagePath(props.isAdmin)} onClick={props.onShouldCollapseMobile}>
        <div>
          <img className={styles.logoFull} src='/img/prodigi-logo-dark.svg' alt='Prodigi' width='151.82' height='59.98' />
          <img className={styles.logoCompact} src={navigationLogo} alt='Prodigi' width='26' height='36' />
        </div>
      </Link>
    </header>
  )
}

export default NavigationHeader
