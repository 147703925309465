// @flow
import { RSAA } from 'redux-api-middleware'
import type { DispatchFunc, ThunkAsync, AddLabOrderResponse, AddLabOrderItem } from '../types'

export const ADD_LAB_ORDER = 'ADD_LAB_ORDER'
export const ADD_LAB_ORDER_SUCCESS = 'ADD_LAB_ORDER_SUCCESS'
export const ADD_LAB_ORDER_ERROR = 'ADD_LAB_ORDER_ERROR'

export function addLabOrder (labOrderId: number, labAlias: string, created: string, labOrderItems: AddLabOrderItem[]): ThunkAsync<?AddLabOrderResponse> {
  return async (dispatch: DispatchFunc) => {
    const endpoint = process.env.REACT_APP_TRACKING_SERVICE || ''

    const body = {
      prodigiLabOrderId: labOrderId,
      labAlias,
      created,
      labOrderItems
    }

    await dispatch({
      [RSAA]: {
        endpoint: `${endpoint}/laborders`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
        types: [ ADD_LAB_ORDER, ADD_LAB_ORDER_SUCCESS, ADD_LAB_ORDER_ERROR ]
      }
    })
  }
}
