// @flow

import ScanTimersController from './ScanTimersController'
import { maxSessionTimeElapsed } from '../../../actions/session'
import { connect } from 'react-redux'
import type { DispatchFunc } from '../../../types'

function mapDispatchToProps (dispatch: DispatchFunc) {
  return {
    maxSessionTimeElapsed: () => dispatch(maxSessionTimeElapsed())
  }
}

export default connect(null, mapDispatchToProps)(ScanTimersController)
