// @flow
import React, { Component } from 'react'
import type { OrderSearchResults, Feedback, RsaaStatusProps } from '../../../../types'
import OrderSearchView from './OrderSearchView'

type Props = {|
  searchResults: ?OrderSearchResults,
  searchForOrder: (orderSearch: string) => void,
  resetState: () => void,
  searchFeedback: Feedback,
  requestStatus: RsaaStatusProps,
  resetOrderSearchFeedback: () => void
|}

type State = {|
  orderSearch: string,
  needsReset: boolean
|}

class OrderSearchController extends Component<Props, State> {
  state = {
    orderSearch: '',
    needsReset: false
  }

  componentDidMount () {
    this.props.resetState()
    this.props.resetOrderSearchFeedback()
  }

  checkReset = () => {
    if (this.state.needsReset) {
      this.props.resetOrderSearchFeedback()
      this.props.resetState()
      this.setState({ needsReset: false })
    }
  }

  updateQuery = (query: string) => {
    this.checkReset()
    this.setState({ orderSearch: query })
  }

  searchOrder = (e: SyntheticInputEvent<HTMLInputElement>) => {
    e.preventDefault()
    const { orderSearch } = this.state
    if (orderSearch !== '') {
      this.props.resetOrderSearchFeedback()
      this.props.searchForOrder(orderSearch)
      this.setState({ needsReset: true })
    }
  }

  render () {
    return (
      <OrderSearchView
        searchResults={this.props.searchResults}
        searchFeedback={this.props.searchFeedback}
        orderSearch={this.state.orderSearch}
        requestStatus={this.props.requestStatus}
        updateQuery={this.updateQuery}
        searchOrder={this.searchOrder} />
    )
  }
}

export default OrderSearchController
