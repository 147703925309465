// @flow

import type { PageAction } from '../types'

import * as React from 'react'
import Title from '../../../Title'
import Button from '../../../button'
import styles from './styles.module.css'

type Props = {|
  title: string,
  titleAccessory?: React.Element,
  description?: string,
  backAction?: PageAction,
  primaryAction?: PageAction,
  secondaryActions?: Array<PageAction>
|}

function PageHeader (props: Props) {
  return (
    <header className={styles.header}>
      {props.backAction && (
        <div className={styles.back}>
          <Button plain icon='arrow-left' onClick={props.backAction.onClick}>{props.backAction.title}</Button>
        </div>
      )}
      <div className={styles.titleContainer}>
        <Title>{props.title}</Title>
        {props.titleAccessory && props.titleAccessory()}
      </div>
      {props.description && <p className={styles.description}>{props.description}</p>}
      {(props.primaryAction || props.secondaryActions) && (
        <div className={styles.actionsContainer}>
          {props.primaryAction && (
            <Button
              primary
              destructive={props.primaryAction.isDestructive}
              icon={props.primaryAction.icon}
              iconPosition={props.primaryAction.iconPosition}
              disabled={props.primaryAction.disabled}
              onClick={props.primaryAction.onClick}
            >
              {props.primaryAction.title}
            </Button>
          )}
          {props.secondaryActions && props.secondaryActions.map((action, i) => (
            <Button
              destructive={action.isDestructive}
              icon={action.icon}
              iconPosition={action.iconPosition}
              onClick={action.onClick}
              disabled={action.disabled}
              key={action.title}
            >
              {action.title}
            </Button>
          ))}
        </div>
      )}
    </header>
  )
}

export default PageHeader
