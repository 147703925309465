// @flow
import type { Store } from 'redux'
import { createStore, applyMiddleware } from 'redux'
import rootReducer from './reducers/rootReducer'
import { persistStore } from 'redux-persist'
import { createBrowserHistory } from 'history'
import { composeWithDevTools } from 'redux-devtools-extension'

import thunkMiddleware from 'redux-thunk'
import { routerMiddleware } from 'react-router-redux'
import { apiMiddleware } from 'redux-api-middleware'
import rsaaMiddleware from './middleware/rsaaMiddleware'
import authMiddleware from './middleware/authMiddleware'
import handleRefreshTokenErrors from './middleware/handleRefreshTokenErrors'
import retryUnauthorisedRequests from './middleware/retryUnauthorisedRequests'
import abortInFlightRequests from './middleware/abortInFlightRequests'
import { TEST } from './data/constants'
import type { AppState, Action } from './types'
import reduxLoggerMidware from './middleware/reduxLoggerMidware'

export const history = createBrowserHistory()
const routerMidware = routerMiddleware(history)

export default function configureStore (): Store<AppState, Action> {
  const testMode = process.env.REACT_APP_ENV === TEST

  const middleware = [
    thunkMiddleware,
    abortInFlightRequests,
    retryUnauthorisedRequests,
    handleRefreshTokenErrors,
    authMiddleware,
    apiMiddleware,
    rsaaMiddleware,
    testMode ? reduxLoggerMidware : null,
    routerMidware
  ]

  let storeEnhancer = applyMiddleware(...middleware.filter(Boolean).filter(m => m !== null))

  if (testMode) {
    storeEnhancer = composeWithDevTools(storeEnhancer)
  }

  const store = createStore(rootReducer, storeEnhancer)
  persistStore(store)
  return store
}
