// @flow
import React from 'react'
import RelatedForm from './components/RelatedForm'

type Props = {|
  sessionNames: string[],
  updateSessionChoice: (value: string) => void,
  addRelatedSession: (e: Event) => void,
  session: string,
  relatedSessions: string[]
|}

export default function RelatedSessionForm (props: Props) {
  return (
    <RelatedForm
      names={props.sessionNames}
      updateChoice={props.updateSessionChoice}
      addRelated={props.addRelatedSession}
      selected={props.session}
      related={props.relatedSessions}
      headerText={'Add Related Sessions'}
      buttonText={'Add Session'}
      pattern='^[A-Za-z ]+$'
      patternTitle='A-Z and a-z only'
      listText={'Session name'}
      listHeaderText={'Related sessions'} />
  )
}
