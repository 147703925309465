// @flow
import type { Action, AppState } from '../../types'
import getErrorMessage from './getErrorMessage'
import { LOADING, ERROR, SUCCESS } from '../../data/rsaa'
import {
  LOG_IN,
  LOG_IN_SUCCESS,
  LOG_IN_ERROR,
  REVOKE_ACCESS_TOKEN,
  REVOKE_ACCESS_TOKEN_SUCCESS,
  REVOKE_ACCESS_TOKEN_ERROR,
  FETCH_USER_LAB,
  FETCH_USER_LAB_SUCCESS,
  FETCH_USER_LAB_ERROR
} from '../../actions/auth'

export function auth (action: Action, state: AppState): ?string[] {
  switch (action.type) {
    case FETCH_USER_LAB: {
      return ['labAlias', LOADING]
    }

    case FETCH_USER_LAB_SUCCESS: {
      return ['labAlias', SUCCESS]
    }

    case FETCH_USER_LAB_ERROR: {
      return ['labAlias', ERROR]
    }

    case LOG_IN: {
      return ['logIn', LOADING]
    }

    case LOG_IN_SUCCESS: {
      return ['logIn', SUCCESS]
    }

    case LOG_IN_ERROR: {
      return ['logIn', ERROR, getErrorMessage(action)]
    }

    case REVOKE_ACCESS_TOKEN: {
      return ['revokeToken', LOADING]
    }

    case REVOKE_ACCESS_TOKEN_SUCCESS: {
      return ['revokeToken', SUCCESS]
    }

    case REVOKE_ACCESS_TOKEN_ERROR: {
      return ['revokeToken', ERROR, getErrorMessage(action)]
    }

    default: {
      return null
    }
  }
}
