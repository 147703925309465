// @flow
import * as React from 'react'
import Button from '../../../../../components/button'
import styles from './OrderItemForm.module.css'
import TextField from '../../../../../components/input/TextField'
import type { AddLabOrderItem, ItemDetails } from '../../../../../types'
import RelatedOrderItemList from './RelatedOrderItemList'
import cx from 'classnames'

type Props = {|
  updateItemId: (value: string) => void,
  updateProductionSku: (value: string) => void,
  updateCopies: (value: number) => void,
  addRelated: (e: Event) => void,
  itemDetails: ItemDetails,
  labOrderItemDetails: AddLabOrderItem[]
|}

export default function RelatedOrderItemForm (props: Props) {
  const numberPattern = '^\\d+$'
  const skuPattern = '^[0-9A-Za-z-]+$'
  const numberRegex = new RegExp(numberPattern)
  const skuRegex = new RegExp(skuPattern)

  const { itemId, productionSku, copies } = props.itemDetails

  const canSubmitForm = numberRegex.test(itemId) &&
    skuRegex.test(productionSku) &&
    numberRegex.test(copies.toString()) &&
    copies > 0 &&
    itemId !== ''

  return (
    <div>
      <div>
        <header className={`${styles.inputGroupHeader} ${styles.addPaddingTop} ${styles.addOrderItemHeader}`}>
          <label>{'Add Lab Order Items'}</label>
        </header>
      </div>

      <div className={styles.itemDetailsContainer}>
        <div className={`${styles.formContainer} ${styles.inputGroup}`}>
          <header className={styles.inputGroupHeader}>
            <label>Lab Order Item Id</label>
          </header>
          <TextField
            id='name'
            type='number'
            pattern={numberPattern}
            title='0-9 only'
            value={itemId}
            onChange={(e) => props.updateItemId(e.target.value)}
            className={styles.inputBox}
            autoCorrect='off'
            min={0}
            autoCapitalize='off'
          />
        </div>

        <div className={`${styles.formContainer} ${styles.inputGroup}`}>
          <header className={styles.inputGroupHeader}>
            <label>Production Sku</label>
          </header>
          <TextField
            id='productionSku'
            type='text'
            pattern={skuPattern}
            title='A-Z, a-z and 0-9 only'
            value={productionSku}
            onChange={(e) => props.updateProductionSku(e.target.value)}
            className={styles.inputBox}
            autoCorrect='off'
            autoCapitalize='off'
          />
        </div>

        <div className={cx(styles.formContainer, styles.inputGroup, styles.copies)}>
          <header className={styles.inputGroupHeader}>
            <label>Copies</label>
          </header>
          <TextField
            id='copies'
            type='number'
            pattern={numberPattern}
            title='0-9 only'
            value={copies}
            onChange={(e) => props.updateCopies(e.target.value)}
            className={styles.copies}
            min={1}
          />
        </div>

        <div className={styles.addItemButton}>
          <Button
            type='submit'
            onClick={props.addRelated}
            disabled={!canSubmitForm}
            tabIndex='1' >
            Add Item
          </Button>
        </div>
      </div>

      <div className={styles.addedItems}>
        <RelatedOrderItemList relatedItems={props.labOrderItemDetails} />
      </div>
    </div>
  )
}
