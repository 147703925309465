// @flow
import React, { Component } from 'react'
import styles from './EmployeeSearch.module.css'
import type { EmployeeSearchDetails } from '../../../../types'
import DetailPanel from '../../../../components/layout/DetailPanel'

type Props = {|
  employeeDetails: EmployeeSearchDetails
|}

class SearchTable extends Component<Props> {
  render () {
    const { employeeDetails } = this.props

    return (
      <div className={styles.table}>
        <DetailPanel title='Employee Details'>
          <table>
            <thead />
            <tbody>
              <tr>
                <th className={`${styles.header} ${styles.left} `}>Name</th>
                <td className={`${styles.data} ${styles.left}`}>{employeeDetails.name}</td>
              </tr>
              <tr>
                <th className={`${styles.header} ${styles.left}`}>Lab</th>
                <td className={`${styles.data} ${styles.left}`}>{employeeDetails.lab}</td>
              </tr>
              <tr>
                <th className={`${styles.header} ${styles.left}`}>Barcode</th>
                <td className={`${styles.data} ${styles.left}`}>{employeeDetails.barcode}</td>
              </tr>
            </tbody>
          </table>
        </DetailPanel>
      </div>
    )
  }
}

export default SearchTable
