// @flow
import React, { Component } from 'react'
import type { Match } from '../../../types'
import { ADMIN_NAVIGATION_PATHNAMES } from '../../../data/auth'
import ManageView from './ManageView'
import type { TabItem } from '../../../components/Tabs'

type Props = {|
  match: Match,
  redirectAction: (address: string) => void
|}

class ManageController extends Component<Props> {
  componentDidMount () {
    const { redirectAction } = this.props
    redirectAction(ADMIN_NAVIGATION_PATHNAMES.Session)
  }

  componentDidUpdate () {
    const { redirectAction } = this.props
    if (window.location.pathname === '/manage') {
      redirectAction(ADMIN_NAVIGATION_PATHNAMES.Session)
    }
  }

  get tabs (): TabItem[] {
    return [
      { title: 'Session', path: ADMIN_NAVIGATION_PATHNAMES.Session },
      { title: 'Equipment', path: ADMIN_NAVIGATION_PATHNAMES.Equipment },
      { title: 'Activity', path: ADMIN_NAVIGATION_PATHNAMES.Activity },
      { title: 'Order', path: ADMIN_NAVIGATION_PATHNAMES.Order },
      { title: 'Employee', path: ADMIN_NAVIGATION_PATHNAMES.Employee }
    ]
  }

  render () {
    return (
      <ManageView
        match={this.props.match}
        tabs={this.tabs} />
    )
  }
}

export default ManageController
