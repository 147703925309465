// @flow
import { RSAA } from 'redux-api-middleware'
import type { DispatchFunc, GetStateFunc } from '../types'
import { checkIsRequired } from '../helpers/checkIsRequired'
import { getEndOfWorkFlowSessions } from '../selectors/search'

export const ADD_EQUIPMENT = 'ADD_EQUIPMENT'
export const ADD_EQUIPMENT_SUCCESS = 'ADD_EQUIPMENT_SUCCESS'
export const ADD_EQUIPMENT_ERROR = 'ADD_EQUIPMENT_ERROR'
export const REMOVE_EQUIPMENT = 'REMOVE_EQUIPMENT'
export const REMOVE_EQUIPMENT_SUCCESS = 'REMOVE_EQUIPMENT_SUCCESS'
export const REMOVE_EQUIPMENT_ERROR = 'REMOVE_EQUIPMENT_ERROR'
export const EQUIPMENT_IS_REQUIRED = 'EQUIPMENT_IS_REQUIRED'

export function addEquipment (equipmentName: string, relatedActivities: string[], labAlias: string) {
  return async (dispatch: DispatchFunc) => {
    const endpoint = process.env.REACT_APP_TRACKING_SERVICE || ''

    try {
      await dispatch({
        [RSAA]: {
          endpoint: `${endpoint}/update/addEquipment`,
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ equipmentName, relatedActivities, labAlias }),
          types: [ ADD_EQUIPMENT, ADD_EQUIPMENT_SUCCESS, ADD_EQUIPMENT_ERROR ]
        }
      })
    } catch (err) {
      console.log(err)
    }
  }
}

export function removeEquipment (equipmentName: string) {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const endpoint = process.env.REACT_APP_TRACKING_SERVICE || ''
    const endOfWorkFlowSessions = getEndOfWorkFlowSessions(getState())

    if (checkIsRequired(equipmentName, endOfWorkFlowSessions)) {
      dispatch({
        type: EQUIPMENT_IS_REQUIRED
      })
    }

    try {
      await dispatch({
        [RSAA]: {
          endpoint: `${endpoint}/update/removeEquipment`,
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ equipmentName }),
          types: [ REMOVE_EQUIPMENT, REMOVE_EQUIPMENT_SUCCESS, REMOVE_EQUIPMENT_ERROR ]
        }
      })
    } catch (err) {
      console.log(err)
    }
  }
}
