// @flow
import React, { Component } from 'react'
import { Redirect, matchPath } from 'react-router-dom'
import type { ChildrenArray } from 'react'
import type { RouterHistory, Match, Location } from 'react-router'
import { findPaths } from '../../helpers/reactChildren'
import { ADMIN_NAVIGATION_PATHNAMES } from '../../data/auth'
import { values } from '../../helpers/dictionary'
import PageNotFound from '../PageNotFound'
const REDIRECT = 'redirect'

type Props = {|
  isAdmin: boolean,
  match: Match,
  location: Location,
  history: RouterHistory,
  isUserLoggedIn: boolean,
  redirectPath: string,
  children: ChildrenArray
|}

type State = {|
  protectedPaths: string[]
|}

export default class AuthenticatedRouteGuard extends Component<Props, State> {
  state = { protectedPaths: [] }

  get isCurrentPathProtected (): boolean {
    return this.state.protectedPaths.some(path => matchPath(window.location.pathname, path))
  }

  get fullRedirectPath (): string {
    const savePreviousLocationInUrl = window.location.pathname !== '/'

    if (savePreviousLocationInUrl) {
      return this.props.redirectPath + `?${REDIRECT}=` + encodeURIComponent(window.location.pathname + window.location.search)
    }

    return this.props.redirectPath
  }

  get isCurrentlyOnRedirectPath () {
    return window.location.pathname === this.props.redirectPath
  }

  get isLocationValid (): boolean {
    const isAdminRoute = values(ADMIN_NAVIGATION_PATHNAMES).includes(window.location.pathname)

    if (isAdminRoute) {
      if (!this.props.isAdmin) {
        return false
      }
    }

    return true
  }

  componentDidMount () {
    this.setProtectedPaths()
  }

  setProtectedPaths = () => {
    this.setState({ protectedPaths: findPaths(this.props.children).map(path => path.value) })
  }

  render () {
    if (this.isCurrentPathProtected && this.props.isUserLoggedIn && this.isLocationValid) {
      return this.props.children
    } else if (this.isCurrentPathProtected && this.isLocationValid) {
      return <Redirect to={this.fullRedirectPath} />
    } else if (!this.isLocationValid) {
      return <PageNotFound />
    } else {
      return null
    }
  }
}
