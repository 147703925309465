// @flow
import type { GetStateFunc, DispatchFunc } from '../../types'
import { getTimeoutIdById } from '../../selectors/splash'

export const REFRESH_REQUIRED = 'REFRESH_REQUIRED'
export const RESET_REFRESH = 'RESET_REFRESH'

export function clearRefresh () {
  return {
    type: RESET_REFRESH
  }
}

export function setRefreshRequired (id: string) {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const timeoutId = getTimeoutIdById(getState(), id)
    const isValidLocation = window.location.pathname === '/dashboard' ||
      window.location.pathname === '/splash'

    if (isValidLocation && timeoutId) {
      dispatch({
        type: REFRESH_REQUIRED,
        id
      })
    }
  }
}
