// @flow

import { RSAA } from 'redux-api-middleware'
import type { DispatchFunc } from '../../types'
import { getBarcodeAndType, getBarcodeWithoutPrefix } from '../../helpers/scan'
import { getCurrentLocation } from '../search'
import { SCAN_TYPES } from '../../data/scan'

export const START_SCAN = 'START_SCAN'
export const STOP_SCAN = 'STOP_SCAN'
export const ADD_SCANNED_ORDER = 'ADD_SCANNED_ORDER'
export const ADD_SCAN_START = 'ADD_SCAN_START'
export const ADD_SCAN_SUCCESS = 'ADD_SCAN_SUCCESS'
export const ADD_SCAN_RESULT = 'ADD_SCAN_RESULT'
export const ADD_SCAN_ERROR = 'ADD_SCAN_ERROR'
export const RESET_SCAN_FEEDBACK = 'RESET_SCAN_FEEDBACK'
export const RESET_SESSION_CONFIRMED = 'RESET_SESSION_CONFIRMED'
export const SESSION_CONFIRMED = 'SESSION_CONFIRMED'
export const NOT_SCANNED_INTO_SESSION = 'NOT_SCANNED_INTO_SESSION'

export function notScannedIntoSession () {
  return {
    type: NOT_SCANNED_INTO_SESSION
  }
}

export function resetSessionConfirmed () {
  return {
    type: RESET_SESSION_CONFIRMED
  }
}

export function sessionConfirmed () {
  return {
    type: SESSION_CONFIRMED
  }
}

export function startScan () {
  return {
    type: START_SCAN
  }
}

export function stopScan () {
  return {
    type: STOP_SCAN
  }
}

export function resetScanFeedback () {
  return {
    type: RESET_SCAN_FEEDBACK
  }
}

export function addBulkScan (id: string, scannedBarcode: string, note: string, total: number) {
  return async (dispatch: DispatchFunc) => {
    const barcode = getBarcodeWithoutPrefix(scannedBarcode)
    const employeeId = getBarcodeWithoutPrefix(id)
    const endpoint = process.env.REACT_APP_TRACKING_SERVICE || ''

    try {
      const result = await dispatch({
        [RSAA]: {
          endpoint: `${endpoint}/scan/bulk`,
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ barcode, employeeId, note, total }),
          types: [ ADD_SCAN_START, ADD_SCAN_SUCCESS, ADD_SCAN_ERROR ]
        }
      })

      if (result.type === ADD_SCAN_SUCCESS) {
        dispatch({
          type: ADD_SCAN_RESULT,
          payload: result.payload.data,
          scanType: SCAN_TYPES.BULK
        })
      }

      dispatch(getCurrentLocation(id))
    } catch (err) {
      console.log(err)
    }
  }
}

export function addScan (id: string, barcode: string, note: string, scanAllOrderItems: boolean) {
  return async (dispatch: DispatchFunc) => {
    const [ type, scannedBarcode ] = getBarcodeAndType(barcode)
    const userId = getBarcodeWithoutPrefix(id)

    if (type === SCAN_TYPES.ORDER && scanAllOrderItems) {
      dispatch(addToDatabase(scannedBarcode, userId, SCAN_TYPES.WORKSHEET, note, id))
    } else if (type === SCAN_TYPES.ORDER) {
      dispatch(addToDatabase(scannedBarcode, userId, SCAN_TYPES.ORDER_ITEM, note, id))
    } else {
      dispatch(addToDatabase(scannedBarcode, userId, type, note, id))
    }
  }
}

export function addToDatabase (barcode: string, employeeId: string, type: string, note: string, id: string) {
  return async (dispatch: DispatchFunc) => {
    const endpoint = process.env.REACT_APP_TRACKING_SERVICE || ''

    try {
      const result = await dispatch({
        [RSAA]: {
          endpoint: `${endpoint}/scan/${type}`,
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ barcode, employeeId, note }),
          types: [ ADD_SCAN_START, ADD_SCAN_SUCCESS, ADD_SCAN_ERROR ]
        }
      })

      if (result.type === ADD_SCAN_SUCCESS) {
        dispatch({
          type: ADD_SCAN_RESULT,
          payload: result.payload.data,
          scanType: type
        })
      }

      if (type !== 'equipment') {
        dispatch(getCurrentLocation(id))
      }
    } catch (err) {
      console.log(err)
    }
  }
}
