// @flow
import {
  FETCH_EQUIPMENT_UPDATE,
  FETCH_ORDER_ITEM_DETAILS,
  FETCH_SESSION_ORDER_ITEMS,
  FETCH_ITEM_SCAN_SUMMARY
} from '../actions/splash'

export const abortActionsForLogout = [
  FETCH_EQUIPMENT_UPDATE,
  FETCH_ORDER_ITEM_DETAILS,
  FETCH_SESSION_ORDER_ITEMS,
  FETCH_ITEM_SCAN_SUMMARY
]
