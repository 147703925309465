// @flow
import React, { Component } from 'react'
import Page from '../../../components/layout/Page'
import Tabs from '../../../components/Tabs'
import { Route } from 'react-router-dom'
import Order from './Order'
import Employee from './Employee'
import type { Match } from '../../../types'
import type { TabItem } from '../../../components/Tabs'

type Props = {|
  match: Match,
  tabs: TabItem[]
|}

class SearchView extends Component<Props> {
  render () {
    const { match, tabs } = this.props

    return (
      <Page title='Search'>
        <Tabs items={tabs} />

        <React.Fragment>
          <Route path={`${match.url}/order`} exact render={() => <Order />}/>
          <Route path={`${match.url}/employee`} exact render={() => <Employee />}/>
        </React.Fragment>
      </Page>
    )
  }
}

export default SearchView
