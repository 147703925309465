// @flow
import { createSelector } from 'reselect'
import { mapRsaaStateToProps } from '../actions/rsaa'
import { returnSecondArg, identity } from './helpers'
import type { InFlightRequestControllers, AppState, DefaultRsaaStatus, AbortController } from '../types'

function selectRsaaStatus (state: AppState, category: string, ref: string): DefaultRsaaStatus {
  return state.rsaa[category][ref]
}

export function selectInFlightRequestControllers (state: AppState): InFlightRequestControllers {
  return state.rsaa.inFlightRequestControllers
}

export function selectInFlightRequestController (controllers: InFlightRequestControllers, actionType: string): ?AbortController {
  return controllers[actionType]
}

export const getRsaaStatus = createSelector(selectRsaaStatus, identity)
export const getRsaaStatusProps = createSelector(selectRsaaStatus, mapRsaaStateToProps)
export const getInFlightRequestController = createSelector(selectInFlightRequestControllers, returnSecondArg, selectInFlightRequestController)
