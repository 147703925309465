// @flow
import { connect } from 'react-redux'
import EmployeeSearchController from './EmployeeSearchController'
import type { AppState, DispatchFunc, EmployeeSearchDetails, Feedback } from '../../../../types'
import { searchForEmployee, resetState, resetEmployeeSearchFeedback } from '../../../../actions/search'
import { getEmployeeSearch } from '../../../../selectors/search'
import { getEmployeeSearchFeedback } from '../../../../selectors/feedback'
import { compose } from 'redux'
import WithLabAlias from '../../../../components/hoc/WithLabAlias'
import { getRsaaStatusProps } from '../../../../selectors/rsaa'

type StateProps = {|
  employeeDetails: EmployeeSearchDetails,
  searchFeedback: Feedback,
  isSuccess: boolean
|}

function mapStateToProps (state: AppState): StateProps {
  const rsaaStatus = getRsaaStatusProps(state, 'employee', 'details')
  return {
    employeeDetails: getEmployeeSearch(state),
    searchFeedback: getEmployeeSearchFeedback(state),
    isSuccess: rsaaStatus.success
  }
}

function mapDispatchToProps (dispatch: DispatchFunc) {
  return {
    searchForEmployee: (employeeName: string) => dispatch(searchForEmployee(employeeName)),
    resetState: () => dispatch(resetState()),
    resetEmployeeSearchFeedback: () => dispatch(resetEmployeeSearchFeedback())
  }
}

export default compose(
  WithLabAlias,
  connect(mapStateToProps, mapDispatchToProps)
)(EmployeeSearchController)
