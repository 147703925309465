// @flow
import React, { Component } from 'react'
import styles from './defaultDashboard.module.css'
import Page from '../../components/layout/Page'
import ItemScanSummary from '../Dashboard/Splash/components/ItemScanSummary'
import type { Dictionary, Overview, ItemScanSummary as ScanSummary } from '../../types'
import SessionOverview from './SessionOverview'

type Props = {|
  sessionOrderItems: Dictionary<Overview>,
  scanSummary: ?ScanSummary,
  screen: number
|}

class DefaultDashboardView extends Component<Props> {
  render () {
    const { screen, scanSummary } = this.props
    return (
      <Page title='Dashboard'>
        <div>
          {screen === 0 && <div className={styles.content}>
            {scanSummary && <div>
              <ItemScanSummary itemScanSummary={scanSummary} />
            </div>}
          </div>}

          {screen === 1 && Object.keys(this.props.sessionOrderItems).length > 0 &&
          <div className={styles.content}>
            <SessionOverview sessions={this.props.sessionOrderItems}/>
          </div>}
        </div>
      </Page>
    )
  }
}

export default DefaultDashboardView
