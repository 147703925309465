// @flow
import React, { Component } from 'react'
import type { Dictionary, BarcodeFeedback, AddLabOrderItem, ItemDetails } from '../../../../types'
import styles from '../Manage.module.css'
import Feedback from '../../../../components/layout/Feedback'
import TextField from '../../../../components/input/TextField'
import WhitePanel from '../../../../components/layout/WhitePanel'
import Button from '../../../../components/button'
import ChooseLabAlias from '../ChooseLabAlias'
import RelatedOrderItemForm from '../RelatedForm/OrderItemForm/RelatedOrderItemForm'
import { NO_SELECTION } from '../../../../data/constants'

type Props = {|
  feedback:BarcodeFeedback,
  labs: Dictionary<string>,
  addLabOrder: (e: any) => void,
  labOrderId: string,
  labAlias: string,
  itemDetails: ItemDetails,
  created: string,
  labOrderItemDetails: AddLabOrderItem[],
  maxDate: string,
  updateProductionSku: (value: string) => void,
  updateCopies: (value: number) => void,
  updateLabAlias: (value: string) => void,
  updateLabOrderId: (value: string) => void,
  updateItemId: (value: string) => void,
  updateCreated: (value: string) => void,
  addItemDetails: (e: any) => void
|}

class OrderView extends Component<Props> {
  render () {
    const { feedback, labAlias, labOrderId } = this.props

    const disableButton = labAlias === '' ||
      labAlias === NO_SELECTION ||
      labOrderId === ''

    return (
      <div>
        {feedback && feedback.message !== '' &&
        <div className={styles.content}>
          <Feedback message={feedback.message} hasScanError={feedback.hasError} barcodes={feedback.barcodes}/>
        </div>}

        <div className={styles.addPadding}>
          <WhitePanel heading='Add Lab Order'>
            <form id='add-form' onSubmit={this.props.addLabOrder}>
              <div className={styles.content}>
                <div className={styles.inputGroup}>
                  <header className={styles.inputGroupHeader}>
                    <label>Lab Order Id</label>
                  </header>
                  <TextField
                    id='labOrderId'
                    type='number'
                    pattern='^\\d+$'
                    title='0-9 only'
                    value={labOrderId}
                    onChange={(e) => this.props.updateLabOrderId(e.target.value.toString())}
                    className={styles.searchBox}
                    autoCorrect='off'
                    autoCapitalize='off'
                    min={0}
                    required
                  />
                </div>

                <ChooseLabAlias
                  labs={this.props.labs}
                  labAlias={labAlias}
                  updateLabAlias={(value: string) => this.props.updateLabAlias(value)} />

                <div className={`${styles.inputGroup} ${styles.addSmallPadding} `}>
                  <header className={styles.inputGroupHeader}>
                    <label>Created</label>
                  </header>
                  <TextField
                    id='created'
                    type='datetime-local'
                    value={this.props.created}
                    onChange={(e) => this.props.updateCreated(e.target.value)}
                    className={styles.dateTime}
                    autoCorrect='off'
                    autoCapitalize='off'
                    required
                    max={this.props.maxDate}
                  />
                </div>

                <RelatedOrderItemForm
                  updateItemId={this.props.updateItemId}
                  updateProductionSku={this.props.updateProductionSku}
                  updateCopies={this.props.updateCopies}
                  addRelated={this.props.addItemDetails}
                  itemDetails={this.props.itemDetails}
                  labOrderItemDetails={this.props.labOrderItemDetails} />
              </div>
            </form>
            <div>
              <Button primary type='submit' form='add-form' tabIndex='1' disabled={disableButton}>
                Add Lab Order
              </Button>
            </div>
          </WhitePanel>
        </div>
      </div>
    )
  }
}

export default OrderView
