// @flow
import { createSelector } from 'reselect'
import { PRODUCTION_ADMIN } from '../data/auth'
import type { AppState } from '../types'
import { identity } from './helpers'

export function selectIsUserLoggedIn (state: AppState): boolean {
  return state.auth.isUserLoggedIn
}

export function selectIsUserAdmin (state: AppState): boolean {
  return state.auth.userType === PRODUCTION_ADMIN
}

export function selectJwtTimerId (state: AppState): ?IntervalID {
  return state.auth.jwtTimerId
}

export function selectUserId (state: AppState): string {
  return state.auth.userId
}

export function selectSessionExpired (state: AppState): boolean {
  return state.auth.sessionExpired
}

export function selecHasLabAlias (state: AppState): boolean {
  return Boolean(state.auth.labAlias !== '')
}

function selectLabAlias (state: AppState): string {
  return state.auth.labAlias
}

export const getIsUserLoggedIn = createSelector(selectIsUserLoggedIn, identity)
export const getIsUserAdmin = createSelector(selectIsUserAdmin, identity)
export const getLabAlias = createSelector(selectLabAlias, identity)
export const getSessionExpired = createSelector(selectSessionExpired, identity)
export const getHasLabAlias = createSelector(selecHasLabAlias, identity)
