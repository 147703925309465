// @flow
import type { SplashState, SplashActions, FetchItemScanSummarySuccess } from '../types'
import {
  REFRESH_REQUIRED,
  RESET_REFRESH,
  ADD_SPLASH_TIMEOUT,
  CLEAR_SPLASH_TIMEOUT,
  CLEAR_ALL_SPLASH_TIMEOUTS,
  FETCH_ORDER_ITEM_DETAILS,
  FETCH_ORDER_ITEM_DETAILS_SUCCESS,
  FETCH_EQUIPMENT_UPDATE,
  FETCH_EQUIPMENT_UPDATE_SUCCESS,
  FETCH_SESSION_ORDER_ITEMS,
  FETCH_SESSION_ORDER_ITEMS_SUCCESS,
  FETCH_LABS_SUCCESS,
  FETCH_ITEM_SCAN_SUMMARY,
  FETCH_ITEM_SCAN_SUMMARY_SUCCESS,
  FETCH_ITEM_SCAN_SUMMARY_ERROR
} from '../actions/splash'
import { SPLASH_ACTION_IDS } from '../data/splash'

const defaultRefreshState = {
  [SPLASH_ACTION_IDS.ORDER_ITEM_DETAILS]: false,
  [SPLASH_ACTION_IDS.EQUIPMENT_DETAILS]: false,
  [SPLASH_ACTION_IDS.SESSION_ORDER_ITEMS]: false,
  [SPLASH_ACTION_IDS.ITEM_SCAN_SUMMARY]: false
}

const defaultTimeoutIDs = {
  [SPLASH_ACTION_IDS.ORDER_ITEM_DETAILS]: null,
  [SPLASH_ACTION_IDS.EQUIPMENT_DETAILS]: null,
  [SPLASH_ACTION_IDS.SESSION_ORDER_ITEMS]: null,
  [SPLASH_ACTION_IDS.ITEM_SCAN_SUMMARY]: null
}

const initialState: SplashState = {
  orderItemDetails: {
    lastCompleted: null,
    oldestOrderItem: null
  },
  itemScanSummary: null,
  equipmentDetails: [],
  sessionOrderItems: {},
  labs: {},
  refresh: defaultRefreshState,
  timeoutIds: defaultTimeoutIDs
}

export function splash (state: SplashState = initialState, action: SplashActions): SplashState {
  switch (action.type) {
    case FETCH_ITEM_SCAN_SUMMARY: {
      return {
        ...state,
        refresh: {
          ...state.refresh,
          itemScanSummary: false
        }
      }
    }

    case FETCH_ITEM_SCAN_SUMMARY_SUCCESS: {
      const currentAction: FetchItemScanSummarySuccess = action

      return {
        ...state,
        itemScanSummary: currentAction.payload.data
      }
    }

    case FETCH_ITEM_SCAN_SUMMARY_ERROR: {
      return {
        ...state,
        itemScanSummary: null
      }
    }

    case CLEAR_ALL_SPLASH_TIMEOUTS: {
      return {
        ...state,
        timeoutIds: defaultTimeoutIDs
      }
    }

    case CLEAR_SPLASH_TIMEOUT: {
      return {
        ...state,
        timeoutIds: {
          ...state.timeoutIds,
          [action.id]: null
        }
      }
    }

    case ADD_SPLASH_TIMEOUT: {
      return {
        ...state,
        timeoutIds: {
          ...state.timeoutIds,
          [action.id]: action.timeoutId
        }
      }
    }

    case RESET_REFRESH: {
      return {
        ...state,
        refresh: defaultRefreshState
      }
    }

    case REFRESH_REQUIRED: {
      const currentId = state.timeoutIds[action.id]

      if (currentId) {
        clearTimeout(currentId)
      }

      return {
        ...state,
        refresh: {
          ...state.refresh,
          [action.id]: true
        },
        timeoutIds: {
          ...state.timeoutIds,
          [action.id]: null
        }
      }
    }

    case FETCH_LABS_SUCCESS: {
      return {
        ...state,
        labs: action.payload
      }
    }

    case FETCH_ORDER_ITEM_DETAILS: {
      return {
        ...state,
        refresh: {
          ...state.refresh,
          orderItemDetails: false
        }
      }
    }

    case FETCH_ORDER_ITEM_DETAILS_SUCCESS: {
      return {
        ...state,
        orderItemDetails: action.payload
      }
    }

    case FETCH_EQUIPMENT_UPDATE: {
      return {
        ...state,
        refresh: {
          ...state.refresh,
          equipmentDetails: false
        }
      }
    }

    case FETCH_EQUIPMENT_UPDATE_SUCCESS: {
      return {
        ...state,
        equipmentDetails: action.payload
      }
    }

    case FETCH_SESSION_ORDER_ITEMS: {
      return {
        ...state,
        refresh: {
          ...state.refresh,
          sessionOrderItems: false
        }
      }
    }

    case FETCH_SESSION_ORDER_ITEMS_SUCCESS: {
      return {
        ...state,
        sessionOrderItems: action.payload
      }
    }
    default:
      return state
  }
}

export default splash
