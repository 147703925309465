// @flow

import * as React from 'react'
import cx from 'classnames'
import styles from './AuthPage.module.css'

type Props = {|
  wideLayout?: boolean,
  children: React.Element
|}

function AuthPage ({ wideLayout, children }: Props) {
  return (
    <main className={
      cx(styles.page, {
        [styles.pageWide]: wideLayout
      })
    }>

      <div className={styles.spacerTop} />

      <div className={styles.logo} tabIndex='1'>
        <img src='/img/prodigi-logo-dark.svg' alt='Prodigi' width='182' height='72' />
      </div>

      <section className={styles.content}>
        {children}
      </section>

      <div className={styles.spacerBottom} />

    </main>
  )
}

export default AuthPage
