// @flow
import React, { PureComponent } from 'react'
import { makeCancellable, PROMISE_CANCELLED_MESSAGE } from '../../helpers/promise'
import type { ComponentType } from 'react'
import type { Dictionary } from '../../types'
import type { Cancel } from '../../helpers/promise'

type Props<ActionResult, WrappedCompProps> = {|
  ...WrappedCompProps,
  fetchDataAsync: () => Promise<ActionResult>
|}

type State<ActionResult> = {|
  error: boolean,
  loading: boolean,
  success: boolean,
  cancel: ?Cancel,
  data: ?ActionResult
|}

export default function withAsyncDataFetcher<ActionResult> (propName: string, successActionType?: string) {
  return function<WrappedCompProps> (WrappedComponent: ComponentType<WrappedCompProps>): ComponentType<*> {
    return class WithAsyncDataFetcher extends PureComponent<Props<ActionResult, WrappedCompProps>, State<ActionResult>> {
      state = {
        loading: false,
        success: false,
        error: false,
        data: null,
        cancel: null
      }

      componentDidMount () {
        const cancellablePromise = makeCancellable(this.props.fetchDataAsync)

        this.setState({cancel: cancellablePromise.cancel})

        cancellablePromise
          .run(this.onStart)
          .then(this.processResult)
          .catch(err => {
            if (err.message !== PROMISE_CANCELLED_MESSAGE) {
              throw err
            }
          })
      }

      componentWillUnmount () {
        this.state.cancel && this.state.cancel()
      }

      processResult = (actionResult: ?ActionResult) => {
        const resultHasSuccessActionType = successActionType && actionResult && actionResult.type && actionResult.type === successActionType

        if (actionResult && resultHasSuccessActionType) {
          this.onSuccess(actionResult)
        } else if (actionResult && !successActionType) {
          this.onSuccess(actionResult)
        } else {
          this.onError()
        }
      }

      onStart = () => {
        this.setState({ loading: true, success: false, error: false })
      }

      onError = () => {
        this.setState({ cancel: null, loading: false, error: true })
      }

      onSuccess = (data: ActionResult) => {
        this.setState({ cancel: null, loading: false, success: true, data })
      }

      getStatusProps = (): Dictionary<boolean> => ({
        [propName + 'Loading']: this.state.loading,
        [propName + 'Error']: this.state.error,
        [propName + 'Success']: this.state.success
      })

      render () {
        const { fetchDataAsync, ...restProps } = this.props
        return <WrappedComponent {...this.getStatusProps()} {...this.state.data} {...restProps} />
      }
    }
  }
}
