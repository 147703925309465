// @flow
import type { ChildrenArray } from 'react'
import React, { Component } from 'react'
import type { RouterHistory, Match, Location, HistoryAction } from 'react-router'

import styles from './ErrorBoundary.module.css'

type ErrorBoundaryProps = {|
  match: Match,
  location: Location,
  children: ChildrenArray,
  history: RouterHistory
|}

type ErrorBoundaryState = {|
  hasError: boolean,
  removeHistoryListener: ?() => void
|}

export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state = { hasError: false, removeHistoryListener: null }

  componentDidMount () {
    this.addHistoryListener()
  }

  componentWillUnmount () {
    this.state.removeHistoryListener && this.state.removeHistoryListener()
  }

  addHistoryListener = () => {
    const removeHistoryListener = this.props.history.listen(this.resetErrorStatus)
    this.setState({ removeHistoryListener })
  }

  resetErrorStatus = (location: Location, action: HistoryAction) => {
    this.setState({ hasError: false })
  }

  static getDerivedStateFromError (_: Error) {
    return { hasError: true }
  }

  render () {
    if (this.state.hasError) {
      return (
        <div className={styles.container}>
          <h3>An error occurred. Please refresh the page to try again</h3>
        </div>
      )
    }

    return this.props.children
  }
}
