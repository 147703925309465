// @flow
import React, { Component } from 'react'
import type { Dictionary, Overview, SplashStateDetails, ItemScanSummary } from '../../types'
import DefaultDashboardView from './DefaultDashboardView'

type Props = {|
  sessionOrderItems: Dictionary<Overview>,
  labAlias: string,
  ordersBySession: SplashStateDetails,
  itemScanSummary: SplashStateDetails,
  scanSummary: ?ItemScanSummary,
  clearRefresh: () => void,
  abortRequests: () => void,
  getOrdersBySession: (labAlias: string) => void,
  getItemScanSummary: (labAlias: string) => void
|}

type State = {|
  screen: number,
  changeScreenIntervalId: ?IntervalID
|}

class DefaultDashboardController extends Component<Props, State> {
  state = {
    screen: 0,
    changeScreenIntervalId: null
  }

  componentDidMount () {
    this.props.getItemScanSummary(this.props.labAlias)
    this.props.getOrdersBySession(this.props.labAlias)

    const changeScreenIntervalId = setInterval(() => this.changeScreen(), 60000)

    this.setState({ changeScreenIntervalId })
  }

  componentWillUnmount () {
    if (this.state.changeScreenIntervalId) {
      clearInterval(this.state.changeScreenIntervalId)
    }
    this.props.abortRequests()
    this.props.clearRefresh()
  }

  componentDidUpdate () {
    const { labAlias, ordersBySession, itemScanSummary } = this.props

    if (!itemScanSummary.loading && itemScanSummary.refresh && labAlias !== '') {
      this.props.getItemScanSummary(labAlias)
    }

    if (!ordersBySession.loading && ordersBySession.refresh && labAlias !== '') {
      this.props.getOrdersBySession(labAlias)
    }
  }

  changeScreen = () => {
    if (this.state.screen === 0) {
      this.setState({ screen: 1 })
    } else {
      this.setState({ screen: 0 })
    }
  }

  render () {
    return (
      <DefaultDashboardView
        scanSummary={this.props.scanSummary}
        sessionOrderItems={this.props.sessionOrderItems}
        screen={this.state.screen} />
    )
  }
}

export default DefaultDashboardController
