// @flow
import React, { Component } from 'react'
import type { ChildrenArray } from 'react'
import type { Style } from '../../../types'
import styles from './PanelTitle.module.css'

type Props = {|
  children: ChildrenArray,
  style?: Style
|}

class PanelTitle extends Component<Props> {
  render () {
    return (
      <p className={styles.title} style={this.props.style} >
        {this.props.children}
      </p>
    )
  }
}

export default PanelTitle
