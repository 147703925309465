// @flow
import { RSAA } from 'redux-api-middleware'
import { selectAuthServiceUrl } from '../../selectors/appSettings'
import { getAccessToken, getRefreshToken, saveJwtTokens } from './localStorage'
import { startJwtTimerIfNeeded } from './startJwtTimerIfNeeded'
import type { AuthTokens, DispatchFunc, GetStateFunc, ThunkAsync, RefreshAccessTokenRsaa, RefreshAccessTokenResult } from '../../types'
import { getUserLab } from './getUserLab'
import { getUserDetailsFromJwt } from './getJwt'

export const REFRESH_ACCESS_TOKEN = 'REFRESH_ACCESS_TOKEN'
export const REFRESH_ACCESS_TOKEN_SUCCESS = 'REFRESH_ACCESS_TOKEN_SUCCESS'
export const REFRESH_ACCESS_TOKEN_ERROR = 'REFRESH_ACCESS_TOKEN_ERROR'
export const REFRESH_ACCESS_TOKEN_SKIPPED = 'REFRESH_ACCESS_TOKEN_SKIPPED'

export function refreshAccessToken (): ThunkAsync<RefreshAccessTokenResult> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const accessToken = getAccessToken()
    const refreshToken = getRefreshToken()

    if (!(accessToken && refreshToken)) {
      return dispatch(refreshAccessTokenSkipped())
    }

    const result = await dispatch(refreshAccessTokenRsaa(accessToken, refreshToken))

    if (result.type === REFRESH_ACCESS_TOKEN_SUCCESS) {
      onSuccess(dispatch, result.payload)
    }

    return result
  }
}

function refreshAccessTokenRsaa (accessToken: string, refreshToken: string): RefreshAccessTokenRsaa {
  return {
    [RSAA]: {
      endpoint: selectAuthServiceUrl() + '/api/auth/refresh',
      method: 'POST',
      body: JSON.stringify({ refreshToken }),
      headers: { 'Content-Type': 'application/json' },
      types: [
        REFRESH_ACCESS_TOKEN,
        REFRESH_ACCESS_TOKEN_SUCCESS,
        REFRESH_ACCESS_TOKEN_ERROR
      ]
    }
  }
}

function onSuccess (dispatch: DispatchFunc, tokens: AuthTokens) {
  saveJwtTokens(tokens)
  dispatch(startJwtTimerIfNeeded(tokens.accessToken))
  dispatch(getUserDetailsFromJwt(tokens.accessToken))
  dispatch(getUserLab())
}

function refreshAccessTokenSkipped () {
  return {
    type: REFRESH_ACCESS_TOKEN_SKIPPED
  }
}
