// @flow
import type { AppState, DispatchFunc } from '../../types'
import { connect } from 'react-redux'
import { logIn } from '../../actions/auth'
import LoginController from './LoginController'
import { getIsUserLoggedIn, getSessionExpired } from '../../selectors/auth'
import { getRsaaStatusProps } from '../../selectors/rsaa'
import { routerActions } from 'react-router-redux'

type StateProps = {|
  loading: boolean,
  errorMessage: string,
  isUserLoggedIn: boolean
|}

type DispatchProps = {|
  onLogin: (username: string, password: string) => void,
  redirect: (address: string) => void
|}

const SESSION_EXPIRED_MESSAGE = 'Your session has expired.'

function mapStateToProps (state: AppState): StateProps {
  const rsaaStatus = getRsaaStatusProps(state, 'auth', 'logIn')
  const sessionExpired = getSessionExpired(state)

  return {
    loading: rsaaStatus.loading,
    errorMessage: sessionExpired ? SESSION_EXPIRED_MESSAGE : rsaaStatus.statusMessage,
    isUserLoggedIn: getIsUserLoggedIn(state)
  }
}

function mapDispatchToProps (dispatch: DispatchFunc): DispatchProps {
  return {
    onLogin: (username: string, password: string) => {
      dispatch(logIn(username, password))
    },
    redirect: (address: string) => {
      dispatch(routerActions.push(address))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginController)
