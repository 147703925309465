// @flow
import React, {PureComponent} from 'react'
import type { ComponentType } from 'react'

type WithLoadingIndicatorState = {|
  isLoading: boolean
|}

export default function (loadingPropName: string) {
  return function withLoadingIndicator (LoadingIndicator: ComponentType<*>) {
    return function (WrappedComponent: ComponentType<*>): ComponentType<*> {
      return class WithLoadingIndicator extends PureComponent<*, WithLoadingIndicatorState> {
        state = { isLoading: true }

        componentDidUpdate () {
          if (this.isLoading()) {
            this.setState({ isLoading: true })
          } else if (this.hasFinished()) {
            this.setState({ isLoading: false })
          }
        }

        isLoading = ():boolean => !this.state.isLoading && this.props[loadingPropName]
        hasFinished = ():boolean => this.state.isLoading && !this.props[loadingPropName]

        render () {
          if (this.state.isLoading) {
            return <LoadingIndicator {...this.props} />
          }
          return <WrappedComponent {...this.props} />
        }
      }
    }
  }
}
