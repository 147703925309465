// @flow
import { createSelector } from 'reselect'
import type {
  AppState,
  OrderSearchResults,
  SessionDetails,
  EmployeeSearchDetails,
  SessionActivitySearch,
  CurrentActivity,
  CurrentSession,
  OrderScanCheck,
  AllActivities
} from '../types'
import { identity } from './helpers'

export function selectOrderSearchResults (state: AppState): ?OrderSearchResults {
  return state.search.orderSearch
}

export function selectSessionNames (state: AppState): string[] {
  return state.search.sessionTypes
}

export function selectActivityNames (state: AppState): string[] {
  return state.search.activityTypes
}

export function selectSessionDetails (state: AppState): SessionDetails {
  return state.search.sessionDetails
}

export function selectEquipmentNames (state: AppState): string[] {
  return state.search.equipmentTypes
}

export function selectEmployeeSearch (state: AppState): EmployeeSearchDetails {
  return state.search.employeeDetails
}

export function selectCurrentSession (state: AppState): CurrentSession {
  return state.search.currentSession
}

export function selectCurrentUser (state: AppState): string {
  return state.search.currentUser
}

export function selectActivitySession (state: AppState): SessionActivitySearch {
  return state.search.sessionActivity
}

export function selectCurrentActivity (state: AppState): CurrentActivity {
  return state.search.currentActivity
}

export function selectAllActivities (state: AppState): AllActivities {
  return state.search.allActivities
}

export function selectOrderItemScanCheckResult (state: AppState): OrderScanCheck {
  return state.search.orderItemScanCheck
}

export function selectEndOfWorkFlowSessions (state: AppState): string[] {
  return state.search.endOfWorkFlowSessions
}

export const getOrderSearchResults = createSelector(selectOrderSearchResults, identity)
export const getSessionNames = createSelector(selectSessionNames, identity)
export const getActivityNames = createSelector(selectActivityNames, identity)
export const getSessionDetails = createSelector(selectSessionDetails, identity)
export const getEquipmentNames = createSelector(selectEquipmentNames, identity)
export const getEmployeeSearch = createSelector(selectEmployeeSearch, identity)
export const getCurrentSession = createSelector(selectCurrentSession, identity)
export const getCurrentActivitySession = createSelector(selectActivitySession, identity)
export const getCurrentActivity = createSelector(selectCurrentActivity, identity)
export const getSessionEquipmentActivities = createSelector(selectAllActivities, identity)
export const getOrderItemScanCheckResult = createSelector(selectOrderItemScanCheckResult, identity)
export const getEndOfWorkFlowSessions = createSelector(selectEndOfWorkFlowSessions, identity)
export const getCurrentUser = createSelector(selectCurrentUser, identity)
