// @flow
import React, { Component } from 'react'
import { EQUIPMENT } from '../../../../data/scan'
import type { Dictionary, BarcodeFeedback } from '../../../../types'
import EquipmentView from './EquipmentView'

type Props = {|
  labs: Dictionary<string>,
  getLabs: () => void,
  removeEquipmentFromDatabase: (equipmentName: string) => void,
  addEquipmentToDatabase: (equipmentName: string, relatedActivities: string[], labAlias: string) => void,
  getActivityTypes: (activityType: string) => void,
  getEquipmentTypes: () => void,
  activityNames: string[],
  equipmentNames: string[],
  resetFeedback: () => void,
  feedback: BarcodeFeedback
|}

type State = {|
  addEquipment: string,
  removeEquipment: string,
  activity: string,
  relatedActivities: string[],
  labAlias: string,
  needsReset: boolean
|}

class EquipmentController extends Component<Props, State> {
  state = {
    addEquipment: '',
    removeEquipment: '',
    activity: '',
    relatedActivities: [],
    labAlias: '',
    needsReset: false
  }

  componentDidMount () {
    this.props.resetFeedback()
    this.props.getActivityTypes(EQUIPMENT)
    this.props.getEquipmentTypes()
    this.props.getLabs()
  }

  checkReset = () => {
    if (this.state.needsReset) {
      this.props.resetFeedback()
      this.setState({ needsReset: false })
    }
  }

  updateAdd = (value: string) => {
    this.checkReset()
    this.setState({ addEquipment: value })
  }

  updateRemove = (value: string) => {
    this.checkReset()
    this.setState({ removeEquipment: value })
  }

  updateActivityChoice = (value: string) => {
    this.checkReset()
    this.setState({ activity: value })
  }

  updateLabAlias = (value: string) => {
    this.checkReset()
    this.setState({ labAlias: value })
  }

  addEquipment = (e: any) => {
    e.preventDefault()
    const { relatedActivities, addEquipment, labAlias } = this.state
    if (relatedActivities.length !== 0 && addEquipment !== '' && labAlias !== '') {
      this.props.addEquipmentToDatabase(addEquipment, relatedActivities, labAlias)
    }
    this.setState({ relatedActivities: [], addEquipment: '', labAlias: '', needsReset: true })
    this.props.getEquipmentTypes()
  }

  removeEquipment = (e: any) => {
    e.preventDefault()
    if (this.state.removeEquipment !== '') {
      this.props.removeEquipmentFromDatabase(this.state.removeEquipment)
    }
    this.setState({ removeEquipment: '', needsReset: true })
    this.props.getEquipmentTypes()
  }

  addRelatedActivity = (e: any) => {
    e.preventDefault()
    this.checkReset()
    const { activity, relatedActivities } = this.state
    if (!relatedActivities.includes(activity)) {
      this.setState({ activity: '', relatedActivities: [...relatedActivities, activity] })
    } else {
      this.setState({ activity: '' })
    }
  }

  render () {
    return (
      <EquipmentView
        labs={this.props.labs}
        activityNames={this.props.activityNames}
        equipmentNames={this.props.equipmentNames}
        updateAdd={this.updateAdd}
        updateRemove={this.updateRemove}
        updateActivityChoice={this.updateActivityChoice}
        updateLabAlias={this.updateLabAlias}
        addEquipmentToDatabase={this.addEquipment}
        removeEquipmentFromDatabase={this.removeEquipment}
        addRelatedActivity={this.addRelatedActivity}
        removeEquipment={this.state.removeEquipment}
        addEquipment={this.state.addEquipment}
        activity={this.state.activity}
        relatedActivities={this.state.relatedActivities}
        labAlias={this.state.labAlias}
        feedback={this.props.feedback} />
    )
  }
}

export default EquipmentController
