// @flow
import { getInFlightRequestController } from '../selectors/rsaa'
import { hardResetRsaaStatus } from './rsaa'
import type { GetStateFunc, DispatchFunc } from '../types'

export function abortRequest (requestName: string) {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const requestController = getInFlightRequestController(getState(), requestName)

    if (requestController) {
      requestController.abort()
      dispatch(hardResetRsaaStatus(requestName))
    }

    dispatch({type: [requestName]})
  }
}
