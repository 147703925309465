// @flow

import React, { Component } from 'react'
import type { CurrentSession } from '../../../types'
import ScanTimersView from './ScanTimersView'
import {
  getHasMaxTimeElapsed,
  getTimeElapsed,
  getFormattedByTimeElapsed
} from '../../../helpers/date'

type Props = {|
  fetchLocationSuccess: boolean,
  currentSession: CurrentSession,
  maxSessionTimeElapsed: () => void
|}

type State = {|
  timeIntervalId: ?IntervalID,
  sessionDuration: string
|}

class ScanTimersController extends Component<Props, State> {
  state = {
    timeIntervalId: null,
    sessionDuration: ''
  }

  componentDidMount () {
    const timeIntervalId = setInterval(() => this.updateTime(), 1000)
    this.setState({ timeIntervalId })
  }

  componentDidUpdate () {
    const initialState = this.state.sessionDuration === ''

    if (this.props.fetchLocationSuccess && initialState) {
      this.updateTime()
    }
  }

  componentWillUnmount () {
    if (this.state.timeIntervalId) {
      clearInterval(this.state.timeIntervalId)
    }
  }

  updateTime = () => {
    const { currentSession } = this.props

    if (currentSession && currentSession.timeStamp && currentSession.timeStamp !== '') {
      this.updateSessionTimer()
    }
  }

  updateSessionTimer = () => {
    const timeElapsed = getTimeElapsed(this.props.currentSession.timeStamp)
    const maxSessionTime = getHasMaxTimeElapsed(timeElapsed)

    if (maxSessionTime) {
      this.props.maxSessionTimeElapsed()
      this.setState({ sessionDuration: '' })
    } else {
      const formatted = getFormattedByTimeElapsed(timeElapsed)
      this.setState({ sessionDuration: formatted })
    }
  }

  render () {
    return (
      <ScanTimersView
        session={this.props.currentSession.session}
        sessionTime={this.state.sessionDuration} />
    )
  }
}

export default ScanTimersController
