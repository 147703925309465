// @flow
export const EQUIPMENT = 'equipment'
export const SESSION = 'session'
export const ACTIVITY = 'activity'
export const ORDER_ITEM = 'item'
export const ORDER = 'order'
export const BULK = 'bulk'
export const WORKSHEET = 'worksheet'

export const EQUIPMENT_PREFIX = 'e'
export const SESSION_PREFIX = 's'
export const ORDER_PREFIX = 'o'
export const USER_PREFIX = 'h'
export const ACTIVITY_PREFIX = 'a'

export const SCAN_PREFIX_TYPE = [
  { prefix: EQUIPMENT_PREFIX, type: EQUIPMENT },
  { prefix: SESSION_PREFIX, type: SESSION },
  { prefix: ORDER_PREFIX, type: ORDER },
  { prefix: USER_PREFIX, type: 'user' },
  { prefix: ACTIVITY_PREFIX, type: ACTIVITY }
]

export const VALID_KEYCODES = [
  ACTIVITY_PREFIX,
  EQUIPMENT_PREFIX,
  SESSION_PREFIX,
  ORDER_PREFIX
]

export const SCAN_TYPES = {
  EQUIPMENT: EQUIPMENT,
  SESSION: SESSION,
  ACTIVITY: ACTIVITY,
  ORDER_ITEM: ORDER_ITEM,
  ORDER: ORDER,
  BULK: BULK,
  WORKSHEET: WORKSHEET
}
