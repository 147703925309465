/* eslint-disable flowtype/require-valid-file-annotation */

import './styles/styles.css'
import './styles/colors.css'
import './styles/typography.css'
import './styles/mediaQueries.css'
import 'tippy.js/dist/tippy.css'

import 'babel-polyfill'
import './polyfill/urlSearchParams'
import React from 'react'
import { render } from 'react-dom'
import Root from './containers/Root'
import intlPolyfill from 'intl'
import 'intl/locale-data/jsonp/en.js'

if (!window.Intl || !global.Intl) {
  window.Intl = intlPolyfill
  global.Intl = intlPolyfill
}

render(<Root />, document.getElementById('root'))
