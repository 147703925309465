// @flow

import React from 'react'
import Radium from 'radium'
import styles from './TextField.module.css'
import cx from 'classnames'

type Props = {|
  style: any,
  uppercase: boolean,
  name?: string,
  pattern?: string,
  title?: string,
  placeholder?: string,
  type: string,
  booleanFocus?: boolean,
  className?: string
|}

type State = {|
  id: string
|}

function getId ({ name, placeholder, type, ...rest }: Props): string {
  return `${(name || placeholder || 'no_name').toLowerCase().replace(/\s+/g, '_')}_${type || 'text'}_field`
}

class TextField extends React.Component<Props, State> {
  state = { id: getId(this.props) }

  render () {
    const { style, uppercase, booleanFocus, pattern, title, className, ...otherProps } = this.props
    if (booleanFocus) {
      return <input
        autoFocus
        id={this.state.id}
        spellCheck={false}
        pattern={pattern}
        title={title}
        type={this.props.type}
        style={style}
        className={cx(styles.primaryStyle, className, { [styles.upper]: uppercase })}
        {...otherProps} />
    } else {
      return <input
        id={this.state.id}
        spellCheck={false}
        pattern={pattern}
        title={title}
        type={this.props.type}
        style={style}
        className={cx(styles.primaryStyle, className, { [styles.upper]: uppercase })}
        {...otherProps} />
    }
  }
}
export default Radium(TextField)
