// @flow
import type { DispatchFunc, GetStateFunc } from '../types'
import { clearTimeoutByIds } from './splash'
import { abortRequest } from './abort'
import { SPLASH_ACTION_IDS } from '../data/splash'

export function abortDefaultDashboardRequests () {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const timeoutIds = [
      SPLASH_ACTION_IDS.SESSION_ORDER_ITEMS,
      SPLASH_ACTION_IDS.ITEM_SCAN_SUMMARY
    ]

    await dispatch(abortRequest('ordersBySession'))
    await dispatch(abortRequest('itemScanSummary'))
    await dispatch(clearTimeoutByIds(timeoutIds))
  }
}
