// @flow
import { connect } from 'react-redux'
import OrderSearchController from './OrderSearchController'
import type { AppState, DispatchFunc, OrderSearchResults, Feedback, RsaaStatusProps } from '../../../../types'
import { searchForOrder, resetState, resetOrderSearchFeedback } from '../../../../actions/search'
import { getOrderSearchResults } from '../../../../selectors/search'
import { getOrderSearchResultsFeedback } from '../../../../selectors/feedback'
import { compose } from 'redux'
import WithLabAlias from '../../../../components/hoc/WithLabAlias'
import { getRsaaStatusProps } from '../../../../selectors/rsaa'

type StateProps = {|
  searchResults: ?OrderSearchResults,
  searchFeedback: Feedback,
  requestStatus: RsaaStatusProps
|}

function mapStateToProps (state: AppState): StateProps {
  const rsaaStatus = getRsaaStatusProps(state, 'search', 'orderSearch')
  return {
    searchResults: getOrderSearchResults(state),
    searchFeedback: getOrderSearchResultsFeedback(state),
    requestStatus: rsaaStatus
  }
}

function mapDispatchToProps (dispatch: DispatchFunc) {
  return {
    searchForOrder: (orderQuery: string) => dispatch(searchForOrder(orderQuery)),
    resetState: () => dispatch(resetState()),
    resetOrderSearchFeedback: () => dispatch(resetOrderSearchFeedback())
  }
}

export default compose(
  WithLabAlias,
  connect(mapStateToProps, mapDispatchToProps)
)(OrderSearchController)
