// @flow
import cx from 'classnames'
import styles from '../components/button/Button.module.css'

export function getButtonStyles (
  primary?: boolean,
  secondary?: boolean,
  outline?: boolean,
  rollover?: boolean,
  destructive?: boolean,
  plain?: boolean,
  className?: string,
  size: string
): string {
  const classNames = {
    [styles.primary]: primary,
    [styles.primaryOutline]: (primary && outline),
    [styles.primaryRollover]: (primary && rollover),
    [styles.primaryOutlineRollover]: (primary && outline && rollover),
    [styles.secondary]: (secondary && !outline),
    [styles.secondaryOutline]: (secondary && outline),
    [styles.secondaryRollover]: (secondary && rollover),
    [styles.secondaryOutlineRollover]: (secondary && outline && rollover),
    [styles.outline]: (!primary && !secondary),
    [styles.flat]: plain,
    [styles.destructive]: destructive === true,
    [styles.destructiveFill]: (destructive && primary),
    [styles.small]: size === 'small',
    [styles.large]: size === 'large'
  }

  return cx(styles.base, className, classNames)
}
