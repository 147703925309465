// @flow
import React, { Component } from 'react'
import DetailPanel from '../../../../../components/layout/DetailPanel'
import styles from './OrderItemForm.module.css'
import type { AddLabOrderItem } from '../../../../../types'
import cx from 'classnames'

type Props = {|
  relatedItems: AddLabOrderItem[]
|}

export default class RelatedOrderItemList extends Component<Props> {
  render () {
    const items = this.props.relatedItems || []
    return (
      <DetailPanel title={'Lab Order Items'} className={styles.mainTable}>
        {items.length > 0 &&
        <table>
          <thead>
            <tr>
              <th className={styles.header}>Lab Order Item Id</th>
              <th className={cx(styles.header, styles.sku)}>Production Sku</th>
              <th className={styles.header}>Copies</th>
            </tr>
          </thead>
          <tbody>
            {items.map(row => <tr className={styles.row} key ={row.prodigiLabOrderItemId.toString()}>
              <td className={styles.data}>{row.prodigiLabOrderItemId}</td>
              <td className={cx(styles.data, styles.sku)}>{row.productionSku}</td>
              <td className={styles.data}>{row.copies}</td>
            </tr>)}
          </tbody>
        </table>}
      </DetailPanel>
    )
  }
}
