// @flow
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import FontAwesome from 'react-fontawesome'
import Button from '../button'
import styles from './PageNotFound.module.css'

type Props = {||}

class PageNotFound extends Component<Props> {
  render () {
    return (
      <div className={styles.container}>
        <FontAwesome name='ban' className={styles.icon} />
        <p className={styles.title}>404</p>
        <p className={styles.message}>Page Not Found</p>
        <Link to={`/scan`}>
          <Button primary icon='chevron-left'>Go back</Button>
        </Link>
      </div>
    )
  }
}

export default PageNotFound
