// @flow
import React, { Component } from 'react'
import type { EquipmentStatus as Status, OrderItemDetails, SplashStateDetails, ItemScanSummary } from '../../../types'
import SplashView from './SplashView'

type Props = {|
  equipment: Status[],
  orderItems: OrderItemDetails,
  labAlias: string,
  orderItemUpdate: SplashStateDetails,
  equipmentUpdate: SplashStateDetails,
  itemScanSummary: SplashStateDetails,
  scanSummary: ?ItemScanSummary,
  clearRefresh: () => void,
  abortRequests: () => void,
  getOrderItemUpdate: (labAlias: string) => void,
  getEquipmentUpdate: (labAlias: string) => void,
  getItemScanSummary: (labAlias: string) => void,
|}

class SplashController extends Component<Props> {
  componentDidMount () {
    const { labAlias } = this.props

    this.props.getItemScanSummary(this.props.labAlias)
    this.props.getOrderItemUpdate(labAlias)
    this.props.getEquipmentUpdate(labAlias)
  }

  componentWillUnmount () {
    this.props.clearRefresh()
    this.props.abortRequests()
  }

  componentDidUpdate () {
    const { equipmentUpdate, orderItemUpdate, labAlias, itemScanSummary } = this.props

    if (!itemScanSummary.loading && itemScanSummary.refresh && labAlias !== '') {
      this.props.getItemScanSummary(labAlias)
    }

    if (!orderItemUpdate.loading && orderItemUpdate.refresh && labAlias !== '') {
      this.props.getOrderItemUpdate(labAlias)
    }

    if (!equipmentUpdate.loading && equipmentUpdate.refresh && labAlias !== '') {
      this.props.getEquipmentUpdate(labAlias)
    }
  }

  render () {
    return (
      <SplashView
        scanSummary={this.props.scanSummary}
        equipment={this.props.equipment}
        orderItems={this.props.orderItems} />
    )
  }
}

export default SplashController
