// @flow
import React from 'react'
import Button from '../../../../../components/button'
import type { SessionDetails } from '../../../../../types'
import WhitePanel from '../../../../../components/layout/WhitePanel'
import styles from '../../Manage.module.css'
import TextField from '../../../../../components/input/TextField'
import Choose from '../../../../../components/Choose'
import { NO_SELECTION } from '../../../../../data/constants'

type Props = {|
  sessionSearch: string,
  updateQuery: (value: string) => void,
  searchSession: () => void,
  sessionNames: string[],
  sessionDetails: SessionDetails,
  updateSessionDetails: (name: string, value: string | number | boolean) => void,
  updateDetails: () => void,
|}

export default function UpdateSession (props: Props) {
  const { sessionDetails, updateSessionDetails } = props
  const itemsPerHourPattern = '^\\d+$'
  const sessionNamePattern = '^[A-Za-z ]+$'
  const sessionNameRegex = new RegExp(sessionNamePattern)
  const itemsPerHourRegex = new RegExp(itemsPerHourPattern)

  const hasSessionDetails = Boolean(
    sessionDetails.itemsPerHour > -1 &&
    sessionDetails.sessionName !== '' &&
    sessionDetails.id > -1 &&
    sessionNameRegex.test(sessionDetails.sessionName) &&
    itemsPerHourRegex.test(sessionDetails.itemsPerHour.toString())
  )
  const canShowDetails = sessionDetails.id > -1

  const disableButton = props.sessionSearch === '' || props.sessionSearch === NO_SELECTION

  return (
    <div className={styles.addPadding}>
      <WhitePanel heading='Update Session'>
        <form id='update-form' onSubmit={props.searchSession}>
          <div className={styles.content}>
            <div className={styles.searchSession}>
              <Choose
                names={props.sessionNames}
                id={'update-session'}
                checkForRequired={false}
                pattern='^[A-Za-z ]+$'
                patternTitle={'A-Z and a-z only'}
                search={props.sessionSearch}
                title={'Session Name'}
                update={props.updateQuery} />
            </div>
          </div>
        </form>
        <div className={styles.addPadding}>
          <Button primary type='submit' form='update-form' tabIndex='1' disabled={disableButton}>
            Search
          </Button>
        </div>
        <div>
          {canShowDetails &&
            <div className={styles.content}>
              <div className={styles.inputGroup}>
                <header className={styles.inputGroupHeader}>
                  <label>Session Name</label>
                </header>
                <TextField
                  id='name'
                  type='text'
                  pattern={sessionNamePattern}
                  title='A-Z and a-z only'
                  value={sessionDetails.sessionName}
                  onChange={(e) => updateSessionDetails('sessionName', e.target.value)}
                  className={styles.searchBox}
                  autoCorrect='off'
                  autoCapitalize='off'
                  required
                />
              </div>
              <div className={`${styles.inputGroup} ${styles.addSmallPadding}`}>
                <header className={styles.inputGroupHeader}>
                  <label>Items per hour (AHT)</label>
                </header>
                <TextField
                  id='itemsPerHour'
                  type='number'
                  pattern={itemsPerHourPattern}
                  title='0-9 only'
                  value={sessionDetails.itemsPerHour}
                  onChange={(e) => updateSessionDetails('itemsPerHour', parseInt(e.target.value))}
                  className={styles.searchBox}
                  autoCorrect='off'
                  autoCapitalize='off'
                  min={0}
                  required
                />
              </div>
              <div className={`${styles.checkboxGroup} ${styles.addSmallPadding} ${styles.addPadding}`}>
                <header className={styles.inputGroupHeader}>
                  <label>End of workflow (completed session)</label>
                </header>
                <input
                  id='isEndOfWorkflow'
                  type='checkbox'
                  className={styles.completed}
                  value={sessionDetails.isEndOfWorkflow}
                  onChange={(e) => updateSessionDetails('isEndOfWorkflow', e.target.checked)}
                  checked={sessionDetails.isEndOfWorkflow} />
              </div>
              <div>
                <Button
                  primary
                  type='submit'
                  disabled={!hasSessionDetails}
                  onClick={props.updateDetails}
                  tabIndex='1' >
                  Update
                </Button>
              </div>
            </div>
          }
        </div>
      </WhitePanel>
    </div>
  )
}
