// @flow
import React, { Component } from 'react'
import type { OrderSearchResults, Feedback as FeedbackStatus, RsaaStatusProps } from '../../../../types'
import styles from '../Search.module.css'
import Button from '../../../../components/button'
import TextField from '../../../../components/input/TextField'
import SearchResults from './components/SearchResults'
import Feedback from '../../../../components/layout/Feedback'
import LoadingSpinner from '../../../../components/LoadingSpinner'

type Props = {|
  searchResults: ?OrderSearchResults,
  searchFeedback: FeedbackStatus,
  orderSearch: string,
  requestStatus: RsaaStatusProps,
  updateQuery: (query: string) => void,
  searchOrder: (e: SyntheticInputEvent<HTMLInputElement>) => void
|}

class OrderSearchView extends Component<Props> {
  render () {
    const { searchFeedback, requestStatus } = this.props

    return (
      <div className={styles.content}>
        <form
          id='order-search'
          onSubmit={this.props.searchOrder}
          className={styles.leftColumn}>
          <div className={styles.inputGroup}>
            <TextField
              type='text'
              value={this.props.orderSearch}
              onChange={(e) => this.props.updateQuery(e.target.value)}
              className={styles.searchBox}
              placeholder='Please enter an order ID or order reference'
              autoCorrect='off'
              autoCapitalize='off'
              required
            />
          </div>
          <Button
            className={styles.button}
            primary
            type='submit'
            form={'order-search'}
            tabIndex='1' >
            Search
          </Button>
        </form>

        {requestStatus.loading && <LoadingSpinner />}

        {requestStatus.error && <div className={styles.feedback}>
          <Feedback
            message={searchFeedback.message}
            hasScanError={searchFeedback.hasError} />
        </div>}

        {requestStatus.success && <div className={styles.historyList}>
          <SearchResults results={this.props.searchResults} />
        </div>}
      </div>
    )
  }
}

export default OrderSearchView
