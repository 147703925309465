// @flow

import type { DispatchFunc, AppState } from '../../types'

import { connect } from 'react-redux'
import { logOut } from '../../actions/auth'
import Navigation from './Navigation'
import { getIsUserAdmin } from '../../selectors/auth'

function mapStateToProps (state: AppState) {
  return {
    isAdmin: getIsUserAdmin(state)
  }
}

function mapDispatchToProps (dispatch: DispatchFunc) {
  return {
    onSignOut: () => dispatch(logOut())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation)
