// @flow
import React, { Component } from 'react'
import type { BarcodeFeedback, AllActivities } from '../../../../types'
import ActivityView from './ActivityView'

type Props = {|
  removeActivityFromDatabase: (activityName: string, activityType: string, typeName: string) => void,
  addActivityToDatabase: (activityName: string, related: string[], activityType: string, isBulkScan: boolean) => void,
  getSessionTypes: () => void,
  getEquipmentTypes: () => void,
  resetFeedback: () => void,
  getAllActivities: () => void,
  allActivities: AllActivities,
  sessionNames: string[],
  equipmentNames: string[],
  feedback: BarcodeFeedback
|}

class ActivityController extends Component<Props> {
  componentDidMount () {
    this.props.resetFeedback()
    this.props.getAllActivities()
    this.props.getSessionTypes()
    this.props.getEquipmentTypes()
  }

  render () {
    return (
      <ActivityView
        removeActivityFromDatabase={this.props.removeActivityFromDatabase}
        addActivityToDatabase={this.props.addActivityToDatabase}
        sessionNames={this.props.sessionNames}
        equipmentNames={this.props.equipmentNames}
        feedback={this.props.feedback}
        allActivities={this.props.allActivities}
        resetFeedback={this.props.resetFeedback}
      />
    )
  }
}

export default ActivityController
