// @flow
import { removeJwtTokens, getRefreshToken } from './localStorage'
import { clearJwtTimer } from './clearJwtTimer'
import { revokeAccessToken, REVOKE_ACCESS_TOKEN_SUCCESS } from './'
import type { DispatchFunc, GetStateFunc, ThunkAsync } from '../../types'
import { resetAppState } from '../global'
import { routerActions } from 'react-router-redux'
import { abortRequest } from '../abort'
import { abortActionsForLogout } from '../../data/abortActions'
import { clearTimeouts } from '../splash/timeout'

export const LOG_OUT_ERROR = 'LOG_OUT_ERROR'

export function logOut (): ThunkAsync<void> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const refreshToken = getRefreshToken()

    if (refreshToken) {
      const revokeTokenResult = await dispatch(revokeAccessToken(refreshToken))
      if (revokeTokenResult.type !== REVOKE_ACCESS_TOKEN_SUCCESS) {
        return dispatch(onLogOutError())
      }
    }

    dispatch(clearJwtTimer())
    removeJwtTokens()
    await dispatch(abortRequestsOnLogout())
    await dispatch(clearTimeouts())
    dispatch(resetAppState())
    dispatch(routerActions.replace('/login'))
  }
}

export function onLogOutError () {
  return {
    type: LOG_OUT_ERROR
  }
}

function abortRequestsOnLogout () {
  return async (dispatch: DispatchFunc) => {
    abortActionsForLogout.forEach(
      action => dispatch(abortRequest(action))
    )
  }
}
