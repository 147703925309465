// @flow
import React, { Component } from 'react'
import { Provider } from 'react-redux'
import AsyncApp from './AsyncApp'
import configureStore from '../configureStore'

const store = configureStore()

type Props = {|
|}

class Root extends Component<Props> {
  render () {
    return (
      <Provider store={store}>
        <AsyncApp />
      </Provider>
    )
  }
}

export default Root
