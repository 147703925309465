// @flow
import React, { Component } from 'react'
import type { Dictionary, EmployeeDetails, BarcodeFeedback } from '../../../../types'
import styles from '../Manage.module.css'
import AddEmployee from './AddEmployee'
// import RemoveForm from '../RemoveForm'
import Feedback from '../../../../components/layout/Feedback'

type Props = {|
  labs: Dictionary<string>,
  addEmployeeToDatabase: (employee: EmployeeDetails) => void,
  removeEmployeeFromDatabase: (employeeBarcode: string) => void,
  updateRemove: (value: string) => void,
  updateAdd: (name: string, value: any) => void,
  feedback: BarcodeFeedback,
  addEmployee: EmployeeDetails,
  removeEmployee: string
|}

class EmployeeView extends Component<Props> {
  render () {
    const { feedback } = this.props
    return (
      <div>
        {feedback && feedback.message !== '' &&
        <div className={styles.content}>
          <Feedback message={feedback.message} hasScanError={feedback.hasError} barcodes={feedback.barcodes}/>
        </div>}

        <AddEmployee
          employee={this.props.addEmployee}
          labs={this.props.labs}
          addEmployee={this.props.addEmployeeToDatabase}
          updateAdd={this.props.updateAdd} />

        {/* <RemoveForm
          removeFromDatabase={this.props.removeEmployeeFromDatabase}
          removeValue={this.props.removeEmployee}
          updateRemove={this.props.updateRemove}
          pattern='^[A-Za-z0-9 ]+$'
          title={'A-Z, a-z and 0-9 only'}
          heading={'Remove Employee'}
          label={'Employee Barcode'} /> */}
      </div>
    )
  }
}

export default EmployeeView
