// @flow
import type { AuthState, AuthActions } from '../types'
import {
  SESSION_EXPIRED,
  LOG_IN,
  LOG_IN_SUCCESS,
  START_JWT_TIMER,
  REFRESH_ACCESS_TOKEN_SUCCESS,
  CLEAR_JWT_TIMER,
  FETCH_USER_LAB_SUCCESS,
  SET_USER_TYPE
} from '../actions/auth'

const initialState: AuthState = {
  isUserLoggedIn: false,
  sessionExpired: false,
  jwtTimerId: null,
  userId: '',
  userType: '',
  labAlias: ''
}

export function auth (state: AuthState = initialState, action: AuthActions): AuthState {
  switch (action.type) {
    case SESSION_EXPIRED: {
      return {
        ...state,
        sessionExpired: true
      }
    }

    case FETCH_USER_LAB_SUCCESS: {
      return {
        ...state,
        labAlias: action.payload.labAlias
      }
    }

    case SET_USER_TYPE: {
      return {
        ...state,
        userType: action.userType,
        userId: action.userId
      }
    }

    case CLEAR_JWT_TIMER: {
      return {
        ...state,
        jwtTimerId: null
      }
    }

    case REFRESH_ACCESS_TOKEN_SUCCESS:
    case LOG_IN_SUCCESS: {
      return {
        ...state,
        isUserLoggedIn: true
      }
    }

    case START_JWT_TIMER: {
      return {
        ...state,
        jwtTimerId: action.timerId
      }
    }

    case LOG_IN: {
      return {
        ...state,
        sessionExpired: false
      }
    }

    default:
      return state
  }
}

export default auth
