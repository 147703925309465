// @flow
import React, { Component } from 'react'
import type { SessionDetails, BarcodeFeedback } from '../../../../types'
import styles from '../Manage.module.css'
import AddSession from './components/AddSession'
import UpdateSession from './components/UpdateSession'
import Feedback from '../../../../components/layout/Feedback'

type Props = {|
  removeSessionFromDatabase: (sessionName: string) => void,
  addSessionToDatabase: (sessionName: string, relatedActivities: string[], itemsPerHour: number, isEndOfWorkflow: boolean) => void,
  activityNames: string[],
  sessionNames: string[],
  sessionDetails: SessionDetails,
  updateSessionDetails: (name: string, value: any) => void,
  feedback: BarcodeFeedback,
  updateAdd: (value: string) => void,
  updateActivityChoice: (value: string) => void,
  addRelatedActivity: (e: any) => void,
  updateItemsPerHour: (value: number) => void,
  updateRemove: (value: string) => void,
  updateQuery: (value: string) => void,
  searchSession: (e: any) => void,
  updateDetails: () => void,
  addSession: string,
  removeSession: string,
  activity: string,
  relatedActivities: string[],
  itemsPerHour: number,
  sessionSearch: string,
  updateIsEndOfWorkflow: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  isEndOfWorkflow: boolean
|}

class SessionView extends Component<Props> {
  render () {
    const { feedback } = this.props
    return (
      <div>
        {feedback && feedback.message !== '' &&
        <div className={styles.content}>
          <Feedback message={feedback.message} hasScanError={feedback.hasError} barcodes={feedback.barcodes}/>
        </div>}

        <AddSession
          session={this.props.addSession}
          addSession={this.props.addSessionToDatabase}
          updateAdd={this.props.updateAdd}
          activityNames={this.props.activityNames}
          updateActivityChoice={this.props.updateActivityChoice}
          addRelatedActivity={this.props.addRelatedActivity}
          activity={this.props.activity}
          relatedActivities={this.props.relatedActivities}
          updateItemsPerHour={this.props.updateItemsPerHour}
          isEndOfWorkflow={this.props.isEndOfWorkflow}
          updateIsEndOfWorkflow={this.props.updateIsEndOfWorkflow}
          itemsPerHour={this.props.itemsPerHour} />

        {/* <RemoveForm
          removeFromDatabase={this.props.removeSessionFromDatabase}
          removeValue={this.props.removeSession}
          updateRemove={this.props.updateRemove}
          names={this.props.sessionNames}
          heading={'Remove Session'}
          pattern='^[A-Za-z ]+$'
          title={'A-Z and a-z only'}
          label={'Session Name'} /> */}

        <UpdateSession
          sessionSearch={this.props.sessionSearch}
          updateQuery={this.props.updateQuery}
          searchSession={this.props.searchSession}
          sessionNames={this.props.sessionNames}
          sessionDetails={this.props.sessionDetails}
          updateSessionDetails={this.props.updateSessionDetails}
          updateDetails={this.props.updateDetails} />
      </div>
    )
  }
}

export default SessionView
