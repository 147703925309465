// @flow
import { connect } from 'react-redux'
import type { DispatchFunc, AppState, BarcodeFeedback, Dictionary, AddLabOrderItem } from '../../../../types'
import { addLabOrder } from '../../../../actions/order'
import { getLabOrderFeedback } from '../../../../selectors/feedback'
import OrderController from './OrderController'
import { resetFeedback } from '../../../../actions/global'
import { getLabs } from '../../../../actions/splash'
import { getLabDetails } from '../../../../selectors/splash'

type StateProps = {|
  feedback: BarcodeFeedback,
  labs: Dictionary<string>
|}

function mapStateToProps (state: AppState): StateProps {
  return {
    feedback: getLabOrderFeedback(state),
    labs: getLabDetails(state)
  }
}

function mapDispatchToProps (dispatch: DispatchFunc) {
  return {
    addLabOrderFromDatabase: (labOrderId: number, labAlias: string, created: string, labOrderItemDetails: AddLabOrderItem[]) =>
      dispatch(addLabOrder(labOrderId, labAlias, created, labOrderItemDetails)),
    resetFeedback: () => dispatch(resetFeedback()),
    getLabs: () => dispatch(getLabs())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderController)
