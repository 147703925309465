// @flow
import React, { Component } from 'react'
import { getDateTime } from '../../../../helpers/date'
import type { BarcodeFeedback, Dictionary, AddLabOrderItem, ItemDetails } from '../../../../types'
import OrderView from './OrderView'

type Props = {|
  feedback: BarcodeFeedback,
  labs: Dictionary<string>,
  getLabs: () => void,
  resetFeedback: () => void,
  addLabOrderFromDatabase: (
    labOrderId: number,
    labAlias: string,
    created: string,
    labOrderItemDetails: AddLabOrderItem[]
  ) => void
|}

type State = {|
  needsReset: boolean,
  labOrderId: string,
  labAlias: string,
  created: string,
  labOrderItemDetails: AddLabOrderItem[],
  maxDate: string,
  itemDetails: ItemDetails
|}

const defaultItemDetails = {
  itemId: '',
  productionSku: '',
  copies: 1
}

class OrderController extends Component<Props, State> {
  state = {
    needsReset: false,
    labOrderId: '',
    labAlias: '',
    created: '',
    labOrderItemDetails: [],
    maxDate: '',
    itemDetails: defaultItemDetails
  }

  componentDidMount () {
    this.props.resetFeedback()
    this.props.getLabs()

    const date = new Date().toString()
    const formatted = getDateTime(date, false)

    this.setState({ maxDate: formatted, created: formatted })
  }

  checkReset = () => {
    if (this.state.needsReset) {
      this.props.resetFeedback()
      this.setState({ needsReset: false })
    }
  }

  updateLabOrderId = (value: string) => {
    this.checkReset()
    this.setState({ labOrderId: value })
  }

  updateLabAlias = (value: string) => {
    this.checkReset()
    this.setState({ labAlias: value })
  }

  updateCreated = (value: string) => {
    this.checkReset()
    this.setState({ created: value })
  }

  updateItemId = (value: string) => {
    this.checkReset()
    this.setState({ itemDetails: { ...this.state.itemDetails, itemId: value } })
  }

  updateProductionSku = (value: string) => {
    this.checkReset()
    this.setState({ itemDetails: { ...this.state.itemDetails, productionSku: value } })
  }

  updateCopies = (value: number) => {
    this.checkReset()
    this.setState({ itemDetails: { ...this.state.itemDetails, copies: value } })
  }

  addItemDetails = (e: any) => {
    e.preventDefault()
    this.checkReset()
    const { labOrderItemDetails, itemDetails } = this.state
    const { itemId, productionSku, copies } = itemDetails
    const itemIdAsInt = parseInt(itemId)

    if (!labOrderItemDetails.some(details => details.prodigiLabOrderItemId === itemIdAsInt)) {
      const details = { prodigiLabOrderItemId: itemIdAsInt, productionSku, copies }
      this.setState({ itemDetails: defaultItemDetails, labOrderItemDetails: [...labOrderItemDetails, details] })
    } else {
      this.setState({ itemDetails: defaultItemDetails })
    }
  }

  addLabOrder = (e: any) => {
    e.preventDefault()
    const { labOrderId, labAlias, created, labOrderItemDetails } = this.state

    if (labOrderId !== '') {
      const utcDate = new Date(created).toUTCString()
      const formatted = getDateTime(utcDate, true) + 'Z'

      this.props.addLabOrderFromDatabase(parseInt(labOrderId), labAlias, formatted, labOrderItemDetails)
    }

    this.setState({
      labOrderId: '',
      labAlias: '',
      itemDetails: defaultItemDetails,
      created: this.state.maxDate,
      labOrderItemDetails: [],
      needsReset: true
    })
  }

  render () {
    return (
      <OrderView
        feedback={this.props.feedback}
        maxDate={this.state.maxDate}
        labs={this.props.labs}
        addLabOrder={this.addLabOrder}
        labOrderId={this.state.labOrderId}
        labAlias={this.state.labAlias}
        itemDetails={this.state.itemDetails}
        created={this.state.created}
        labOrderItemDetails={this.state.labOrderItemDetails}
        updateLabAlias={this.updateLabAlias}
        updateLabOrderId={this.updateLabOrderId}
        updateItemId={this.updateItemId}
        updateProductionSku={this.updateProductionSku}
        updateCopies={this.updateCopies}
        updateCreated={this.updateCreated}
        addItemDetails={this.addItemDetails}
      />
    )
  }
}

export default OrderController
