// @flow
export const SANDBOX = 'SANDBOX'
export const LIVE = 'LIVE'
export const TEST = 'TEST'

export const ABORT_ERROR_NAME = 'AbortError'
export const ABORT_ERROR_CODE = 20

export const AUTH_HEADER_PREFIX = 'Bearer '

export const MILLISECONDS_PER_MINUTE = 60000
export const VISUAL_REFRESH_TIME = 30000

export const ORDER = 'Order'
export const ORDER_ITEM = 'Order Item'

export const SCAN_ERROR_MESSAGES = {
  'DEFAULT': 'Scan failed. Please review and try again',
  'NOT_SCANNED_INTO_SESSION': 'Scan failed. Please scan into a session and try again'
}

export const NOT_SCANNED_INTO_SESSION_MESSAGES = [
  'no session could be found for employee with id',
  'session has expired for employee with id'
]

export const WRONG_PREFIX_START = 'wrong prefix used for barcode'
export const BULK_SCAN_FAILED = 'scan could not be added. Barcode was not for a bulk activity,'

export const START_SESSION = 'Start Session'
export const PRINT = 'Print'

export const REQUIRED_OPTIONS = [
  START_SESSION,
  PRINT
]

export const NO_SELECTION = 'NO_SELECTION'

export const ANONYMOUS_ID_LOCAL_STORAGE_KEY = 'prodigiAnonymousId'
