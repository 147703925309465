// @flow
import type { DispatchFunc, GetStateFunc } from '../../types'
import { selectJwtTimerId } from '../../selectors/auth'

export const CLEAR_JWT_TIMER = 'CLEAR_JWT_TIMER'

export function clearJwtTimer () {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const timerId = selectJwtTimerId(getState())

    if (timerId) {
      clearInterval(timerId)
    }

    dispatch({ type: CLEAR_JWT_TIMER })
  }
}
