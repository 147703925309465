// @flow

import { RSAA } from 'redux-api-middleware'
import type { DispatchFunc } from '../../types'
import { getBarcodeWithoutPrefix } from '../../helpers/scan'
import { getCurrentLocation } from '../search'

export const END_SESSION_START = 'END_SESSION_START'
export const END_SESSION_SUCCESS = 'END_SESSION_SUCCESS'
export const END_SESSION_ERROR = 'END_SESSION_ERROR'

export function endSession (employeeId: string) {
  return async (dispatch: DispatchFunc) => {
    const endpoint = process.env.REACT_APP_TRACKING_SERVICE || ''
    const employeeBarcode = getBarcodeWithoutPrefix(employeeId)
    try {
      await dispatch({
        [RSAA]: {
          endpoint: `${endpoint}/scan/endSession?employeeBarcode=${employeeBarcode}`,
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          types: [ END_SESSION_START, END_SESSION_SUCCESS, END_SESSION_ERROR ]
        }
      })
      dispatch(getCurrentLocation(employeeId))
    } catch (err) {
      console.log(err)
    }
  }
}
