// @flow
import React from 'react'
import FontAwesome from 'react-fontawesome'
import styles from './Status.module.css'
import cx from 'classnames'

type Props = {|
  efficiency?: number,
  color?: string
|}

export default function StatusIcon ({ efficiency = 0, color = 'Red' }: Props) {
  return (
    <FontAwesome
      name='circle'
      className={cx(styles.defaultColor, {
        [styles.green]: efficiency > 99 || color === 'Green',
        [styles.amber]: (efficiency < 99 && efficiency > 49) || color === 'Amber' })} />
  )
}
