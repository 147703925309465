// @flow
import { ABORT_IN_FLIGHT_REQUESTS, RSAA_ACTION_NAME } from '../data/rsaa'
import { saveInFlightRequestController } from '../actions/rsaa'
import { getInFlightRequestController } from '../selectors/rsaa'
import { ABORT_ERROR_NAME, ABORT_ERROR_CODE } from '../data/constants'
import type { MiddlewareAPI, Dispatch } from 'redux'
import type {
  RsaaActionShape,
  DOMException,
  AbortSignal,
  AbortController,
  AppState,
  Action,
  RequestOptions
} from '../types'

type RsaaAction = RsaaActionShape<*, *, *, *, *>

function noop () {}

function isCancellationError (error: DOMException): boolean {
  return error.name === ABORT_ERROR_NAME && error.code === ABORT_ERROR_CODE
}

function getFetchWithInFlightCancellationSupport (abortSignal: AbortSignal) {
  return async function (url: string, options: RequestOptions) {
    options.signal = abortSignal

    try {
      return await window.fetch(url, options)
    } catch (error) {
      if (isCancellationError(error)) {
        // RSAA middleware should treat the current action as if it was still in progress
        return new Promise(noop)
      }
      throw error
    }
  }
}

function getRequestType (rsaaAction: RsaaAction): string {
  return rsaaAction.types[0].type
}

function overwriteFetchFunction (rsaaAction: RsaaAction, controller: AbortController): void {
  rsaaAction.fetch = getFetchWithInFlightCancellationSupport(controller.signal)
}

function cancelPreviousRequest (state: AppState, requestType: string): void {
  const prevActionRequestController = getInFlightRequestController(state, requestType)
  if (prevActionRequestController) {
    prevActionRequestController.abort()
  }
}

function isInFlightRequestCancellationRequired (action: RsaaAction): boolean {
  return action && action.types && action.types[0].meta && action.types[0].meta.enhancements && action.types[0].meta.enhancements.includes(ABORT_IN_FLIGHT_REQUESTS)
}

export default function (store: MiddlewareAPI<AppState, Action>) {
  return function (next: Dispatch<Action>) {
    return async function (action: Action) {
      const rsaaAction = action[RSAA_ACTION_NAME]

      if (rsaaAction && isInFlightRequestCancellationRequired(rsaaAction)) {
        const controller = new window.AbortController()
        const requestType = getRequestType(rsaaAction)

        overwriteFetchFunction(rsaaAction, controller)

        cancelPreviousRequest(store.getState(), requestType)

        next(saveInFlightRequestController(requestType, controller))
      }

      return next(action)
    }
  }
}
