// @flow

import React from 'react'
import styles from './Title.module.css'

type Props = {|
  element?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6',
  children: string
|}

function Title (props: Props) {
  const Element = props.element || 'h1'
  return (
    <Element className={styles.base}>{props.children}</Element>
  )
}

export default Title
