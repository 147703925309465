// @flow

export type HistoryDateTime = {|
  time: string,
  date: string
|}

type TimeElapsed = {|
  hour: number,
  minute: number,
  second: number
|}

export function getHasMaxTimeElapsed (timeElapsed: TimeElapsed): boolean {
  return timeElapsed.hour > 8 ||
    (timeElapsed.hour === 8 && timeElapsed.minute > 29)
}

export function getFormattedTimeElapsedByTimestamp (time: string): string {
  const timeElapsed = getTimeElapsed(time)

  return getFormattedByTimeElapsed(timeElapsed)
}

export function getFormattedByTimeElapsed (timeElapsed: TimeElapsed): string {
  const hour = formatResult(timeElapsed.hour, 'hour')
  const minute = formatResult(Math.trunc(timeElapsed.minute), 'minute')
  const second = formatResult(Math.trunc(timeElapsed.second), 'second')

  return hour + minute + second
}

export function getTimeElapsed (time: string): TimeElapsed {
  const scanTime = createDate(time, ' ')
  const utcTimeNow = new Date().toUTCString()

  const durationInMilliSeconds = Date.parse(utcTimeNow) - Date.parse(scanTime)

  const durationInSeconds = durationInMilliSeconds / 1000
  const durationInMinutes = durationInSeconds / 60

  const hoursPassed = Math.trunc(durationInMinutes / 60)
  const minutesPassed = Math.trunc(durationInMinutes) - (hoursPassed * 60)
  const secondsPassed = Math.trunc(durationInSeconds) - (minutesPassed * 60) - (hoursPassed * 60 * 60)

  return {
    hour: hoursPassed,
    minute: minutesPassed,
    second: secondsPassed
  }
}

export function getDateTime (value: string, useUTC: boolean) : string {
  const today = new Date(value)

  const date = getDate(today, useUTC)
  const time = getTime(today, useUTC)

  return date + 'T' + time
}

export function getDateTimeInLocalFromTimeStamp (dateTime: string): HistoryDateTime {
  const formattedDate = dateTime.split('T')

  const originalDate = formattedDate[0].split('-')
  const originalTime = formattedDate[1].split(':')

  const utcTime = Date.UTC(
    parseInt(originalDate[0]),
    parseInt(originalDate[1]) - 1,
    parseInt(originalDate[2]),
    parseInt(originalTime[0]),
    parseInt(originalTime[1]),
    parseInt(originalTime[2]))

  const localDateTime = new Date(utcTime).toLocaleString()
  const splitLocalDateTime = localDateTime.split(', ')

  return {
    time: splitLocalDateTime[1],
    date: splitLocalDateTime[0]
  }
}

export function getDateTimeInLocal (time: string): HistoryDateTime {
  const utcTime = createUtcDate(time, ' ', '-')

  const localDateTime = new Date(utcTime).toLocaleString()
  const splitLocalDateTime = localDateTime.split(', ')

  return {
    time: splitLocalDateTime[1],
    date: splitLocalDateTime[0]
  }
}

function createDate (time: string, dateTimeSplit: string): string {
  const utcTime = createUtcDate(time, dateTimeSplit, '/')

  return new Date(utcTime).toUTCString()
}

function createUtcDate (time: string, dateTimeSplit: string, dateSplit: string): number {
  const formattedDate = time.split(dateTimeSplit)

  const originalDate = formattedDate[0].split(dateSplit)
  const originalTime = formattedDate[1].split(':')

  return Date.UTC(
    parseInt(originalDate[2]),
    parseInt(originalDate[1]) - 1,
    parseInt(originalDate[0]),
    parseInt(originalTime[0]),
    parseInt(originalTime[1]),
    parseInt(originalTime[2]))
}

function getDate (date: Date, useUtc: boolean) : string {
  const year = useUtc
    ? date.getUTCFullYear()
    : date.getFullYear()

  const month = useUtc
    ? date.getUTCMonth() + 1
    : date.getMonth() + 1

  const day = useUtc
    ? date.getUTCDate()
    : date.getDate()

  return formatYear(year) + '-' + format(month) + '-' + format(day)
}

function getTime (date: Date, useUtc: boolean) : string {
  const hour = useUtc
    ? date.getUTCHours()
    : date.getHours()

  const minute = useUtc
    ? date.getUTCMinutes()
    : date.getMinutes()

  const seconds = useUtc
    ? date.getUTCSeconds()
    : date.getSeconds()

  return format(hour) + ':' + format(minute) + ':' + format(seconds)
}

function format (value: number): string {
  const format = value.toString()

  return format.length < 2
    ? '0' + format
    : format
}

function formatYear (value: number): string {
  const yearFormat = '0000'
  const newYear = value.toString()

  const numberOfZeros = 4 - newYear.length

  const firstPart = yearFormat.slice(0, numberOfZeros)

  return firstPart + newYear
}

function formatResult (time: number, type: string): string {
  const formattedValue = time === 1
    ? ' ' + type
    : ' ' + type + 's'

  return type === 'hour'
    ? time + formattedValue
    : ' ' + time + formattedValue
}
