// @flow
import type { FeedbackState, FeedbackActions, AddLabOrderResponse, BarcodeDetails, AddActivitySuccess, AddEmployeeSuccess, AddEmployeeError, AddSessionSuccess, AddEquipmentSuccess } from '../types'
import { ADD_EQUIPMENT_SUCCESS, ADD_EQUIPMENT_ERROR, REMOVE_EQUIPMENT_SUCCESS, REMOVE_EQUIPMENT_ERROR, EQUIPMENT_IS_REQUIRED } from '../actions/equipment'
import { ADD_ACTIVITY_SUCCESS, ADD_ACTIVITY_ERROR, REMOVE_ACTIVITY_SUCCESS, REMOVE_ACTIVITY_ERROR, ACTIVITY_IS_REQUIRED } from '../actions/activity'
import { ADD_SESSION_SUCCESS, ADD_SESSION_ERROR, REMOVE_SESSION_SUCCESS, REMOVE_SESSION_ERROR, UPDATE_SESSION_SUCCESS, UPDATE_SESSION_ERROR, SESSION_IS_REQUIRED } from '../actions/session'
import { ADD_EMPLOYEE_SUCCESS, ADD_EMPLOYEE_ERROR, REMOVE_EMPLOYEE_ERROR, REMOVE_EMPLOYEE_SUCCESS } from '../actions/employee'
import { ADD_LAB_ORDER_SUCCESS, ADD_LAB_ORDER_ERROR } from '../actions/order'
import { FETCH_ORDER_SEARCH_ERROR, RESET_ORDER_SEARCH_FEEDBACK, RESET_EMPLOYEE_SEARCH, FETCH_EMPLOYEE_SEARCH_ERROR } from '../actions/search'
import { RESET_FEEDBACK } from '../actions/global'
import { mapAddErrorMessage } from '../helpers/mapAddErrorMessage'

const blankFeedback = {
  message: '',
  hasError: false
}

const blankBarcodeFeedback = {
  ...blankFeedback,
  barcodes: []
}

const initialState: FeedbackState = {
  equipment: blankBarcodeFeedback,
  session: blankBarcodeFeedback,
  activity: blankBarcodeFeedback,
  employee: blankBarcodeFeedback,
  orderSearch: blankFeedback,
  labOrder: blankBarcodeFeedback,
  employeeDetails: blankFeedback
}

export function feedback (state: FeedbackState = initialState, action: FeedbackActions): FeedbackState {
  switch (action.type) {
    case RESET_FEEDBACK: {
      return initialState
    }

    case ADD_LAB_ORDER_ERROR: {
      return {
        ...state,
        labOrder: {
          message: 'Lab order addition failed. Please try again',
          barcodes: [],
          hasError: true
        }
      }
    }

    case FETCH_EMPLOYEE_SEARCH_ERROR: {
      return {
        ...state,
        employeeDetails: {
          message: 'Employee not found',
          hasError: true
        }
      }
    }

    case ADD_LAB_ORDER_SUCCESS: {
      const currentResult: AddLabOrderResponse = action.payload
      const labOrderItems = currentResult.data.labOrderItems

      const barcodes: BarcodeDetails[] = labOrderItems.map(item => ({
        barcodeType: item.prodigiLabOrderItemId.toString(),
        barcode: item.barcode.barcodeString
      }))

      return {
        ...state,
        labOrder: {
          message: 'Lab order added',
          hasError: false,
          barcodes: barcodes
        }
      }
    }

    case FETCH_ORDER_SEARCH_ERROR: {
      return {
        ...state,
        orderSearch: {
          message: 'An error occurred. Please try again',
          hasError: true
        }
      }
    }

    case ADD_EQUIPMENT_SUCCESS: {
      const currentAction : AddEquipmentSuccess = action
      const newBarcodes = currentAction.payload.data.barcodes

      return {
        ...state,
        equipment: {
          message: 'Equipment added',
          barcodes: newBarcodes,
          hasError: false
        }
      }
    }

    case ADD_EQUIPMENT_ERROR: {
      return {
        ...state,
        equipment: {
          message: mapAddErrorMessage(action),
          barcodes: [],
          hasError: true
        }
      }
    }

    case EQUIPMENT_IS_REQUIRED: {
      return {
        ...state,
        equipment: {
          message: 'Equipment can not be removed',
          barcodes: [],
          hasError: true
        }
      }
    }

    case REMOVE_EQUIPMENT_SUCCESS: {
      return {
        ...state,
        equipment: {
          message: 'Equipment removed',
          barcodes: [],
          hasError: false
        }
      }
    }

    case REMOVE_EQUIPMENT_ERROR: {
      return {
        ...state,
        equipment: {
          message: 'Equipment removal failed. Please try again',
          barcodes: [],
          hasError: true
        }
      }
    }

    case ADD_ACTIVITY_SUCCESS: {
      const currentAction : AddActivitySuccess = action
      const newBarcodes = currentAction.payload.data.barcodes

      return {
        ...state,
        activity: {
          message: 'Activity added',
          barcodes: newBarcodes,
          hasError: false
        }
      }
    }

    case ADD_ACTIVITY_ERROR: {
      return {
        ...state,
        activity: {
          message: 'Activity addition failed. Please try again',
          barcodes: [],
          hasError: true
        }
      }
    }

    case REMOVE_ACTIVITY_SUCCESS: {
      return {
        ...state,
        activity: {
          message: 'Activity removed',
          barcodes: [],
          hasError: false
        }
      }
    }

    case REMOVE_ACTIVITY_ERROR: {
      return {
        ...state,
        activity: {
          message: 'Activity removal failed. Please try again',
          barcodes: [],
          hasError: true
        }
      }
    }

    case ACTIVITY_IS_REQUIRED: {
      return {
        ...state,
        activity: {
          message: 'Activity can not be removed',
          barcodes: [],
          hasError: true
        }
      }
    }
    case ADD_SESSION_SUCCESS: {
      const currentAction : AddSessionSuccess = action
      const newBarcodes = currentAction.payload.data.barcodes

      return {
        ...state,
        session: {
          message: 'Session added',
          barcodes: newBarcodes,
          hasError: false
        }
      }
    }

    case ADD_SESSION_ERROR: {
      return {
        ...state,
        session: {
          message: mapAddErrorMessage(action),
          barcodes: [],
          hasError: true
        }
      }
    }

    case REMOVE_SESSION_SUCCESS: {
      return {
        ...state,
        session: {
          message: 'Session removed',
          barcodes: [],
          hasError: false
        }
      }
    }

    case SESSION_IS_REQUIRED: {
      return {
        ...state,
        session: {
          message: 'Session can not be removed',
          barcodes: [],
          hasError: true
        }
      }
    }

    case REMOVE_SESSION_ERROR: {
      return {
        ...state,
        session: {
          message: 'Session removal failed. Please try again',
          barcodes: [],
          hasError: true
        }
      }
    }

    case UPDATE_SESSION_SUCCESS: {
      return {
        ...state,
        session: {
          message: 'Session updated',
          barcodes: [],
          hasError: false
        }
      }
    }

    case UPDATE_SESSION_ERROR: {
      return {
        ...state,
        session: {
          message: 'Session update failed. Please try again',
          barcodes: [],
          hasError: true
        }
      }
    }

    case ADD_EMPLOYEE_SUCCESS: {
      const currentAction : AddEmployeeSuccess = action
      const newBarcode = currentAction.payload.data.barcode

      return {
        ...state,
        employee: {
          message: 'Employee added',
          barcodes: [newBarcode],
          hasError: false
        }
      }
    }

    case ADD_EMPLOYEE_ERROR: {
      const currentAction : AddEmployeeError = action

      return {
        ...state,
        employee: {
          message: mapAddErrorMessage(currentAction),
          barcodes: [],
          hasError: true
        }
      }
    }

    case REMOVE_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        employee: {
          message: 'Employee removed',
          barcodes: [],
          hasError: false
        }
      }
    }

    case REMOVE_EMPLOYEE_ERROR: {
      return {
        ...state,
        employee: {
          message: 'Employee removal failed. Please try again',
          barcodes: [],
          hasError: true
        }
      }
    }

    case RESET_ORDER_SEARCH_FEEDBACK: {
      return {
        ...state,
        orderSearch: blankFeedback
      }
    }

    case RESET_EMPLOYEE_SEARCH: {
      return {
        ...state,
        employeeDetails: blankFeedback
      }
    }

    default:
      return state
  }
}

export default feedback
