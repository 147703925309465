// @flow

import React, { Component } from 'react'
import styles from './Scan.module.css'
import ScanTimers from './scanTimers'
import Button from '../../components/button'
import type { CurrentSession } from '../../types'

type Props = {|
  fetchLocationSuccess: boolean,
  endSessionButtonDisabled: boolean,
  currentUser: string,
  currentSession: CurrentSession,
  endSession: () => void
|}

export default class UserOverview extends Component<Props> {
  render () {
    const currentSession = this.props.currentSession
    return (
      <div className={styles.userOverview}>
        <h3 className={styles.user}>User: {this.props.currentUser}</h3>

        <ScanTimers
          fetchLocationSuccess={this.props.fetchLocationSuccess}
          currentSession={currentSession} />

        {currentSession.session !== '' && <p>Scan counter: {currentSession.scanTally}</p>}

        {this.props.currentSession.session !== '' && <Button
          className={styles.endSessionButton}
          secondary
          destructive
          disabled={this.props.endSessionButtonDisabled}
          onClick={this.props.endSession}>
          End Session
        </Button>}
      </div>
    )
  }
}
