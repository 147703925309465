// @flow

export const MANAGE_ROUTE = '/manage'
export const SEARCH_ROUTE = '/search'

export const ADMIN_NAVIGATION_PATHNAMES = {
  Splash: '/splash',
  Employee: MANAGE_ROUTE + '/employee',
  Equipment: MANAGE_ROUTE + '/equipment',
  Activity: MANAGE_ROUTE + '/activity',
  Session: MANAGE_ROUTE + '/session',
  Order: MANAGE_ROUTE + '/order',
  OrderSearch: SEARCH_ROUTE + '/order',
  EmployeeSearch: SEARCH_ROUTE + '/employee'
}

export const PRODUCTION_ADMIN = 'ProductionAdmin'
export const PRODUCTION_AGENT = 'ProductionAgent'
export const PRODUCTION_AGENT_TYPE = '0'
