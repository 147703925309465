// @flow
import scan from './scan'
import auth from './auth'
import search from './search'
import splash from './splash'
import feedback from './feedback'
import rsaa from './rsaa'
import { routerReducer } from 'react-router-redux'
import { persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import type { CombinedReducer } from 'redux'
import type { AppState, Action } from '../types'
import { RESET_APP_STATE } from '../actions/global'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: []
}

const reducers = {
  scan: scan || (() => { }),
  auth: auth || (() => { }),
  search: search || (() => { }),
  splash: splash || (() => { }),
  rsaa: rsaa || (() => { }),
  messages: feedback || (() => { }),
  router: routerReducer
}

const combinedReducers: CombinedReducer<AppState, Action> = persistCombineReducers(persistConfig, reducers)

export default function rootReducer (state: AppState, action: Action) {
  if (action.type === RESET_APP_STATE) {
    return combinedReducers({}, action)
  }

  return combinedReducers(state, action)
}
