// @flow
import type { DispatchFunc, GetStateFunc } from '../../types'
import { getTimeoutIdById } from '../../selectors/splash'

export const CLEAR_ALL_SPLASH_TIMEOUTS = 'CLEAR_ALL_SPLASH_TIMEOUTS'
export const CLEAR_SPLASH_TIMEOUT = 'CLEAR_SPLASH_TIMEOUT'
export const ADD_SPLASH_TIMEOUT = 'ADD_SPLASH_TIMEOUT'

export function clearTimeouts () {
  return {
    type: CLEAR_ALL_SPLASH_TIMEOUTS
  }
}

export function addTimeoutById (id: string, timeoutId: TimeoutID) {
  return {
    type: ADD_SPLASH_TIMEOUT,
    id,
    timeoutId
  }
}

export function clearTimeoutByIds (ids: string[]) {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    ids.forEach(id => {
      const timeoutId = getTimeoutIdById(getState(), id)

      if (timeoutId) {
        clearTimeout(timeoutId)
        dispatch(clearTimeoutById(id))
      }
    })
  }
}

function clearTimeoutById (id: string) {
  return {
    type: CLEAR_SPLASH_TIMEOUT,
    id
  }
}
