// @flow

import React, { Component } from 'react'
import styles from './Scan.module.css'
import Page from '../../components/layout/Page'
import Feedback from '../../components/layout/Feedback'
import WhitePanel from '../../components/layout/WhitePanel'
import CheckModal from './modal/checkModal'
import type { ScanViewProps } from './types'
import ScanForm from './ScanForm'
import UserOverview from './UserOverview'
import ReprintMessage from './reprint'

class ScanView extends Component<ScanViewProps> {
  render () {
    const reprintIds = this.props.orderScanCheck.reprintScanIds

    return (
      <Page title='Scan'>
        {this.props.showModal && <CheckModal
          currentSession={this.props.currentSession.session}
          scanAllOrderItems={this.props.scanAllOrderItems}
          activitySession={this.props.activitySession}
          activitySessionDontMatch={this.props.activitySessionDontMatch}
          orderScanCheck={this.props.orderScanCheck}
          confirmScan={this.props.confirmScan}
          cancelScan={this.props.cancelScan} />}
        <WhitePanel>
          <div className={styles.page}>
            <div className={`${styles.content} ${styles.feedback}`}>
              {reprintIds.length > 0 && <ReprintMessage
                className={styles.noteFeedback}
                reprintIds={reprintIds} />}
              {this.props.feedback !== '' &&
              <Feedback
                message={this.props.feedback}
                className={styles.noteFeedback}
                hasScanError={this.props.hasScanError} />}
              {this.props.showCancellationFeedback && <Feedback
                message="Order cancelled"
                className={styles.noteFeedback}
                hasScanError={true}
              />}
            </div>

            <div className={styles.contentContainer}>
              <ScanForm
                className={styles.scanForm}
                employeeId={this.props.employeeId}
                scannedBarcode={this.props.scannedBarcode}
                showBulkTextbox={this.props.showBulkTextbox}
                note={this.props.note}
                canClickButton={this.props.canClickButton}
                showCheckbox={this.props.showCheckbox}
                scanAllOrderItems={this.props.scanAllOrderItems}
                updateCheckbox={this.props.updateCheckbox}
                onAddScan={this.props.onAddScan}
                scanId={this.props.scanId}
                updateEmployeeId={this.props.updateEmployeeId}
                scanBarcode={this.props.scanBarcode}
                updateScannedBarcode={this.props.updateScannedBarcode}
                updateTotalOrders={this.props.updateTotalOrders}
                totalOrders={this.props.totalOrders}
                updateNote={this.props.updateNote} />

              {this.props.employeeId !== '' && this.props.fetchLocationSuccess && <UserOverview
                fetchLocationSuccess={this.props.fetchLocationSuccess}
                endSessionButtonDisabled={this.props.endSessionButtonDisabled}
                currentUser={this.props.currentUser}
                currentSession={this.props.currentSession}
                endSession={this.props.endSession}
              />}
            </div>
          </div>
        </WhitePanel>
      </Page>
    )
  }
}

export default ScanView
