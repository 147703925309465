// @flow
import { RSAA } from 'redux-api-middleware'
import type { DispatchFunc, GetStateFunc } from '../types'
import { checkIsRequired } from '../helpers/checkIsRequired'
import { getAllActivities } from './search'
import { getEndOfWorkFlowSessions } from '../selectors/search'

export const ADD_ACTIVITY = 'ADD_ACTIVITY'
export const ADD_ACTIVITY_SUCCESS = 'ADD_ACTIVITY_SUCCESS'
export const ADD_ACTIVITY_ERROR = 'ADD_ACTIVITY_ERROR'
export const REMOVE_ACTIVITY = 'REMOVE_ACTIVITY'
export const REMOVE_ACTIVITY_SUCCESS = 'REMOVE_ACTIVITY_SUCCESS'
export const REMOVE_ACTIVITY_ERROR = 'REMOVE_ACTIVITY_ERROR'
export const ACTIVITY_IS_REQUIRED = 'ACTIVITY_IS_REQUIRED'

export function addActivity (activityName: string, related: string[], activityType: string, isBulkScan: boolean) {
  return async (dispatch: DispatchFunc) => {
    const endpoint = process.env.REACT_APP_TRACKING_SERVICE || ''

    try {
      const result = await dispatch({
        [RSAA]: {
          endpoint: `${endpoint}/update/addActivity`,
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ activityName, related, activityType, isBulkScan }),
          types: [ ADD_ACTIVITY, ADD_ACTIVITY_SUCCESS, ADD_ACTIVITY_ERROR ]
        }
      })

      if (result.type === ADD_ACTIVITY_SUCCESS) {
        dispatch(getAllActivities())
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function removeActivity (activityName: string, activityType: string, typeName: string) {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const endpoint = process.env.REACT_APP_TRACKING_SERVICE || ''
    const endOfWorkFlowSessions = getEndOfWorkFlowSessions(getState())

    if (checkIsRequired(activityName, endOfWorkFlowSessions)) {
      dispatch({
        type: ACTIVITY_IS_REQUIRED
      })
    }

    try {
      const result = await dispatch({
        [RSAA]: {
          endpoint: `${endpoint}/update/removeActivity`,
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ activityName, activityType, typeName }),
          types: [ REMOVE_ACTIVITY, REMOVE_ACTIVITY_SUCCESS, REMOVE_ACTIVITY_ERROR ]
        }
      })

      if (result.type === REMOVE_ACTIVITY_SUCCESS) {
        dispatch(getAllActivities())
      }
    } catch (err) {
      console.log(err)
    }
  }
}
