// @flow
import type { Action } from '../../types'

export function getErrorMessage (action: Action): string {
  let message = 'Something went wrong. Please try again and contact us if the issue persists.'

  if (action.message) {
    return action.message
  }

  if (action.payload && action.payload.hasOwnProperty('response') && action.payload.response) {
    if (action.payload.response.hasOwnProperty('statusTxt')) {
      message = action.payload.response.statusTxt
    } else if (action.payload.response.hasOwnProperty('message')) {
      message = action.payload.response.message
    } else if (action.payload.hasOwnProperty('message')) {
      message = action.payload.message
    }
  }

  return message
}

export default getErrorMessage
