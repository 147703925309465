// @flow
import React, { Component } from 'react'
import type { Feedback, EmployeeSearchDetails } from '../../../../types'
import EmployeeSearchView from './EmployeeSearchView'

type Props = {|
  employeeDetails: EmployeeSearchDetails,
  searchFeedback: Feedback,
  isSuccess: boolean,
  searchForEmployee: (employeeName: string) => void,
  resetState: () => void,
  resetEmployeeSearchFeedback: () => void
|}

type State = {|
  employeeSearch: string,
  needsReset: boolean
|}

class EmployeeSearchController extends Component<Props, State> {
  state = {
    employeeSearch: '',
    needsReset: false
  }

  componentDidMount () {
    this.props.resetState()
    this.props.resetEmployeeSearchFeedback()
  }

  checkReset = () => {
    if (this.state.needsReset) {
      this.props.resetEmployeeSearchFeedback()
      this.props.resetState()
      this.setState({ needsReset: false })
    }
  }

  updateQuery = (query: string) => {
    this.checkReset()
    this.setState({ employeeSearch: query })
  }

  searchEmployee = (e: SyntheticInputEvent<HTMLInputElement>) => {
    e.preventDefault()
    const { employeeSearch } = this.state
    if (employeeSearch !== '') {
      this.props.resetEmployeeSearchFeedback()
      this.props.searchForEmployee(employeeSearch)
      this.setState({ needsReset: true })
    }
  }

  render () {
    return (
      <EmployeeSearchView
        employeeDetails={this.props.employeeDetails}
        searchFeedback={this.props.searchFeedback}
        employeeSearch={this.state.employeeSearch}
        isSuccess={this.props.isSuccess}
        updateQuery={this.updateQuery}
        searchEmployee={this.searchEmployee} />
    )
  }
}

export default EmployeeSearchController
