// @flow
import React, { Component } from 'react'
import type { ChildrenArray } from 'react'
import type { Style } from '../../../types'
import PanelTitle from '../PanelTitle/PanelTitle'
import styles from './WhitePanel.module.css'

type Props = {|
  style?: Style,
  heading?: string,
  headingStyle?: Style,
  children: ChildrenArray
|}

class WhitePanel extends Component<Props> {
  render () {
    const { style, heading, headingStyle, children } = this.props
    return (
      <div className={styles.container} style={style}>
        {heading && <PanelTitle style={headingStyle}>{heading}</PanelTitle>}
        {children}
      </div>
    )
  }
}

export default WhitePanel
