// @flow
import React, { Component } from 'react'
import Login from '../pages/Login'
import PageNotFound from './PageNotFound'
import { Router, Route, Switch } from 'react-router-dom'
import { StyleRoot } from 'radium'
import ErrorBoundary from './ErrorBoundary'
import ScrollToTop from './ScrollToTop'
import Scan from '../pages/Scan'
import Splash from '../pages/Dashboard/Splash'
import Search from '../pages/Dashboard/Search'
import Manage from '../pages/Dashboard/Manage'
import DefaultDashboard from '../pages/DefaultDashboard'
import AuthenticatedRouteGuard from './AuthenticatedRouteGuard'
import Navigation from '../components/navigation'
import { history } from '../configureStore'
import HomePage from '../components/homePage'
import { MANAGE_ROUTE, ADMIN_NAVIGATION_PATHNAMES, SEARCH_ROUTE } from '../data/auth'
import SplitWrapper from './SplitWrapper'

export default class AsyncApp extends Component<*> {
  render () {
    return (
      <StyleRoot style={{ minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
        <Router history={history}>
          <ErrorBoundary>
            <SplitWrapper>
              <ScrollToTop />
              <PageNotFound>
                <div>
                  <Switch>
                    <Route exact path='/login' component={Login} />
                    <Route exact path='/' component={HomePage} />
                  </Switch>
                </div>

                <AuthenticatedRouteGuard redirectPath='/login'>
                  <Navigation>
                    <Route exact path='/scan' component={Scan} />
                    <Route exact path='/dashboard' component={DefaultDashboard} />
                    <Route path={MANAGE_ROUTE} component={Manage}/>
                    <Route path={ADMIN_NAVIGATION_PATHNAMES.Splash} exact component={Splash}/>
                    <Route path={SEARCH_ROUTE} component={Search}/>
                  </Navigation>
                </AuthenticatedRouteGuard>

              </PageNotFound>
            </SplitWrapper>
          </ErrorBoundary>
        </Router>
      </StyleRoot>
    )
  }
}
