// @flow

// $FlowFixMe ('cannot resolve module..)
import { nanoid } from 'nanoid'
import { ANONYMOUS_ID_LOCAL_STORAGE_KEY } from '../data/constants'

// $FlowFixMe SplitIO type defined in @splitsoftware/splitio-react
export function getSplitIOConfig (): SplitIO.IBrowserSettings {
  return {
    core: {
      authorizationKey: process.env.REACT_APP_SPLIT_IO_API_KEY,
      key: getAnonymousId(),
      trafficType: 'anonymous'
    },
    debug: false
  }
}

function getAnonymousId () {
  return window.localStorage.getItem(ANONYMOUS_ID_LOCAL_STORAGE_KEY) || generateAnonymousUserId()
}

function generateAnonymousUserId () {
  const anonymousUserId = nanoid()
  window.localStorage.setItem(ANONYMOUS_ID_LOCAL_STORAGE_KEY, anonymousUserId)
  return anonymousUserId
}
