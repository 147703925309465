// @flow

import type { AppState } from '../../types'
import { connect } from 'react-redux'
import HomePage from './HomePage'
import { getIsUserAdmin } from '../../selectors/auth'

function mapStateToProps (state: AppState) {
  return {
    isAdmin: getIsUserAdmin(state)
  }
}

export default connect(mapStateToProps, null)(HomePage)
