// @flow
import { createSelector } from 'reselect'
import type { AppState, Feedback, BarcodeFeedback } from '../types'
import { identity } from './helpers'

export function selectEquipmentFeedback (state: AppState): BarcodeFeedback {
  return state.messages.equipment
}

export function selectSessionFeedback (state: AppState): BarcodeFeedback {
  return state.messages.session
}

export function selectActivityFeedback (state: AppState): BarcodeFeedback {
  return state.messages.activity
}

export function selectEmployeeFeedback (state: AppState): BarcodeFeedback {
  return state.messages.employee
}

export function selectOrderSearchResultsFeedback (state: AppState): Feedback {
  return state.messages.orderSearch
}

export function selectLabOrderFeedback (state: AppState): BarcodeFeedback {
  return state.messages.labOrder
}

export function selectEmployeeSearchFeeback (state: AppState): Feedback {
  return state.messages.employeeDetails
}

export const getEquipmentFeedback = createSelector(selectEquipmentFeedback, identity)
export const getSessionFeedback = createSelector(selectSessionFeedback, identity)
export const getActivityFeedback = createSelector(selectActivityFeedback, identity)
export const getEmployeeFeedback = createSelector(selectEmployeeFeedback, identity)
export const getEmployeeSearchFeedback = createSelector(selectEmployeeSearchFeeback, identity)
export const getOrderSearchResultsFeedback = createSelector(selectOrderSearchResultsFeedback, identity)
export const getLabOrderFeedback = createSelector(selectLabOrderFeedback, identity)
