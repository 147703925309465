// @flow
import React from 'react'
import type { Dictionary } from '../../../types'
import styles from './Manage.module.css'
import TextField from '../../../components/input/TextField'
import SelectField from '../../../components/input/SelectField'

type Props = {|
  labAlias: string,
  labs: Dictionary<string>,
  updateLabAlias: (value: string) => void
|}

export default function ChooseLabAlias ({ labAlias, labs, updateLabAlias }: Props) {
  const hasLabs = Object.keys(labs).length > 0
  return (
    <div className={`${styles.inputGroup} ${styles.addSmallPadding} `}>
      <header className={styles.inputGroupHeader}>
        <label>Lab</label>
      </header>

      {hasLabs &&
        <SelectField value={labAlias} required onChange={(e) => updateLabAlias(e.target.value)}>
          <option value={''} disabled>Please choose...</option>
          {Object.keys(labs).map(key => <option value={key} key={key}>{labs[key]}</option>)}
        </SelectField>}

      {!hasLabs && <TextField
        id='lab'
        type='text'
        pattern='^[A-Za-z ]+$'
        title='A-Z and a-z only'
        value={labAlias}
        onChange={(e) => updateLabAlias(e.target.value)}
        className={styles.searchBox}
        autoCorrect='off'
        autoCapitalize='off'
        required />}
    </div>
  )
}
