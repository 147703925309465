// @flow
import React from 'react'
import type { SearchItem } from '../../../../../../types'
import styles from './OrderHistory.module.css'
import OrderItemHistoryRow from './OrderItemHistoryRow'
import cx from 'classnames'

type Props = {|
  item: SearchItem,
  index: number,
  isLastItem: boolean
|}

export default function OrderItemHistory ({ item, index, isLastItem }: Props) {
  return (
    <div className={styles.itemTable} key={`item-history-${item.id}-${index}`}>
      <span className={styles.content}>
        <b className={styles.heading}>Item {index + 1}: </b>
      </span>
      <span className={styles.content}>
        <b className={styles.heading}>Prodigi Lab Order Item Id: </b>
        {item.prodigiLabOrderItemId}
      </span>
      <span className={styles.content}>
        <b className={styles.heading}>Production Sku: </b>
        {item.productionSku}
      </span>
      <span className={styles.content}>
        <b className={styles.heading}>Copies: </b>
        {item.copies}
      </span>

      <table className={styles.table}>
        <thead>
          <tr key={`history-${item.id}-${index}`}>
            <th className={cx(styles.header, styles.grow, styles.fixedWidth)}>Session</th>
            <th className={styles.header}>Time</th>
            <th className={styles.header}>Date</th>
            <th className={cx(styles.header, styles.name)}>Employee</th>
            <th className={cx(styles.header, styles.grow, styles.fixedWidth)}>Note</th>
          </tr>
        </thead>

        <tbody>
          {item.history.map((history, index) => <OrderItemHistoryRow key={index} history={history} keyValue={index}/>)}
        </tbody>
      </table>
      {!isLastItem && <div className={styles.orderDivider} />}
    </div>
  )
}
