// @flow

import React from 'react'
import { Redirect } from 'react-router-dom'
import { AuthPage } from '../../components/layout'
import TextField from '../../components/input/TextField'
import styles from './Login.module.css'
import Button from '../../components/button'
import Alert from '../../components/Alert'

type Props = {|
  isUserLoggedIn: boolean,
  loading: boolean,
  errorMessage: string,
  redirectPath: string,
  username: string,
  password: string,
  onSubmitLogin: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  updateUsername: (name: string) => void,
  updatePassword: (name: string) => void
|}

class LoginView extends React.Component<Props> {
  render () {
    const { errorMessage, loading } = this.props

    if (this.props.isUserLoggedIn) {
      return <Redirect to={this.props.redirectPath} />
    }

    return (
      <AuthPage>
        <form id='login-form' className={styles.form} onSubmit={this.props.onSubmitLogin}>
          {errorMessage && <Alert>{errorMessage}</Alert>}

          <div className={styles.inputGroup}>
            <label>Username</label>
            <TextField
              onChange={e => this.props.updateUsername(e.target.value)}
              value={this.props.username}
              type='email'
              id='username'
              autoCorrect='off'
              autoCapitalize='off'
              spellCheck={false}
              tabIndex='1'
            />
          </div>

          <div className={styles.inputGroup}>
            <header className={styles.inputGroupHeader}>
              <label>Password</label>
            </header>
            <TextField
              onChange={e => this.props.updatePassword(e.target.value)}
              value={this.props.password}
              type='password'
              id='password'
              tabIndex='2'
            />
          </div>
        </form>

        <Button
          primary
          loading={loading}
          type='submit'
          form='login-form'
          tabIndex='3'
        >
          Log in
        </Button>
        <a href={'https://dashboard.prodigi.com/register'} tabIndex='4' target='_blank' rel='noopener noreferrer'>
          Don&apos;t have an account? Sign up
        </a>
      </AuthPage>
    )
  }
}

export default LoginView
