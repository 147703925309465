// @flow
import React, { Component } from 'react'
import {
  USER_PREFIX,
  VALID_KEYCODES,
  SESSION_PREFIX,
  EQUIPMENT_PREFIX,
  ORDER_PREFIX,
  ACTIVITY_PREFIX
} from '../../data/scan'
import ScanView from './ScanView'
import type { ScanComponentState, ScanControllerProps } from './types'

const defaultState = {
  id: '',
  barcode: '',
  note: '',
  needsReset: false,
  scanPrefix: '',
  sessionRequired: true,
  activityScan: false,
  showModal: false,
  showCheckbox: false,
  scanAllOrderItems: false,
  scanAllConfirmed: false,
  totalOrders: 1
}

class ScanController extends Component<ScanControllerProps, ScanComponentState> {
  state = {
    ...defaultState,
    sessionConfirmed: this.props.sessionConfirmed
  }

  componentDidMount () {
    document.addEventListener('keydown', this.onKeyDown)
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.onKeyDown)
    this.props.resetScanFeedback()
    this.props.resetOrderItemScanCheck()
  }

  onKeyDown = (e: KeyboardEvent) => {
    if (e.key) {
      const key = e.key.toString()
      const { barcode, id } = this.state
      const activeElement = document.activeElement

      if (key === 'Tab' && barcode !== '' && activeElement && activeElement.id === 'barcode') {
        this.updateActivityScan(barcode)
        this.getOrderItemScanCheck(this.state.barcode, this.state.scanAllOrderItems, this.props.currentSession.session)
      } else if (key === 'Tab' && id !== '' && activeElement && activeElement.id === 'id') {
        this.props.getCurrentLocation(id)
      }
    }
  }

  checkReset = () => {
    if (this.state.needsReset) {
      this.props.resetScanFeedback()
      this.props.resetOrderItemScanCheck()
      this.props.resetActivitySessionCheck()
      this.setState({ needsReset: false })
    }
  }

  endSession = () => {
    this.props.endSession(this.state.id)
    this.setState({
      ...defaultState,
      needsReset: true,
      sessionConfirmed: this.props.sessionConfirmed
    })
    this.tabToNextElement('id')
  }

  updateCheckbox = (e: SyntheticEvent<HTMLInputElement>) => {
    const newState = !this.state.scanAllOrderItems

    this.setState({ scanAllOrderItems: newState })
    this.getOrderItemScanCheck(this.state.barcode, newState, this.props.currentSession.session)
  }

  updateTotalOrders = (total: number) => this.setState({ totalOrders: total })

  scanBarcode = (e: KeyboardEvent) => {
    e.preventDefault()
    const { scanning, startScan, stopScan } = this.props
    const { barcode } = this.state
    const key = e.key.toString()

    if (VALID_KEYCODES.includes(key) && !scanning && barcode === '') {
      startScan()
      this.checkIsSessionRequired(key)
      this.setState({ barcode: barcode.concat(key), scanPrefix: key })
      this.checkReset()
      this.updateShowCheckbox(key)
    }

    if (scanning) {
      if (key === 'Enter') {
        e.preventDefault()
        stopScan()
        this.tabToNextElement('note')
        this.updateActivityScan(this.state.barcode)
        this.getOrderItemScanCheck(this.state.barcode, this.state.scanAllOrderItems, this.props.currentSession.session)
      } else {
        barcode
          ? this.setState({ barcode: barcode.concat(key) })
          : this.setState({ barcode: key })
      }
    }
  }

  getOrderItemScanCheck = (barcode: string, orderItems: boolean, session: string) => {
    const prefix = barcode.charAt(0)
    if (barcode !== '' && prefix === ORDER_PREFIX) {
      this.props.getOrderItemScanCheck(barcode, orderItems, session)
    }
  }

  scanId = (e: KeyboardEvent) => {
    e.preventDefault()
    const { scanning, startScan, stopScan } = this.props
    this.checkReset()
    const { id } = this.state
    const key = e.key.toString()

    if ((key === USER_PREFIX) && !scanning && id === '') {
      startScan()
      this.setState({ id: id.concat(key) })
    }

    if (scanning) {
      if (key === 'Enter') {
        e.preventDefault()
        stopScan()
        this.props.getCurrentLocation(id)
        this.tabToNextElement('barcode')
      } else {
        id
          ? this.setState({ id: id.concat(key) })
          : this.setState({ id: key })
      }
    }
  }

  tabToNextElement = (nextElementId: string) => {
    const nextElement = document.getElementById(nextElementId)

    if (nextElement) {
      nextElement.focus()
    }
  }

  onAddScan = (e: SyntheticInputEvent<HTMLInputElement>) => {
    e.preventDefault()
    const { scanPrefix, sessionConfirmed, scanAllConfirmed, scanAllOrderItems } = this.state
    const canAdd = this.canAddScan

    const activitySessionDontMatch = this.activitySessionDontMatch

    const orderCancelled = scanPrefix === ORDER_PREFIX && this.props.orderScanCheck.isCancelled
    const scanCheckPassed = this.props.orderScanCheck.duplicateScanIds.length === 0 &&
      this.props.orderScanCheck.possibleReprintScanIds.length === 0 && !orderCancelled

    if (canAdd) {
      if (scanPrefix === SESSION_PREFIX || scanPrefix === EQUIPMENT_PREFIX) {
        this.sendToDatabase()
      } else if (this.props.currentSession.session === '' && scanPrefix !== ACTIVITY_PREFIX) {
        this.props.notScannedIntoSession()
        this.setState({ needsReset: true })
      } else if (sessionConfirmed && scanPrefix === ORDER_PREFIX && scanAllConfirmed && scanAllOrderItems && scanCheckPassed) {
        this.sendToDatabase()
      } else if (sessionConfirmed && !scanAllOrderItems && !activitySessionDontMatch && scanCheckPassed) {
        this.sendToDatabase()
      } else if (!orderCancelled) {
        this.setState({ showModal: true })
      }
    }
  }

  sendToDatabase = () => {
    const { id, barcode, note, sessionConfirmed, scanPrefix, scanAllOrderItems, scanAllConfirmed } = this.state

    const activitySessionDontMatch = this.activitySessionDontMatch

    if (sessionConfirmed && scanPrefix === ORDER_PREFIX && scanAllConfirmed) {
      this.props.addScan(id, barcode, note, scanAllOrderItems)
      this.setState({ barcode: '', note: '', needsReset: true, sessionRequired: true })
      this.setState({ scanAllOrderItems: false, showCheckbox: false, scanAllConfirmed: false, scanPrefix: '' })
      this.tabToNextElement('barcode')
    } else if (this.showBulkTextbox) {
      this.props.addBulkScan(id, barcode, note, this.state.totalOrders)
      this.setState({ barcode: '', note: '', needsReset: true, totalOrders: 1 })
      this.setState({ sessionRequired: true, showCheckbox: false, activityScan: false, scanPrefix: '' })
      this.tabToNextElement('barcode')
    } else if (sessionConfirmed || activitySessionDontMatch || scanPrefix === SESSION_PREFIX || scanPrefix === EQUIPMENT_PREFIX) {
      this.props.addScan(id, barcode, note, false)
      this.setState({ barcode: '', note: '', needsReset: true, sessionRequired: true, showCheckbox: false, activityScan: false, scanPrefix: '' })
      this.tabToNextElement('barcode')
    }

    if (scanPrefix === ORDER_PREFIX) {
      this.props.resetOrderItemScanCheck()
    } else if (scanPrefix === SESSION_PREFIX) {
      this.props.resetSessionConfirmed()
      this.setState({ sessionConfirmed: false })
    }
  }

  updateEmployeeId = (id: string) => {
    this.checkReset()
    this.props.resetSessionConfirmed()

    const firstChar = id.charAt(0)

    if (id === '') {
      this.props.stopScan()
      this.setState({ id, sessionConfirmed: false })
    } else if (firstChar === USER_PREFIX) {
      this.props.startScan()
      this.setState({ id, sessionConfirmed: false })
      this.props.stopScan()

      this.props.getCurrentLocation(id)
    }
  }

  updateScannedBarcode = (barcode: string) => {
    this.checkReset()
    const firstChar = barcode.charAt(0)

    if (firstChar !== ORDER_PREFIX) {
      this.props.resetOrderItemScanCheck()
    }

    if (barcode === '') {
      this.props.stopScan()

      this.setState({ scanPrefix: '', barcode, sessionRequired: true, showCheckbox: false, activityScan: false })
    } else if (VALID_KEYCODES.includes(firstChar)) {
      this.props.startScan()
      this.checkIsSessionRequired(firstChar)
      this.setState({ barcode, scanPrefix: firstChar })
      this.props.stopScan()

      this.updateActivityScan(barcode)
      this.updateShowCheckbox(firstChar)
      this.getOrderItemScanCheck(barcode, this.state.scanAllOrderItems, this.props.currentSession.session)
    }
  }

  updateShowCheckbox = (prefix: string) => {
    if (prefix === ORDER_PREFIX) {
      this.setState({ showCheckbox: true })
    } else {
      this.setState({ showCheckbox: false })
    }
  }

  updateActivityScan = (barcode: string) => {
    const prefix = barcode.charAt(0)

    if (prefix === ACTIVITY_PREFIX) {
      this.props.getActivitySession(barcode)
      this.setState({ activityScan: true })
    } else {
      this.setState({ activityScan: false })
    }
  }

  checkIsSessionRequired = (scanPrefix: string) => {
    if (scanPrefix === SESSION_PREFIX || scanPrefix === EQUIPMENT_PREFIX) {
      this.setState({ sessionRequired: false })
    }
  }

  updateNote = (note: string) => {
    this.checkReset()
    this.setState({ note })
  }

  confirmScan = () => {
    const { showCheckbox } = this.state
    const activitySessionDontMatch = this.activitySessionDontMatch

    if (showCheckbox) {
      this.setState({ scanAllConfirmed: true })
    }

    if (!activitySessionDontMatch) {
      this.setState({ showModal: false, sessionConfirmed: true }, this.sendToDatabase)
      this.props.setSessionConfirmed()
    } else {
      this.setState({ showModal: false }, this.sendToDatabase)
    }
  }

  cancelScan = () => {
    this.setState({ showModal: false })
  }

  get canAddScan (): boolean {
    const formComplete = this.state.barcode !== '' && this.state.id !== ''
    const required = this.props.canClickButton || !this.state.sessionRequired

    if (this.state.scanPrefix === ORDER_PREFIX) {
      const isOrderScanComplete = !this.props.orderItemScanCheckRsaaStatus.loading

      return Boolean(required && formComplete && isOrderScanComplete && !this.props.orderScanCheck.isCancelled)
    } else if (this.state.scanPrefix === ACTIVITY_PREFIX) {
      const isActivityScanComplete = !this.props.activityRsaaStatus.loading && this.state.activityScan

      return Boolean(required && formComplete && isActivityScanComplete)
    }

    return Boolean(required && formComplete)
  }

  get activitySessionDontMatch (): boolean {
    const { activityScan } = this.state
    const { activitySession, currentSession } = this.props

    const { session } = activitySession

    return activityScan && session !== '' && session !== currentSession.session
  }

  get showBulkTextbox (): boolean {
    return this.state.scanPrefix === ACTIVITY_PREFIX &&
      this.props.activitySession.isBulkScan && this.state.barcode !== '' &&
      this.state.activityScan && !this.props.activityRsaaStatus.loading
  }

  get showCancellationFeedback (): boolean {
    return this.state.scanPrefix === ORDER_PREFIX &&
      this.props.orderItemScanCheckRsaaStatus.success &&
      this.props.orderScanCheck.isCancelled
  }

  render () {
    return (
      <ScanView
        endSessionButtonDisabled={this.props.endSessionButtonDisabled}
        feedback={this.props.feedback}
        className={this.props.className}
        fetchLocationSuccess={this.props.fetchLocationSuccess}
        canClickButton={this.canAddScan}
        hasScanError={this.props.hasScanError}
        onAddScan={this.onAddScan}
        showModal={this.state.showModal}
        activitySession={this.props.activitySession}
        currentUser={this.props.currentUser}
        currentSession={this.props.currentSession}
        activitySessionDontMatch={this.activitySessionDontMatch}
        showBulkTextbox={this.showBulkTextbox}
        totalOrders={this.state.totalOrders}
        updateTotalOrders={this.updateTotalOrders}
        orderScanCheck={this.props.orderScanCheck}
        showCancellationFeedback={this.showCancellationFeedback}
        scanId={this.scanId}
        updateEmployeeId={this.updateEmployeeId}
        employeeId={this.state.id}
        scanBarcode={this.scanBarcode}
        updateScannedBarcode={this.updateScannedBarcode}
        scannedBarcode={this.state.barcode}
        updateNote={this.updateNote}
        note={this.state.note}
        confirmScan={this.confirmScan}
        cancelScan={this.cancelScan}
        updateCheckbox={this.updateCheckbox}
        scanAllOrderItems={this.state.scanAllOrderItems}
        endSession={this.endSession}
        showCheckbox={this.state.showCheckbox} />
    )
  }
}

export default ScanController
