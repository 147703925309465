// @flow
import React, { Component } from 'react'
import styles from './Feedback.module.css'
import cx from 'classnames'
import Alert from '../../Alert'
import type { BarcodeDetails } from '../../../types'

type Props = {|
  className?: string,
  message: string,
  hasScanError: boolean,
  barcodes?: BarcodeDetails[]
|}

class Feedback extends Component<Props> {
  render () {
    const { hasScanError, barcodes, message } = this.props

    const isAdditionSuccess = !hasScanError && message.toLowerCase().includes('added')

    const existingBarcodes = barcodes ? barcodes.filter(x => x.alreadyExisted) : []
    const newBarcodes = barcodes ? barcodes.filter(x => !x.alreadyExisted) : []

    const addedMessage = newBarcodes.length > 1
      ? 'The following barcodes were added:'
      : 'The following barcode was added:'

    const existingMessage = existingBarcodes.length > 1
      ? 'The following barcodes already existed:'
      : 'The following barcode already existed:'

    return (
      <Alert
        className={cx(styles.container, this.props.className)}
        type={hasScanError ? 'error' : 'success'}>
        <React.Fragment>
          <div className={styles.message}>
            {message}
          </div>

          {isAdditionSuccess && newBarcodes.length > 0 && <div className={styles.addMargin}>
            <p className={styles.barcode}>{addedMessage}</p>
            {newBarcodes.map(details =>
              <p className={styles.barcode} key={details.barcode}>{details.barcodeType} = {details.barcode}</p>
            )}
          </div>}

          {isAdditionSuccess && existingBarcodes.length > 0 && <div className={styles.addMargin}>
            <p className={styles.barcode}>{existingMessage}</p>
            {existingBarcodes.map(details =>
              <p className={styles.barcode} key={details.barcode}>{details.barcodeType} = {details.barcode}</p>
            )}

          </div>}
        </React.Fragment>
      </Alert>
    )
  }
}

export default Feedback
