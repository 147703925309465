// @flow
import { connect } from 'react-redux'
import { compose } from 'redux'
import ScanController from './ScanController'
import type {
  AppState,
  DispatchFunc,
  BarcodeFeedback,
  SessionActivitySearch,
  CurrentSession,
  RsaaStatusProps,
  OrderScanCheck
} from '../../types'
import { getIsScanningBarcode, getScanFeedback, getIsScanError, getSessionConfirmed } from '../../selectors/scan'
import { getCurrentSession, getCurrentUser, getCurrentActivitySession, getOrderItemScanCheckResult } from '../../selectors/search'
import { getRsaaStatusProps } from '../../selectors/rsaa'
import {
  startScan,
  addScan,
  addBulkScan,
  stopScan,
  resetScanFeedback,
  sessionConfirmed,
  resetSessionConfirmed,
  endSession,
  notScannedIntoSession
} from '../../actions/scan'
import { getCurrentLocation, getActivitySession, getScanCheck, resetOrderItemScanCheck, resetActivitySessionCheck } from '../../actions/search'
import WithLabAlias from '../../components/hoc/WithLabAlias'

type StateProps = {|
  scanning: boolean,
  feedback: BarcodeFeedback,
  hasScanError: boolean,
  fetchLocationSuccess: boolean,
  sessionConfirmed: boolean,
  endSessionButtonDisabled: boolean,
  orderItemScanCheckRsaaStatus: RsaaStatusProps,
  activityRsaaStatus: RsaaStatusProps,
  currentUser: string,
  currentSession: CurrentSession,
  activitySession: SessionActivitySearch,
  canClickButton: boolean,
  orderScanCheck: OrderScanCheck
|}

function mapStateToProps (state: AppState): StateProps {
  const sessionRsaaStatus = getRsaaStatusProps(state, 'search', 'currentLocation')
  const activityRsaaStatus = getRsaaStatusProps(state, 'search', 'activitySession')
  const orderItemScanCheckRsaaStatus = getRsaaStatusProps(state, 'search', 'orderItemScanCheck')
  const endSessionRsaaStatus = getRsaaStatusProps(state, 'scan', 'endUserSession')
  const scanRsaaStatus = getRsaaStatusProps(state, 'scan', 'addNewScan')

  return {
    scanning: getIsScanningBarcode(state),
    feedback: getScanFeedback(state),
    fetchLocationSuccess: sessionRsaaStatus.success,
    endSessionButtonDisabled: endSessionRsaaStatus.loading || scanRsaaStatus.loading,
    hasScanError: getIsScanError(state),
    sessionConfirmed: getSessionConfirmed(state),
    currentUser: getCurrentUser(state),
    currentSession: getCurrentSession(state),
    activitySession: getCurrentActivitySession(state),
    orderScanCheck: getOrderItemScanCheckResult(state),
    orderItemScanCheckRsaaStatus,
    activityRsaaStatus,
    canClickButton: !orderItemScanCheckRsaaStatus.loading && !sessionRsaaStatus.loading &&
      !activityRsaaStatus.loading && !endSessionRsaaStatus.loading
  }
}

function mapDispatchToProps (dispatch: DispatchFunc) {
  return {
    startScan: () => dispatch(startScan()),
    stopScan: () => dispatch(stopScan()),
    setSessionConfirmed: () => dispatch(sessionConfirmed()),
    resetSessionConfirmed: () => dispatch(resetSessionConfirmed()),
    notScannedIntoSession: () => dispatch(notScannedIntoSession()),
    getCurrentLocation: (barcode: string) => dispatch(getCurrentLocation(barcode)),
    addScan: (id: string, barcode: string, note: string, scanAllOrderItems: boolean) =>
      dispatch(addScan(id, barcode, note, scanAllOrderItems)),
    addBulkScan: (id: string, barcode: string, note: string, total: number) =>
      dispatch(addBulkScan(id, barcode, note, total)),
    getActivitySession: (barcode: string) => dispatch(getActivitySession(barcode)),
    resetActivitySessionCheck: () => dispatch(resetActivitySessionCheck()),
    resetScanFeedback: () => dispatch(resetScanFeedback()),
    resetOrderItemScanCheck: () => dispatch(resetOrderItemScanCheck()),
    getOrderItemScanCheck: (barcode: string, scanAllOrderItems: boolean, currentSession: string) =>
      dispatch(getScanCheck(barcode, scanAllOrderItems, currentSession)),
    endSession: (employeeId: string) => dispatch(endSession(employeeId))
  }
}

export default compose(
  WithLabAlias,
  connect(mapStateToProps, mapDispatchToProps)
)(ScanController)
