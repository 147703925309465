// @flow
import React from 'react'
import type { SessionActivitySearch, OrderScanCheck } from '../../../types'
import styles from './Modal.module.css'

export function mapScanModalMessage (
  currentSession: string,
  scanAllOrderItems: boolean,
  activitySessionDontMatch: boolean,
  orderScanCheck: OrderScanCheck,
  activitySession: SessionActivitySearch
) {
  const { duplicateScanIds, possibleReprintScanIds } = orderScanCheck

  if (possibleReprintScanIds.length > 0) {
    return reprintScanMessage(possibleReprintScanIds, currentSession)
  } else if (duplicateScanIds.length > 0) {
    return duplicateScanMessage(duplicateScanIds, currentSession)
  } else if (activitySessionDontMatch) {
    return activityDontMatchMessage(activitySession)
  } else if (scanAllOrderItems) {
    return scanAllOrderItemsMessage(currentSession)
  } else {
    return <div className={styles.modalMessage}>
      <p> Are you scanned into the right session?</p>
    </div>
  }
}

function duplicateScanMessage (duplicateScanIds: string[], currentSession: string) {
  const startMessage = duplicateScanIds.length > 1
    ? `Order items ${duplicateScanIds.join(', ')} were`
    : `Order item ${duplicateScanIds.join(', ')} was`

  return <div className={styles.modalMessage}>
    <p>{startMessage} last scanned into {currentSession}.</p>
    <p className={styles.modalMessage}> Are you sure you want to continue?</p>
  </div>
}

function reprintScanMessage (possibleReprintScanIds: string[], currentSession: string) {
  const startMessage = possibleReprintScanIds.length > 1
    ? `Order items ${possibleReprintScanIds.join(', ')} have`
    : `Order item ${possibleReprintScanIds.join(', ')} has`

  const endMessage = possibleReprintScanIds.length > 1
    ? 'these order items as reprints.'
    : 'this order item as a reprint.'

  const extraMessage = possibleReprintScanIds.length > 1
    ? ' Any scans for these order items before these scans'
    : ' Any scans for this order item before this scan'

  return <div className={styles.modalMessage}>
    <p>{startMessage} have already been scanned into {currentSession}.</p>
    <p className={styles.modalMessage}>
      Continuing this scan will class {endMessage}
      {extraMessage} will no longer be shown in the <span className={styles.modalItalics}>Order Items Completed In The Last Hour</span>
    </p>
    <p className={styles.modalMessage}> Are you sure you want to continue?</p>
  </div>
}

function activityDontMatchMessage (activitySession: SessionActivitySearch) {
  const { activity, session } = activitySession

  return <div className={styles.modalMessage}>
    <p>The activity barcode you have scanned is for {session} ({activity}).
    Continuing this scan will also update your current session to {session}.</p>
    <p className={styles.modalMessage}> Are you sure you want to continue?</p>
  </div>
}

function scanAllOrderItemsMessage (currentSession: string) {
  return <div className={styles.modalMessage}>
    <p>Are you sure you want to scan all items on this worksheet to {currentSession}</p>
  </div>
}
