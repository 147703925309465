// @flow
import { RSAA } from 'redux-api-middleware'
import type { DispatchFunc, ThunkAsync, SessionDetails, GetStateFunc } from '../types'
import { getSessionTypes } from './search'
import { checkIsRequired } from '../helpers/checkIsRequired'
import { getEndOfWorkFlowSessions } from '../selectors/search'

export const ADD_SESSION = 'ADD_SESSION'
export const ADD_SESSION_SUCCESS = 'ADD_SESSION_SUCCESS'
export const ADD_SESSION_ERROR = 'ADD_SESSION_ERROR'
export const REMOVE_SESSION = 'REMOVE_SESSION'
export const REMOVE_SESSION_SUCCESS = 'REMOVE_SESSION_SUCCESS'
export const REMOVE_SESSION_ERROR = 'REMOVE_SESSION_ERROR'
export const FETCH_SESSION = 'FETCH_SESSION'
export const FETCH_SESSION_SUCCESS = 'FETCH_SESSION_SUCCESS'
export const FETCH_SESSION_ERROR = 'FETCH_SESSION_ERROR'
export const UPDATE_SESSION_DETAILS = 'UPDATE_SESSION_DETAILS'
export const UPDATE_SESSION = 'UPDATE_SESSION'
export const UPDATE_SESSION_SUCCESS = 'UPDATE_SESSION_SUCCESS'
export const UPDATE_SESSION_ERROR = 'UPDATE_SESSION_ERROR'
export const SESSION_IS_REQUIRED = 'SESSION_IS_REQUIRED'
export const MAX_SESSION_TIME_ELAPSED = 'MAX_SESSION_TIME_ELAPSED'

export function maxSessionTimeElapsed () {
  return {
    type: MAX_SESSION_TIME_ELAPSED
  }
}

export function addSession (sessionName: string, relatedActivities: string[], itemsPerHour: number, isEndOfWorkflow: boolean) {
  return async (dispatch: DispatchFunc) => {
    const endpoint = process.env.REACT_APP_TRACKING_SERVICE || ''

    try {
      const addSessionResult = await dispatch({
        [RSAA]: {
          endpoint: `${endpoint}/update/addSession`,
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ sessionName, relatedActivities, itemsPerHour, isEndOfWorkflow }),
          types: [ ADD_SESSION, ADD_SESSION_SUCCESS, ADD_SESSION_ERROR ]
        }
      })

      if (addSessionResult.type === ADD_SESSION_SUCCESS) {
        await dispatch(getSessionTypes())
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function removeSession (sessionName: string) {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const endpoint = process.env.REACT_APP_TRACKING_SERVICE || ''
    const endOfWorkFlowSessions = getEndOfWorkFlowSessions(getState())

    if (checkIsRequired(sessionName, endOfWorkFlowSessions)) {
      dispatch({
        type: SESSION_IS_REQUIRED
      })
    }

    try {
      const removeSessionResult = await dispatch({
        [RSAA]: {
          endpoint: `${endpoint}/update/removeSession`,
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ sessionName }),
          types: [ REMOVE_SESSION, REMOVE_SESSION_SUCCESS, REMOVE_SESSION_ERROR ]
        }
      })

      if (removeSessionResult.type === REMOVE_SESSION_SUCCESS) {
        await dispatch(getSessionTypes())
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function searchForSession (sessionName: string): ThunkAsync<?SessionDetails> {
  return async (dispatch: DispatchFunc) => {
    const endpoint = process.env.REACT_APP_TRACKING_SERVICE || ''

    await dispatch({
      [RSAA]: {
        endpoint: `${endpoint}/search/sessionSearch?sessionName=${sessionName}`,
        method: 'GET',
        types: [ FETCH_SESSION, FETCH_SESSION_SUCCESS, FETCH_SESSION_ERROR ]
      }
    })
  }
}

export function updateSession (details: SessionDetails) {
  return async (dispatch: DispatchFunc) => {
    const endpoint = process.env.REACT_APP_TRACKING_SERVICE || ''

    try {
      const updateSessionResult = await dispatch({
        [RSAA]: {
          endpoint: `${endpoint}/update/session`,
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(details),
          types: [ UPDATE_SESSION, UPDATE_SESSION_SUCCESS, UPDATE_SESSION_ERROR ]
        }
      })

      if (updateSessionResult.type === UPDATE_SESSION_SUCCESS) {
        await dispatch(getSessionTypes())
      }
    } catch (err) {
      console.log(err)
    }
  }
}
