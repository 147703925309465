// @flow
export const RSAA_ACTION_NAME = '@@redux-api-middleware/RSAA'

export const IDLE = 'IDLE'
export const LOADING = 'LOADING'
export const ERROR = 'ERROR'
export const SUCCESS = 'SUCCESS'

export const RSAA_STATUS = {
  IDLE,
  LOADING,
  ERROR,
  SUCCESS
}

export const ABORT_IN_FLIGHT_REQUESTS = 'ABORT_IN_FLIGHT_REQUESTS'
export const LOG_STATUS = 'LOG_STATUS'

export const RSAA_ENHANCEMENTS = {
  ABORT_IN_FLIGHT_REQUESTS,
  LOG_STATUS
}
