// @flow

export const OK = 200
export const PAYMENT_REQUIRED = 402
export const UNAUTHORIZED = 401
export const FORBIDDEN = 403
export const NOT_FOUND = 404
export const GONE = 410

export const STATUS_CODES = {
  OK,
  UNAUTHORIZED,
  FORBIDDEN,
  PAYMENT_REQUIRED,
  NOT_FOUND,
  GONE
}
