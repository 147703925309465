// @flow
import AuthenticatedRouteGuard from './AuthenticatedRouteGuard'
import { connect } from 'react-redux'
import { getIsUserAdmin, getIsUserLoggedIn } from '../../selectors/auth'
import { withRouter } from 'react-router'
import { compose } from 'redux'
import { refreshAccessToken, REFRESH_ACCESS_TOKEN_SUCCESS } from '../../actions/auth/refreshAccessToken'
import WithLoadingIndicator from '../../components/hoc/WithLoadingIndicator'
import RefreshingToken from './RefreshingToken'
import WithAsyncDataFetcher from '../../components/hoc/WithAsyncDataFetcher'
import type { AppState, DispatchFunc, Thunk } from '../../types'

type RouteGuardStateProps = {|
  isAdmin: boolean,
  isUserLoggedIn: boolean
|}

type FetchDataAsync = {|
  fetchDataAsync: () => Thunk<void>
|}

function mapStateToProps (state: AppState): RouteGuardStateProps {
  return {
    isUserLoggedIn: getIsUserLoggedIn(state),
    isAdmin: getIsUserAdmin(state)
  }
}

function wrapFetchDataAsyncWithDispatch (dispatch: DispatchFunc): FetchDataAsync {
  return {
    fetchDataAsync: () => dispatch(refreshAccessToken())
  }
}

export default compose(
  connect<*, *>(null, wrapFetchDataAsyncWithDispatch),
  WithAsyncDataFetcher('refreshToken', REFRESH_ACCESS_TOKEN_SUCCESS),
  WithLoadingIndicator('refreshTokenLoading')(RefreshingToken),
  connect(mapStateToProps),
  withRouter
)(AuthenticatedRouteGuard)
