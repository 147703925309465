// @flow

import React from 'react'
import Tippy from '@tippy.js/react'
import { NavLink } from 'react-router-dom'
import FontAwesome from 'react-fontawesome'
import styles from './Navigation.module.css'
import cx from 'classnames'

type Props = {|
  title: string,
  path: string,
  isExternal?: boolean,
  isHighlighted?: boolean,
  iconName: string,
  onClick?: (e: SyntheticInputEvent<HTMLInputElement>) => void
|}

function NavigationItem ({ title, path, isExternal = false, isHighlighted, iconName, ...otherProps }: Props) {
  function LinkEl (props) {
    return isExternal
    // eslint-disable-next-line jsx-a11y/anchor-has-content
      ? <a href={path} target='_blank' rel='noopener noreferrer' {...props} />
      : <NavLink to={path} {...props} activeClassName={styles.selected} />
  }

  function Content (props) {
    return <LinkEl
      className={cx(styles.listItem, {
        [styles.listItemHighlighted]: isHighlighted
      })}
      {...otherProps}
    >
      <FontAwesome className={styles.listItemIcon} name={iconName} size='lg' />
      <span className={styles.listItemTitle}>{title}</span>
    </LinkEl>
  }

  return (
    <div className={styles.navItem}>
      <Tippy content={title} className={styles.tooltip} placement='right' boundary='window' arrow={false}>
        <span tabIndex='0'> {/* Hack to fix tooltip - https://github.com/atomiks/tippy.js-react#component-children */}
          <Content />
        </span>
      </Tippy>
    </div>
  )
}

export default NavigationItem
