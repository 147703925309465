// @flow
import { connect } from 'react-redux'
import type { AppState, DispatchFunc, SessionDetails, BarcodeFeedback } from '../../../../types'
import { removeSession, addSession, searchForSession, updateSession } from '../../../../actions/session'
import { getActivityTypes, getSessionTypes, resetState } from '../../../../actions/search'
import { getActivityNames, getSessionNames, getSessionDetails } from '../../../../selectors/search'
import { getSessionFeedback } from '../../../../selectors/feedback'
import SessionController from './SessionController'
import { resetFeedback } from '../../../../actions/global'
import { getRsaaStatusProps } from '../../../../selectors/rsaa'

type StateProps = {|
  activityNames: string[],
  sessionNames: string[],
  sessionDetails: SessionDetails,
  feedback: BarcodeFeedback,
  fetchDetailsSuccess: boolean
|}

function mapStateToProps (state: AppState): StateProps {
  const rsaaStatus = getRsaaStatusProps(state, 'search', 'sessionDetails')
  return {
    activityNames: getActivityNames(state),
    sessionNames: getSessionNames(state),
    sessionDetails: getSessionDetails(state),
    feedback: getSessionFeedback(state),
    fetchDetailsSuccess: rsaaStatus.success
  }
}

function mapDispatchToProps (dispatch: DispatchFunc) {
  return {
    removeSessionFromDatabase: (sessionName: string) =>
      dispatch(removeSession(sessionName)),
    addSessionToDatabase: (sessionName: string, relatedActivities: string[], itemsPerHour: number, isEndOfWorkflow: boolean) =>
      dispatch(addSession(sessionName, relatedActivities, itemsPerHour, isEndOfWorkflow)),
    getActivityTypes: (activityType: string) =>
      dispatch(getActivityTypes(activityType)),
    getSessionTypes: () =>
      dispatch(getSessionTypes()),
    searchForSession: (sessionQuery: string) =>
      dispatch(searchForSession(sessionQuery)),
    resetState: () => dispatch(resetState()),
    updateSession: (details: SessionDetails) => {
      dispatch(updateSession(details))
    },
    resetFeedback: () =>
      dispatch(resetFeedback())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionController)
