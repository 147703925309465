// @flow
import React, { Component } from 'react'
import { EQUIPMENT, SESSION } from '../../../../../../data/scan'
import AddActivity from './AddActivity'

type Props = {|
  addActivityToDatabase: (activityName: string, related: string[], activityType: string, isBulkScan: boolean) => void,
  resetFeedback: () => void,
  sessionNames: string[],
  equipmentNames: string[]
|}

type State = {|
  type: string,
  activity: string,
  choice: string,
  optionName: string,
  related: string[],
  needsReset: boolean,
  isBulkScan: boolean
|}

function selectInitialType (props: Props) {
  return props.sessionNames.length > 0
    ? SESSION
    : EQUIPMENT
}

export default class AddActivityController extends Component<Props, State> {
  state = {
    type: selectInitialType(this.props),
    activity: '',
    choice: '',
    related: [],
    optionName: '',
    needsReset: false,
    isBulkScan: false
  }

  checkReset = () => {
    if (this.state.needsReset) {
      this.props.resetFeedback()
      this.setState({ needsReset: false })
    }
  }

  addActivity = (e: any) => {
    e.preventDefault()
    const { related, activity, type, isBulkScan } = this.state
    if (related.length !== 0 && activity !== '') {
      const bulkActivity = type === SESSION ? isBulkScan : false
      this.props.addActivityToDatabase(activity, related, type, bulkActivity)
    }
    this.setState({ related: [], activity: '', needsReset: true })
  }

  updateAdd = (value: string) => {
    this.checkReset()
    this.setState({ activity: value })
  }

  updateChoice = (value: string) => {
    this.checkReset()
    this.setState({ choice: value })
  }

  updateAddType = (e: any) => {
    this.checkReset()
    this.setState({ type: e.target.value, choice: '', related: [] })
  }

  addRelated = (e: any) => {
    e.preventDefault()
    this.checkReset()
    const { choice, related } = this.state
    if (!related.includes(choice)) {
      this.setState({ choice: '', related: [...related, choice] })
    } else {
      this.setState({ choice: '' })
    }
  }

  updateIsBulkScan = (e: SyntheticEvent<HTMLInputElement>) => {
    const newState = !this.state.isBulkScan
    this.setState({ isBulkScan: newState })
  }

  render () {
    return (
      <AddActivity
        isBulkScan={this.state.isBulkScan}
        updateIsBulkScan={this.updateIsBulkScan}
        activity={this.state.activity}
        addActivity={this.addActivity}
        sessionNames={this.props.sessionNames}
        equipmentNames={this.props.equipmentNames}
        updateChoice={this.updateChoice}
        updateAdd={this.updateAdd}
        addRelated={this.addRelated}
        updateType={this.updateAddType}
        choice={this.state.choice}
        type={this.state.type}
        related={this.state.related}
      />
    )
  }
}
