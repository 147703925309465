// @flow
import React from 'react'
import Button from '../../../../../../components/button'
import WhitePanel from '../../../../../../components/layout/WhitePanel'
import styles from '../../../Manage.module.css'
import TextField from '../../../../../../components/input/TextField'
import RelatedSessionForm from '../../../RelatedForm/RelatedSessionForm'
import RelatedEquipmentForm from '../../../RelatedForm/RelatedEquipmentForm'
import SelectType from '../SelectType'
import { SESSION } from '../../../../../../data/scan'

type Props = {|
  updateIsBulkScan: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  isBulkScan: boolean,
  activity: string,
  addActivity: (e: any) => void,
  sessionNames: string[],
  equipmentNames: string[],
  updateChoice: (value: string) => void,
  updateAdd: (value: string) => void,
  addRelated: (e: Event) => void,
  updateType: (e: Event) => void,
  choice: string,
  type: string,
  related: string[]
|}

export default function AddActivity (props: Props) {
  const { sessionNames, equipmentNames, updateChoice, related, choice, addRelated, type } = props
  const hasSessionNames = sessionNames.length > 0
  const hasEquipmentNames = equipmentNames.length > 0
  const disableButton = props.activity === '' || related.length === 0

  return (
    <div>
      <div className={styles.addPadding}>
        <WhitePanel heading='Add Activity'>
          <form id='add-form' onSubmit={props.addActivity}>
            <div className={styles.content}>
              <div className={styles.inputGroup}>
                <header className={styles.inputGroupHeader}>
                  <label>Activity Name</label>
                </header>
                <TextField
                  id='activityName'
                  type='text'
                  pattern='^[A-Za-z /]+$'
                  title='A-Z, a-z and / only'
                  value={props.activity}
                  onChange={(e) => props.updateAdd(e.target.value)}
                  className={styles.searchBox}
                  autoCorrect='off'
                  autoCapitalize='off'
                  required
                />
              </div>

              <SelectType type={props.type} updateType={props.updateType} hasSessionNames={hasSessionNames} hasEquipmentNames={hasEquipmentNames} />

              {type === SESSION && <div className={`${styles.checkboxGroup} ${styles.addSmallPadding} `}>
                <header className={styles.inputGroupHeader}>
                  <label>Enable bulk scans</label>
                </header>
                <input
                  id='isBulkScan'
                  type='checkbox'
                  className={styles.completed}
                  value={props.isBulkScan}
                  onChange={props.updateIsBulkScan}
                  checked={props.isBulkScan} />
              </div>}

              {type === SESSION && hasSessionNames && <RelatedSessionForm
                sessionNames={sessionNames}
                updateSessionChoice={updateChoice}
                addRelatedSession={addRelated}
                session={choice}
                relatedSessions={related} />}

              {type !== SESSION && hasEquipmentNames && <RelatedEquipmentForm
                equipmentNames={equipmentNames}
                updateEquipmentChoice={updateChoice}
                addRelatedEquipment={addRelated}
                className={styles.addEquipment}
                equipment={choice}
                relatedEquipment={related} />}

            </div>
          </form>
          <div>
            <Button primary type='submit' form='add-form' tabIndex='1' disabled={disableButton}>
              Add Activity
            </Button>
          </div>
        </WhitePanel>
      </div>
    </div>
  )
}
