// @flow
import { createSelector } from 'reselect'
import type { AppState, Dictionary, EquipmentStatus, Overview, OrderItemDetails, ItemScanSummary } from '../types'
import { identity, returnSecondArg } from './helpers'

export function selectOrderItemDetails (state: AppState): OrderItemDetails {
  return state.splash.orderItemDetails
}

export function selectEquipmentDetails (state: AppState): EquipmentStatus[] {
  return state.splash.equipmentDetails
}

export function selectSessionOrderItems (state: AppState): Dictionary<Overview> {
  return state.splash.sessionOrderItems
}

export function selectLabs (state: AppState): Dictionary<string> {
  return state.splash.labs
}

export function selectRequiresRefresh (state: AppState, id: string): boolean {
  return state.splash.refresh[id] ?? false
}

export function selectTimeoutIds (state: AppState): Dictionary<?TimeoutID> {
  return state.splash.timeoutIds
}

export function selectTimeoutIdById (timeoutIds: Dictionary<TimeoutID>, id: string): ?TimeoutID {
  return timeoutIds[id]
}

export function selectGetItemScanSummary (state: AppState): ?ItemScanSummary {
  return state.splash.itemScanSummary
}

export const getOrderItemDetails = createSelector(selectOrderItemDetails, identity)
export const getEquipmentDetails = createSelector(selectEquipmentDetails, identity)
export const getSessionOrderItems = createSelector(selectSessionOrderItems, identity)
export const getLabDetails = createSelector(selectLabs, identity)
export const getRequiresRefresh = createSelector(selectRequiresRefresh, identity)
export const getTimeoutIdById = createSelector(selectTimeoutIds, returnSecondArg, selectTimeoutIdById)
export const getItemScanSummaryResult = createSelector(selectGetItemScanSummary, identity)
