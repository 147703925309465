// @flow
import React from 'react'
import styles from './RelatedForm.module.css'

type Props = {|
  name: string
|}

function RelatedRow ({ name }: Props) {
  return (
    <tr className={styles.row}>
      <td className={`${styles.data} ${styles.left}`}>{name}</td>
    </tr>
  )
}

export default RelatedRow
