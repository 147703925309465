// @flow
import type { ScanState, ScanActions } from '../types'
import {
  START_SCAN,
  STOP_SCAN,
  ADD_SCAN_RESULT,
  ADD_SCAN_ERROR,
  RESET_SCAN_FEEDBACK,
  RESET_SESSION_CONFIRMED,
  SESSION_CONFIRMED,
  NOT_SCANNED_INTO_SESSION,
  END_SESSION_SUCCESS,
  END_SESSION_ERROR
} from '../actions/scan'
import { mapScanErrorMessage } from '../helpers/mapScanErrorMessage'
import { SCAN_ERROR_MESSAGES } from '../data/constants'
import { mapScanSuccess } from '../helpers/mapScanSuccess'

const initialState: ScanState = {
  scanning: false,
  scanFeedback: '',
  scanError: false,
  sessionConfirmed: false
}

export function scan (state: ScanState = initialState, action: ScanActions): ScanState {
  switch (action.type) {
    case END_SESSION_SUCCESS: {
      const result = action.payload.data

      return {
        ...state,
        scanFeedback: result.employeeName +
          ' has been scanned out of ' +
          result.sessionName
      }
    }

    case END_SESSION_ERROR: {
      return {
        ...state,
        scanFeedback: mapScanErrorMessage(action),
        scanError: true
      }
    }

    case NOT_SCANNED_INTO_SESSION: {
      return {
        ...state,
        scanFeedback: SCAN_ERROR_MESSAGES.NOT_SCANNED_INTO_SESSION,
        scanError: true
      }
    }

    case SESSION_CONFIRMED: {
      return {
        ...state,
        sessionConfirmed: true
      }
    }

    case RESET_SESSION_CONFIRMED: {
      return {
        ...state,
        sessionConfirmed: false
      }
    }

    case START_SCAN: {
      return {
        ...state,
        scanning: true
      }
    }

    case STOP_SCAN: {
      return {
        ...state,
        scanning: false
      }
    }

    case ADD_SCAN_RESULT: {
      return {
        ...state,
        scanFeedback: mapScanSuccess(action),
        scanError: false
      }
    }

    case ADD_SCAN_ERROR: {
      return {
        ...state,
        scanFeedback: mapScanErrorMessage(action),
        scanError: true
      }
    }

    case RESET_SCAN_FEEDBACK: {
      return {
        ...state,
        scanFeedback: '',
        scanError: false
      }
    }

    default:
      return state
  }
}

export default scan
