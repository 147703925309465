// @flow
import React from 'react'
import styles from './Choose.module.css'
import TextField from '../input/TextField'
import SelectField from '../input/SelectField'
import { checkIsRequired } from '../../helpers/checkIsRequired'
import cx from 'classnames'
import { NO_SELECTION } from '../../data/constants'

type Props = {|
  className?: string,
  search: string,
  title: string,
  update: (value: string) => void,
  pattern: string,
  patternTitle: string,
  names?: string[],
  checkForRequired: boolean,
  endOfWorkFlowSessions: string[],
  id: string
|}

class Choose extends React.Component<Props> {
  render () {
    const { names, title, search, endOfWorkFlowSessions, update, id } = this.props
    const hasValues = names ? names.length > 0 : false

    return (
      <div className={cx(styles.inputGroup, this.props.className)}>
        <header className={styles.inputGroupHeader}>
          <label className={styles.label}>{title}</label>
        </header>

        {hasValues && names &&
        <SelectField id={'choose-' + id} value={search} onChange={(e) => update(e.target.value)}>
          <option value={NO_SELECTION}>Please choose...</option>
          {this.props.checkForRequired
            ? names.map(name => !checkIsRequired(name, endOfWorkFlowSessions) && <option value={name} key={name}>{name}</option>)
            : names.map(name => <option value={name} key={name}>{name}</option>)}
        </SelectField>}

        {!hasValues && <TextField
          id='lab'
          type='text'
          pattern={this.props.pattern}
          title={this.props.patternTitle}
          value={search}
          onChange={(e) => update(e.target.value)}
          className={styles.searchBox}
          autoCorrect='off'
          autoCapitalize='off'
          required />}
      </div>
    )
  }
}

export default Choose
