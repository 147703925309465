// @flow
import type { Action } from '../types'
import { ADD_SESSION_ERROR } from '../actions/session'
import { ADD_EQUIPMENT_ERROR } from '../actions/equipment'
import { ADD_EMPLOYEE_ERROR } from '../actions/employee'

const DEFAULT_SESSION_MESSAGE = 'Session addition failed. Please try again'
const DEFAULT_EQUIPMENT_MESSAGE = 'Equipment addition failed. Please try again'
const ALREADY_EXISTS = 'already exists'
const EMPLOYEE_ALREADY_EXISTS = ['employee with name', ALREADY_EXISTS]

export function mapAddErrorMessage (action: Action): string {
  switch (action.type) {
    case ADD_SESSION_ERROR: {
      return addSessionErrorMessage(action)
    }
    case ADD_EQUIPMENT_ERROR: {
      return addEquipmentErrorMessage(action)
    }
    case ADD_EMPLOYEE_ERROR: {
      return addEmployeeErrorMessage(action)
    }
    default: {
      return 'Addition failed. Please try again'
    }
  }
}

function addSessionErrorMessage (action: Action) {
  if (hasAlreadyExistsErrorMessage(action, ALREADY_EXISTS)) {
    return action.payload.response.message +
      '. Please rename the session and try again'
  }

  return DEFAULT_SESSION_MESSAGE
}

function addEquipmentErrorMessage (action: Action) {
  if (hasAlreadyExistsErrorMessage(action, ALREADY_EXISTS)) {
    return action.payload.response.message +
      '. Please rename the equipment and try again'
  }

  return DEFAULT_EQUIPMENT_MESSAGE
}

function addEmployeeErrorMessage (action: Action) {
  const alreadyExists = EMPLOYEE_ALREADY_EXISTS.every(
    item => hasAlreadyExistsErrorMessage(action, item))

  if (alreadyExists) {
    return action.payload.response.message +
      '. Please use a different name and try again'
  }

  return 'Employee addition failed. Please try again'
}

function hasAlreadyExistsErrorMessage (action: Action, messageToInclude: string): boolean {
  return action.payload &&
    action.payload.response &&
    action.payload.response.hasOwnProperty('message') &&
    action.payload.response.message.includes(messageToInclude)
}
