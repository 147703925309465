// @flow
import { connect } from 'react-redux'
import SplashController from './SplashController'
import type { AppState, DispatchFunc, EquipmentStatus, OrderItemDetails, SplashStateDetails, ItemScanSummary } from '../../../types'
import { getOrderItemUpdate, getEquipmentUpdate, clearRefresh, abortSplashRequests, getItemScanSummary } from '../../../actions/splash'
import { getOrderItemDetails, getEquipmentDetails, getRequiresRefresh, getItemScanSummaryResult } from '../../../selectors/splash'
import { getLabAlias } from '../../../selectors/auth'
import { compose } from 'redux'
import WithLabAlias from '../../../components/hoc/WithLabAlias'
import { getRsaaStatusProps } from '../../../selectors/rsaa'
import { SPLASH_ACTION_IDS } from '../../../data/splash'

type StateProps = {|
  orderItemUpdate: SplashStateDetails,
  equipmentUpdate: SplashStateDetails,
  itemScanSummary: SplashStateDetails,
  scanSummary: ?ItemScanSummary,
  equipment: EquipmentStatus[],
  labAlias: string,
  orderItems: OrderItemDetails
|}

function mapStateToProps (state: AppState): StateProps {
  const equipmentDetailsRsaa = getRsaaStatusProps(state, 'splash', 'equipmentUpdate')
  const orderItemDetailsRsaa = getRsaaStatusProps(state, 'splash', 'orderItemDetails')
  const itemScanSummaryRsaa = getRsaaStatusProps(state, 'splash', 'itemScanSummary')

  return {
    orderItemUpdate: {
      loading: orderItemDetailsRsaa.loading,
      refresh: getRequiresRefresh(state, SPLASH_ACTION_IDS.ORDER_ITEM_DETAILS)
    },
    equipmentUpdate: {
      loading: equipmentDetailsRsaa.loading,
      refresh: getRequiresRefresh(state, SPLASH_ACTION_IDS.EQUIPMENT_DETAILS)
    },
    itemScanSummary: {
      loading: itemScanSummaryRsaa.loading,
      refresh: getRequiresRefresh(state, SPLASH_ACTION_IDS.ITEM_SCAN_SUMMARY)
    },
    scanSummary: getItemScanSummaryResult(state),
    orderItems: getOrderItemDetails(state),
    labAlias: getLabAlias(state),
    equipment: getEquipmentDetails(state)
  }
}

function mapDispatchToProps (dispatch: DispatchFunc) {
  return {
    clearRefresh: () => dispatch(clearRefresh()),
    abortRequests: () => dispatch(abortSplashRequests()),
    getItemScanSummary: (labAlias: string) => dispatch(getItemScanSummary(labAlias)),
    getOrderItemUpdate: (labAlias: string) =>
      dispatch(getOrderItemUpdate(labAlias)),
    getEquipmentUpdate: (labAlias: string) =>
      dispatch(getEquipmentUpdate(labAlias))
  }
}

export default compose(
  WithLabAlias,
  connect(mapStateToProps, mapDispatchToProps)
)(SplashController)
