// @flow
import type { Dictionary } from '../types'

export function flatten<T> (arr: T[][]): T[] {
  return arr.reduce((arrAcc: T[], arr: T[]): T[] => {
    return arrAcc.concat(arr)
  }, [])
}

export function flattenListOfDictionaries (arr: Dictionary<any>[]): string[] {
  return arr.map(name => Object.values(name).toString())
}
