// @flow
import React, { Component } from 'react'
import type { OrderSearchResults } from '../../../../../../types'
import SearchResults from './SearchResults'
import Feedback from '../../../../../../components/layout/Feedback'
import styles from './SearchResults.module.css'

type Props = {|
  results: ?OrderSearchResults
|}

type State = {|
  selectedId: ?number
|}

export default class SearchResultsController extends Component<Props, State> {
  state = {
    selectedId: this.selectDefaultId
  }

  get selectDefaultId (): ?number {
    return this.props.results && this.props.results.labOrders.length === 1
      ? this.props.results.labOrders[0].id
      : null
  }

  selectResult = (id: number) => {
    this.setState({ selectedId: id })
  }

  render () {
    const { results } = this.props

    if (!results || results.labOrders.length === 0) {
      return (
        <div className={styles.feedback}>
          <Feedback
            message={'No matching orders'}
            hasScanError={true} />
        </div>
      )
    }

    return (
      <SearchResults
        results={results}
        selectedId={this.state.selectedId}
        selectResult={this.selectResult} />
    )
  }
}
