// @flow

import * as React from 'react'
import { TABLET_MIN_SCREEN_SIZE } from '../../data/navigation'
import NavigationLayout from './NavigationLayout'
import { history } from '../../configureStore'

type Props = {|
  onSignOut: () => void,
  children: React.Node,
  isAdmin: boolean
|}

type State = {|
  isMobileExpanded: boolean,
  activeLink: string
|}

class Navigation extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      isMobileExpanded: false,
      activeLink: this.getLocation()
    }
  }

  componentDidMount () {
    window.addEventListener('resize', this.checkScreenSize)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.checkScreenSize)
  }

  getLocation= (): string => {
    const location = history.location.pathname.substr(1).split('/')[0]
    const captalise = location.charAt(0).toUpperCase() + location.slice(1)
    return captalise
  }

  toggleMobileExpanded = () => {
    this.setState({ isMobileExpanded: !this.state.isMobileExpanded })
  }

  collapseMobile = () => {
    this.setState({ isMobileExpanded: false })
  }

  onClickSignOut = (e: SyntheticInputEvent<HTMLInputElement>) => {
    e.preventDefault()
    this.collapseMobile()
    this.props.onSignOut()
  }

  checkScreenSize = () => {
    if (window.screen.width >= TABLET_MIN_SCREEN_SIZE && this.state.isMobileExpanded) {
      this.setState({ isMobileExpanded: false }, this.forceUpdate())
    }
  }

  changeNavigationItem = (name: string) => {
    this.setState({ isMobileExpanded: false, activeLink: name })
  }

  render () {
    return (
      <NavigationLayout
        isMobileExpanded={this.state.isMobileExpanded}
        onClickMobileToggle={this.toggleMobileExpanded}
        onClickSignOut={this.props.onSignOut}
        onShouldCollapseMobile={(name: string) => this.changeNavigationItem(name)}
        activeLink= {this.state.activeLink}
        isAdmin={this.props.isAdmin}
      >
        {this.props.children}
      </NavigationLayout>
    )
  }
}

export default Navigation
