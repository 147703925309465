// @flow
import React, { Component } from 'react'
import DetailPanel from '../../../../../../components/layout/DetailPanel'
// import SearchResult from './SearchResult'
import styles from './SearchResults.module.css'
import OrderHistory from '../OrderHistory'
import type { OrderSearchResults } from '../../../../../../types'

type Props = {|
  results: OrderSearchResults,
  selectedId: ?number,
  selectResult: (id: number) => void
|}

export default class SearchResults extends Component<Props> {
  render () {
    const { results, selectedId } = this.props
    const selectedOrder = results.labOrders.find(labOrder => labOrder.id === selectedId)
    const numberOfOrders = results.labOrders.length
    const headerText = numberOfOrders > 1
      ? numberOfOrders + ' Results'
      : numberOfOrders + ' Result'

    return (
      <div>
        {/* <DetailPanel title={headerText}>
          {results.labOrders.map((labOrder, index) => <SearchResult
            key={labOrder.id}
            selectResult={this.props.selectResult}
            labOrder={labOrder} />)}

          {results.hasMore && <p className={styles.moreResults}>
            There are more results which are not shown
          </p>}
        </DetailPanel> */}

        <DetailPanel title={headerText}>

          <table className={styles.table}>
            <thead>
              <tr>
                <th className={styles.header}>Prodigi Lab Order Id</th>
                <th className={styles.header}>Merchant Order Reference</th>
              </tr>
            </thead>

            <tbody>

              {results.labOrders.map((labOrder, index) =>
                <tr className={styles.row} key={labOrder.prodigiLabOrderId} onClick={() => this.props.selectResult(labOrder.id)}>
                  <td className={styles.data}>{labOrder.prodigiLabOrderId}</td>
                  <td className={styles.data}>{labOrder.merchantOrderReference}</td>
                </tr>)}

            </tbody>
          </table>

          {/* {results.labOrders.map((labOrder, index) => <SearchResult
            key={labOrder.id}
            selectResult={this.props.selectResult}
            labOrder={labOrder} />)} */}

          {results.hasMore && <p className={styles.moreResults}>
            There are more results which are not shown
          </p>}
        </DetailPanel>

        {selectedId && selectedOrder && <OrderHistory
          key={selectedId}
          order={selectedOrder} />}
      </div>
    )
  }
}
