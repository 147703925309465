// @flow
import { RSAA_ACTION_NAME } from '../data/rsaa'
import { STATUS_CODES } from '../data/statusCodes'
import { refreshAccessToken, sessionExpired, REFRESH_ACCESS_TOKEN_SUCCESS, REFRESH_ACCESS_TOKEN } from '../actions/auth'
import { AUTH_HEADER_PREFIX } from '../data/constants'
import { isPwintyToken } from '../helpers/jwt'
import type { MiddlewareAPI, Dispatch } from 'redux'
import type { Response, AppState, Action, RequestOptions, DispatchFunc, FetchFunc } from '../types'

export default function (store: MiddlewareAPI<AppState, Action>) {
  return function (next: Dispatch<Action>) {
    return async function (action: Action) {
      const rsaaAction = action[RSAA_ACTION_NAME]

      if (rsaaAction) {
        rsaaAction.fetch = fetchWithRetry(rsaaAction.fetch || window.fetch, store.dispatch, rsaaAction.types)
      }

      return next(action)
    }
  }
}

function fetchWithRetry (fetchFunc: FetchFunc<*>, dispatch: DispatchFunc, actionTypes: string[]) {
  return async (url: string, options: RequestOptions) => {
    const result = await fetchFunc(url, options)

    const shouldRetry = isUnauthorisedRequest(result) && isPwintyRequest(options) && !isRefreshAccessTokenAction(actionTypes)
    if (!shouldRetry) {
      return result
    }

    return retryRequest(fetchFunc, url, options, dispatch)
  }
}

function isRefreshAccessTokenAction (actionTypes: string[]): boolean {
  return actionTypes[0] === REFRESH_ACCESS_TOKEN
}

async function retryRequest (fetchFunc: FetchFunc<*>, url: string, options: RequestOptions, dispatch: DispatchFunc) {
  const refreshedToken = await getNewToken(dispatch)

  if (!refreshedToken) {
    return dispatch(sessionExpired())
  }

  const retryResult = await fetchWithRefreshedToken(fetchFunc, url, options, refreshedToken)

  if (isUnauthorisedRequest(retryResult)) {
    return dispatch(sessionExpired())
  }

  return retryResult
}

function fetchWithRefreshedToken (fetchFunc: FetchFunc<*>, url: string, options: RequestOptions, token: string) {
  const optionsWithUpdatedToken = updateBearerToken(options, token)
  return fetchFunc(url, optionsWithUpdatedToken)
}

function updateBearerToken (options: RequestOptions, token: string): RequestOptions {
  return {
    ...options,
    headers: {
      ...options.headers,
      Authorization: AUTH_HEADER_PREFIX + token
    }
  }
}

async function getNewToken (dispatch: DispatchFunc): Promise<?string> {
  const result = await dispatch(refreshAccessToken())

  if (result.type === REFRESH_ACCESS_TOKEN_SUCCESS) {
    return result.payload.accessToken
  }
}

function isUnauthorisedRequest (result: Response<*>): boolean {
  return result && result.status === STATUS_CODES.UNAUTHORIZED
}

function removeAuthHeaderPrefix (authHeader: string): string {
  return authHeader.replace(AUTH_HEADER_PREFIX, '')
}

function isPwintyRequest (options: RequestOptions): boolean {
  if (options.headers && options.headers.Authorization) {
    const jwt = removeAuthHeaderPrefix(options.headers.Authorization)
    return isPwintyToken(jwt)
  }

  return false
}
