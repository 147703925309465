// @flow
import React, { Component } from 'react'
import { removePrefix } from '../../../../helpers/scan'
import type { Dictionary, EmployeeDetails, BarcodeFeedback } from '../../../../types'
import EmployeeView from './EmployeeView'

type Props = {|
  labs: Dictionary<string>,
  getLabs: () => void,
  addEmployeeToDatabase: (employee: EmployeeDetails) => void,
  removeEmployeeFromDatabase: (employeeBarcode: string) => void,
  resetFeedback: () => void,
  feedback: BarcodeFeedback
|}

type State = {|
  addEmployee: EmployeeDetails,
  removeEmployee: string
|}

class EmployeeController extends Component<Props, State> {
  state = {
    removeEmployee: '',
    addEmployee: {
      name: '',
      labAlias: ''
    }
  }

  componentDidMount () {
    this.props.resetFeedback()
    this.props.getLabs()
  }

  updateRemove = (value: string) => {
    const barcode = removePrefix(value)
    this.setState({ removeEmployee: barcode })
  }

  updateAdd = (name: string, value: any) => {
    this.setState(state => ({ addEmployee: { ...state.addEmployee, [name]: value } }))
  }

  addEmployee = (e: any) => {
    e.preventDefault()
    if (Object.keys(this.state.addEmployee).length !== 0) {
      this.props.addEmployeeToDatabase(this.state.addEmployee)
    }
    this.setState({ addEmployee: { name: '', labAlias: '' } })
  }

  removeEmployee = (e: any) => {
    e.preventDefault()
    if (this.state.removeEmployee !== '') {
      this.props.removeEmployeeFromDatabase(this.state.removeEmployee)
    }
    this.setState({ removeEmployee: '' })
  }

  render () {
    return (
      <EmployeeView
        labs={this.props.labs}
        addEmployeeToDatabase={this.addEmployee}
        removeEmployeeFromDatabase={this.removeEmployee}
        updateAdd={this.updateAdd}
        updateRemove={this.updateRemove}
        addEmployee={this.state.addEmployee}
        removeEmployee={this.state.removeEmployee}
        feedback={this.props.feedback} />
    )
  }
}

export default EmployeeController
