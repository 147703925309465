// @flow

import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './Tabs.module.css'

export type TabItem = {|
  title: string,
  path: string
|}

type Props = {|
  items: TabItem[]
|}

function Tabs (props: Props) {
  return (
    <div className={styles.list}>
      {props.items.map((item) => (
        <NavLink key={item.title} className={styles.item} activeClassName={styles.selected} to={item.path}>
          {item.title}
        </NavLink>
      ))}
    </div>
  )
}

export default Tabs
