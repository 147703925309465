// @flow
import { createLogger } from 'redux-logger'
import { SET_RSAA_STATUS, RESET_RSAA_STATUS } from '../actions/rsaa'

const IGNORED_ACTIONS = [
  SET_RSAA_STATUS,
  RESET_RSAA_STATUS
]

export default createLogger({
  predicate: (getState, action) => !IGNORED_ACTIONS.includes(action.type)
})
