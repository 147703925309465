// @flow
import React, { Component } from 'react'
import { START_SESSION } from '../../../../data/constants'
import { SESSION } from '../../../../data/scan'
import type { SessionDetails, BarcodeFeedback } from '../../../../types'
import SessionView from './SessionView'

type Props = {|
  removeSessionFromDatabase: (sessionName: string) => void,
  addSessionToDatabase: (sessionName: string, relatedActivities: string[], itemsPerHour: number, isEndOfWorkflow: boolean) => void,
  getActivityTypes: (activityType: string) => void,
  activityNames: string[],
  sessionNames: string[],
  fetchDetailsSuccess: boolean,
  getSessionTypes: () => void,
  searchForSession: (sessionSearch: string) => void,
  resetState: () => void,
  sessionDetails: SessionDetails,
  updateSession: (details: SessionDetails) => void,
  resetFeedback: () => void,
  feedback: BarcodeFeedback
|}

type State = {|
  addSession: string,
  removeSession: string,
  activity: string,
  relatedActivities: string[],
  itemsPerHour: number,
  sessionSearch: string,
  sessionDetails: SessionDetails,
  hasUpdated: boolean,
  isEndOfWorkflow: boolean,
  needsReset: boolean
|}

class SessionController extends Component<Props, State> {
  state = {
    addSession: '',
    removeSession: '',
    activity: '',
    relatedActivities: [START_SESSION],
    itemsPerHour: 0,
    sessionSearch: '',
    sessionDetails: this.props.sessionDetails,
    hasUpdated: false,
    isEndOfWorkflow: false,
    needsReset: false
  }

  componentDidMount () {
    this.props.resetState()
    this.props.resetFeedback()
    this.props.getActivityTypes(SESSION)
    this.props.getSessionTypes()
  }

  componentDidUpdate () {
    if (this.props.fetchDetailsSuccess && !this.state.hasUpdated) {
      this.setState({ sessionDetails: this.props.sessionDetails })
      this.setState({ hasUpdated: true })
    }
  }

  checkReset = () => {
    if (this.state.needsReset) {
      this.props.resetFeedback()
      this.setState({ needsReset: false })
    }
  }

  updateAdd = (value: string) => {
    this.checkReset()
    this.setState({ addSession: value })
  }

  updateRemove = (value: string) => {
    this.checkReset()
    this.setState({ removeSession: value })
  }

  updateActivityChoice = (value: string) => {
    this.checkReset()
    this.setState({ activity: value })
  }

  updateItemsPerHour = (value: number) => {
    this.checkReset()
    this.setState({ itemsPerHour: value })
  }

  updateQuery = (value: string) => {
    this.checkReset()
    this.setState({ sessionSearch: value })
  }

  updateSessionDetails = (name: string, value: string | number | boolean) => {
    this.checkReset()

    this.setState(state => ({ sessionDetails: { ...state.sessionDetails, [name]: value } }))
  }

  addSession = (e: any) => {
    e.preventDefault()
    const { relatedActivities, addSession, itemsPerHour, isEndOfWorkflow } = this.state
    if (relatedActivities.length !== 0 && addSession !== '') {
      this.props.addSessionToDatabase(addSession, relatedActivities, itemsPerHour, isEndOfWorkflow)
    }
    this.setState({
      relatedActivities: [START_SESSION],
      addSession: '',
      itemsPerHour: 0,
      needsReset: true,
      isEndOfWorkflow: false })
    this.props.getSessionTypes()
  }

  removeSession = (e: any) => {
    e.preventDefault()
    if (this.state.removeSession !== '') {
      this.props.removeSessionFromDatabase(this.state.removeSession)
    }
    this.setState({ removeSession: '', needsReset: true })
    this.props.getSessionTypes()
  }

  addRelatedActivity = (e: any) => {
    e.preventDefault()
    this.checkReset()
    const { activity, relatedActivities } = this.state
    if (!relatedActivities.includes(activity)) {
      this.setState({ activity: '', relatedActivities: [...relatedActivities, activity] })
    } else {
      this.setState({ activity: '' })
    }
  }

  searchSession = (e: any) => {
    e.preventDefault()
    const { sessionSearch } = this.state

    if (sessionSearch !== '') {
      this.setState({
        hasUpdated: false,
        sessionDetails: { id: -1, sessionName: '', itemsPerHour: -1, isEndOfWorkflow: false },
        needsReset: true })
      this.props.searchForSession(sessionSearch)
    }
  }

  updateDetails = () => {
    this.props.updateSession(this.state.sessionDetails)
    this.props.resetState()
    this.setState({ hasUpdated: false })
    this.props.getSessionTypes()
  }

  updateIsEndOfWorkflow = (e: SyntheticEvent<HTMLInputElement>) => {
    const newState = !this.state.isEndOfWorkflow
    this.setState({ isEndOfWorkflow: newState })
  }

  render () {
    return (
      <SessionView
        removeSessionFromDatabase={this.removeSession}
        addSessionToDatabase={this.addSession}
        activityNames={this.props.activityNames}
        sessionNames={this.props.sessionNames}
        sessionDetails={this.state.sessionDetails}
        updateSessionDetails={this.updateSessionDetails}
        feedback={this.props.feedback}
        updateAdd={this.updateAdd}
        updateActivityChoice={this.updateActivityChoice}
        addRelatedActivity={this.addRelatedActivity}
        updateItemsPerHour={this.updateItemsPerHour}
        updateRemove={this.updateRemove}
        updateQuery={this.updateQuery}
        searchSession={this.searchSession}
        updateDetails={this.updateDetails}
        addSession={this.state.addSession}
        removeSession={this.state.removeSession}
        activity={this.state.activity}
        relatedActivities={this.state.relatedActivities}
        updateIsEndOfWorkflow={this.updateIsEndOfWorkflow}
        isEndOfWorkflow={this.state.isEndOfWorkflow}
        itemsPerHour={this.state.itemsPerHour}
        sessionSearch={this.state.sessionSearch} />
    )
  }
}

export default SessionController
