// @flow
import { connect } from 'react-redux'
import { compose } from 'redux'
import DefaultDashboardController from './DefaultDashboardController'
import type { AppState, DispatchFunc, Dictionary, Overview, SplashStateDetails, ItemScanSummary } from '../../types'
import { getOrdersBySession, clearRefresh, getItemScanSummary } from '../../actions/splash'
import { abortDefaultDashboardRequests } from '../../actions/dashboard'
import { getSessionOrderItems, getRequiresRefresh, getItemScanSummaryResult } from '../../selectors/splash'
import { getLabAlias } from '../../selectors/auth'
import WithLabAlias from '../../components/hoc/WithLabAlias'
import { getRsaaStatusProps } from '../../selectors/rsaa'
import { SPLASH_ACTION_IDS } from '../../data/splash'

type StateProps = {|
  ordersBySession: SplashStateDetails,
  itemScanSummary: SplashStateDetails,
  scanSummary: ?ItemScanSummary,
  sessionOrderItems: Dictionary<Overview>,
  labAlias: string
|}

function mapStateToProps (state: AppState): StateProps {
  const ordersBySessionUpdateRsaa = getRsaaStatusProps(state, 'splash', 'ordersBySession')
  const itemScanSummaryRsaa = getRsaaStatusProps(state, 'splash', 'itemScanSummary')

  return {
    ordersBySession: {
      loading: ordersBySessionUpdateRsaa.loading,
      refresh: getRequiresRefresh(state, SPLASH_ACTION_IDS.SESSION_ORDER_ITEMS)
    },
    itemScanSummary: {
      loading: itemScanSummaryRsaa.loading,
      refresh: getRequiresRefresh(state, SPLASH_ACTION_IDS.ITEM_SCAN_SUMMARY)
    },
    scanSummary: getItemScanSummaryResult(state),
    sessionOrderItems: getSessionOrderItems(state),
    labAlias: getLabAlias(state)
  }
}

function mapDispatchToProps (dispatch: DispatchFunc) {
  return {
    clearRefresh: () => dispatch(clearRefresh()),
    abortRequests: () => dispatch(abortDefaultDashboardRequests()),
    getItemScanSummary: (labAlias: string) => dispatch(getItemScanSummary(labAlias)),
    getOrdersBySession: (labAlias: string) =>
      dispatch(getOrdersBySession(labAlias))
  }
}

export default compose(
  WithLabAlias,
  connect(mapStateToProps, mapDispatchToProps)
)(DefaultDashboardController)
