// @flow
import React, { Component } from 'react'
import { matchPath } from 'react-router-dom'
import PageNotFoundView from '../../components/PageNotFound/PageNotFound'
import { findPaths } from '../../helpers/reactChildren'
import type { ChildrenArray } from 'react'
import type { RouterHistory, Match, Location } from 'react-router'
import type { Path } from '../../helpers/reactChildren'

type Props = {|
  match: Match,
  location: Location,
  history: RouterHistory,
  children: ChildrenArray
|}

type State = {|
  paths: Path[]
|}

export default class PageNotFoundController extends Component<Props, State> {
  state = { paths: [] }

  get hasMatch (): boolean {
    return this.state.paths.some(path => matchPath(window.location.pathname, {path: path.value, exact: path.isExact}))
  }

  get isLocationValid (): boolean {
    return this.hasMatch || window.location.pathname === '/'
  }

  componentDidMount () {
    this.setState({ paths: findPaths(this.props.children) })
  }

  render () {
    if (this.isLocationValid) {
      return this.props.children
    }
    return <PageNotFoundView />
  }
}
