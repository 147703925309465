// @flow
import { connect } from 'react-redux'
import { removeEquipment, addEquipment } from '../../../../actions/equipment'
import { getActivityTypes, getEquipmentTypes } from '../../../../actions/search'
import { getActivityNames, getEquipmentNames } from '../../../../selectors/search'
import { getEquipmentFeedback } from '../../../../selectors/feedback'
import EquipmentController from './EquipmentController'
import { resetFeedback } from '../../../../actions/global'
import { getLabs } from '../../../../actions/splash'
import { getLabDetails } from '../../../../selectors/splash'
import type { Dictionary, AppState, DispatchFunc, BarcodeFeedback } from '../../../../types'

type StateProps = {|
  labs: Dictionary<string>,
  activityNames: string[],
  feedback: BarcodeFeedback,
  equipmentNames: string[]
|}

function mapStateToProps (state: AppState): StateProps {
  return {
    labs: getLabDetails(state),
    activityNames: getActivityNames(state),
    feedback: getEquipmentFeedback(state),
    equipmentNames: getEquipmentNames(state)
  }
}

function mapDispatchToProps (dispatch: DispatchFunc) {
  return {
    removeEquipmentFromDatabase: (equipmentName: string) =>
      dispatch(removeEquipment(equipmentName)),
    addEquipmentToDatabase: (equipmentName: string, relatedActivities: string[], labAlias: string) =>
      dispatch(addEquipment(equipmentName, relatedActivities, labAlias)),
    getActivityTypes: (activityType: string) =>
      dispatch(getActivityTypes(activityType)),
    getEquipmentTypes: () =>
      dispatch(getEquipmentTypes()),
    getLabs: () =>
      dispatch(getLabs()),
    resetFeedback: () =>
      dispatch(resetFeedback())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentController)
