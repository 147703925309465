// @flow
import { RSAA } from 'redux-api-middleware'
import type { GetStateFunc, DispatchFunc, ThunkAsync, Types, EmployeeSearchDetails, AllActivities } from '../types'
import { getBarcodeWithoutPrefix } from '../helpers/scan'
import { resetRsaaStatus } from './rsaa'

export const FETCH_ORDER_SEARCH = 'FETCH_ORDER_SEARCH'
export const FETCH_ORDER_SEARCH_SUCCESS = 'FETCH_ORDER_SEARCH_SUCCESS'
export const FETCH_ORDER_SEARCH_ERROR = 'FETCH_ORDER_SEARCH_ERROR'
export const CLEAR_RECENT_SEARCH = 'CLEAR_RECENT_SEARCH'
export const FETCH_SESSION_TYPES = 'FETCH_SESSION_TYPES'
export const FETCH_SESSION_TYPES_SUCCESS = 'FETCH_SESSION_TYPES_SUCCESS'
export const FETCH_SESSION_TYPES_ERROR = 'FETCH_SESSION_TYPES_ERROR'
export const FETCH_ACTIVITY_TYPES = 'FETCH_ACTIVITY_TYPES'
export const FETCH_ACTIVITY_TYPES_SUCCESS = 'FETCH_ACTIVITY_TYPES_SUCCESS'
export const FETCH_ACTIVITY_TYPES_ERROR = 'FETCH_ACTIVITY_TYPES_ERROR'
export const RESET_ORDER_SEARCH_FEEDBACK = 'RESET_ORDER_SEARCH_FEEDBACK'
export const FETCH_EQUIPMENT_NAMES = 'FETCH_EQUIPMENT_NAMES'
export const FETCH_EQUIPMENT_NAMES_SUCCESS = 'FETCH_EQUIPMENT_NAMES_SUCCESS'
export const FETCH_EQUIPMENT_NAMES_ERROR = 'FETCH_EQUIPMENT_NAMES_ERROR'
export const RESET_EMPLOYEE_SEARCH = 'RESET_EMPLOYEE_SEARCH'
export const FETCH_EMPLOYEE_SEARCH_ERROR = 'FETCH_EMPLOYEE_SEARCH_ERROR'
export const FETCH_EMPLOYEE_SEARCH_SUCCESS = 'FETCH_EMPLOYEE_SEARCH_SUCCESS'
export const FETCH_EMPLOYEE_SEARCH = 'FETCH_EMPLOYEE_SEARCH'
export const FETCH_CURRENT_LOCATION = 'FETCH_CURRENT_LOCATION'
export const FETCH_CURRENT_LOCATION_SUCCESS = 'FETCH_CURRENT_LOCATION_SUCCESS'
export const FETCH_CURRENT_LOCATION_ERROR = 'FETCH_CURRENT_LOCATION_ERROR'
export const FETCH_ACTIVITY_SESSION = 'FETCH_ACTIVITY_SESSION'
export const FETCH_ACTIVITY_SESSION_SUCCESS = 'FETCH_ACTIVITY_SESSION_SUCCESS'
export const FETCH_ACTIVITY_SESSION_ERROR = 'FETCH_ACTIVITY_SESSION_ERROR'
export const FETCH_ALL_ACTIVITIES = 'FETCH_ALL_ACTIVITIES'
export const FETCH_ALL_ACTIVITIES_SUCCESS = 'FETCH_ALL_ACTIVITIES_SUCCESS'
export const FETCH_ALL_ACTIVITIES_ERROR = 'FETCH_ALL_ACTIVITIES_ERROR'
export const FETCH_ORDER_SCAN_CHECK = 'FETCH_ORDER_SCAN_CHECK'
export const FETCH_ORDER_SCAN_CHECK_SUCCESS = 'FETCH_ORDER_SCAN_CHECK_SUCCESS'
export const FETCH_ORDER_SCAN_CHECK_ERROR = 'FETCH_ORDER_SCAN_CHECK_ERROR'
export const RESET_ORDER_SCAN_CHECK = 'RESET_ORDER_SCAN_CHECK'
export const RESET_ACTIVITY_SESSION_CHECK = 'RESET_ACTIVITY_SESSION_CHECK'

export function resetOrderSearchFeedback () {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    dispatch(resetRsaaStatus('orderSearch'))
    dispatch({
      type: RESET_ORDER_SEARCH_FEEDBACK
    })
  }
}

export function resetActivitySessionCheck () {
  return {
    type: RESET_ACTIVITY_SESSION_CHECK
  }
}

export function resetOrderItemScanCheck () {
  return {
    type: RESET_ORDER_SCAN_CHECK
  }
}

export function getScanCheck (barcode: string, scanAllOrderItems: boolean, currentSession: string) {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const endpoint = process.env.REACT_APP_TRACKING_SERVICE || ''
    const orderItemBarcode = getBarcodeWithoutPrefix(barcode)

    try {
      await dispatch({
        [RSAA]: {
          endpoint: `${endpoint}/search/orderScanChecks?barcode=${orderItemBarcode}&scanAllItems=${scanAllOrderItems.toString()}&currentSession=${currentSession}`,
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          types: [ FETCH_ORDER_SCAN_CHECK, FETCH_ORDER_SCAN_CHECK_SUCCESS, FETCH_ORDER_SCAN_CHECK_ERROR ]
        }
      })
    } catch (err) {
      console.log(err)
    }
  }
}

export function getActivitySession (barcode: string) {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const endpoint = process.env.REACT_APP_TRACKING_SERVICE || ''
    const activityBarcode = getBarcodeWithoutPrefix(barcode)

    try {
      await dispatch({
        [RSAA]: {
          endpoint: `${endpoint}/search/activitySession?barcode=${activityBarcode}`,
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          types: [ FETCH_ACTIVITY_SESSION, FETCH_ACTIVITY_SESSION_SUCCESS, FETCH_ACTIVITY_SESSION_ERROR ]
        }
      })
    } catch (err) {
      console.log(err)
    }
  }
}

export function getCurrentLocation (barcode: string) {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const endpoint = process.env.REACT_APP_TRACKING_SERVICE || ''
    const userBarcode = getBarcodeWithoutPrefix(barcode)

    try {
      await dispatch({
        [RSAA]: {
          endpoint: `${endpoint}/search/getCurrentLocation?barcode=${userBarcode}`,
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          types: [ FETCH_CURRENT_LOCATION, FETCH_CURRENT_LOCATION_SUCCESS, FETCH_CURRENT_LOCATION_ERROR ]
        }
      })
    } catch (err) {
      console.log(err)
    }
  }
}

export function searchForOrder (orderQuery: string) {
  return async (dispatch: DispatchFunc) => {
    const endpoint = process.env.REACT_APP_TRACKING_SERVICE || ''

    const url = new URL(`${endpoint}/laborders`)

    url.searchParams.append('search', orderQuery)
    url.searchParams.append('top', '100')

    await dispatch({
      [RSAA]: {
        endpoint: url.toString(),
        method: 'GET',
        types: [ FETCH_ORDER_SEARCH, FETCH_ORDER_SEARCH_SUCCESS, FETCH_ORDER_SEARCH_ERROR ]
      }
    })
  }
}

export function getSessionTypes (): ThunkAsync<?Types> {
  return async (dispatch: DispatchFunc) => {
    const endpoint = process.env.REACT_APP_TRACKING_SERVICE || ''

    await dispatch({
      [RSAA]: {
        endpoint: `${endpoint}/search/sessionTypes`,
        method: 'GET',
        types: [ FETCH_SESSION_TYPES, FETCH_SESSION_TYPES_SUCCESS, FETCH_SESSION_TYPES_ERROR ]
      }
    })
  }
}

export function getEquipmentTypes (): ThunkAsync<?Types> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const endpoint = process.env.REACT_APP_TRACKING_SERVICE || ''

    await dispatch({
      [RSAA]: {
        endpoint: `${endpoint}/search/equipmentTypes`,
        method: 'GET',
        types: [ FETCH_EQUIPMENT_NAMES, FETCH_EQUIPMENT_NAMES_SUCCESS, FETCH_EQUIPMENT_NAMES_ERROR ]
      }
    })
  }
}

export function getActivityTypes (activityType: string): ThunkAsync<?Types> {
  return async (dispatch: DispatchFunc) => {
    const endpoint = process.env.REACT_APP_TRACKING_SERVICE || ''

    await dispatch({
      [RSAA]: {
        endpoint: `${endpoint}/search/activityTypes?activityType=${activityType}`,
        method: 'GET',
        types: [ FETCH_ACTIVITY_TYPES, FETCH_ACTIVITY_TYPES_SUCCESS, FETCH_ACTIVITY_TYPES_ERROR ]
      }
    })
  }
}

export function getAllActivities (): ThunkAsync<?AllActivities> {
  return async (dispatch: DispatchFunc) => {
    const endpoint = process.env.REACT_APP_TRACKING_SERVICE || ''

    await dispatch({
      [RSAA]: {
        endpoint: `${endpoint}/search/allActivities`,
        method: 'GET',
        types: [ FETCH_ALL_ACTIVITIES, FETCH_ALL_ACTIVITIES_SUCCESS, FETCH_ALL_ACTIVITIES_ERROR ]
      }
    })
  }
}

export function resetState () {
  return {
    type: CLEAR_RECENT_SEARCH
  }
}

export function resetEmployeeSearchFeedback () {
  return {
    type: RESET_EMPLOYEE_SEARCH
  }
}

export function searchForEmployee (name: string): ThunkAsync<?EmployeeSearchDetails> {
  return async (dispatch: DispatchFunc) => {
    const endpoint = process.env.REACT_APP_TRACKING_SERVICE || ''

    await dispatch({
      [RSAA]: {
        endpoint: `${endpoint}/search/employeeDetails?name=${name}`,
        method: 'GET',
        types: [ FETCH_EMPLOYEE_SEARCH, FETCH_EMPLOYEE_SEARCH_SUCCESS, FETCH_EMPLOYEE_SEARCH_ERROR ]
      }
    })
  }
}
