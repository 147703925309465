// @flow
import type { SearchState, SearchActions, FetchOrderSearchSuccess } from '../types'
import {
  FETCH_ORDER_SEARCH_SUCCESS,
  CLEAR_RECENT_SEARCH,
  FETCH_SESSION_TYPES_SUCCESS,
  FETCH_EQUIPMENT_NAMES_SUCCESS,
  FETCH_ACTIVITY_TYPES_SUCCESS,
  FETCH_ALL_ACTIVITIES_SUCCESS,
  FETCH_CURRENT_LOCATION_SUCCESS,
  FETCH_CURRENT_LOCATION_ERROR,
  FETCH_ACTIVITY_SESSION_SUCCESS,
  FETCH_ACTIVITY_SESSION_ERROR,
  FETCH_EMPLOYEE_SEARCH_SUCCESS,
  FETCH_ORDER_SCAN_CHECK_SUCCESS,
  FETCH_ORDER_SCAN_CHECK_ERROR,
  RESET_ORDER_SCAN_CHECK,
  RESET_ACTIVITY_SESSION_CHECK
} from '../actions/search'
import { FETCH_SESSION_SUCCESS, MAX_SESSION_TIME_ELAPSED } from '../actions/session'
import { flattenListOfDictionaries } from '../helpers/array'

const blankSessionDetails = {
  id: -1,
  sessionName: '',
  itemsPerHour: -1,
  isEndOfWorkflow: false
}

const blankEmployeeDetails = {
  name: '',
  lab: '',
  barcode: ''
}

const initialState: SearchState = {
  orderSearch: null,
  sessionTypes: [],
  activityTypes: [],
  equipmentTypes: [],
  allActivities: {
    equipment: {},
    session: {}
  },
  sessionDetails: blankSessionDetails,
  employeeDetails: blankEmployeeDetails,
  currentUser: '',
  currentSession: {
    session: '',
    timeStamp: '',
    scanTally: 0
  },
  currentActivity: {
    activity: '',
    timeStamp: ''
  },
  sessionActivity: {
    session: '',
    activity: '',
    isBulkScan: false
  },
  orderItemScanCheck: {
    duplicateScanIds: [],
    reprintScanIds: [],
    possibleReprintScanIds: [],
    isCancelled: false
  },
  endOfWorkFlowSessions: []
}

export function search (state: SearchState = initialState, action: SearchActions): SearchState {
  switch (action.type) {
    case FETCH_ALL_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        allActivities: action.payload
      }
    }

    case RESET_ACTIVITY_SESSION_CHECK: {
      return {
        ...state,
        sessionActivity: {
          session: '',
          activity: '',
          isBulkScan: false
        }
      }
    }

    case RESET_ORDER_SCAN_CHECK: {
      return {
        ...state,
        orderItemScanCheck: {
          duplicateScanIds: [],
          reprintScanIds: [],
          possibleReprintScanIds: [],
          isCancelled: false
        }
      }
    }

    case FETCH_ORDER_SCAN_CHECK_SUCCESS: {
      return {
        ...state,
        orderItemScanCheck: action.payload
      }
    }

    case FETCH_ORDER_SCAN_CHECK_ERROR: {
      return {
        ...state,
        orderItemScanCheck: {
          duplicateScanIds: [],
          reprintScanIds: [],
          possibleReprintScanIds: [],
          isCancelled: false
        }
      }
    }

    case FETCH_EMPLOYEE_SEARCH_SUCCESS: {
      return {
        ...state,
        employeeDetails: action.payload
      }
    }

    case FETCH_ACTIVITY_SESSION_SUCCESS: {
      return {
        ...state,
        sessionActivity: action.payload
      }
    }

    case FETCH_ACTIVITY_SESSION_ERROR: {
      return {
        ...state,
        sessionActivity: {
          session: '',
          activity: '',
          isBulkScan: false
        }
      }
    }

    case FETCH_CURRENT_LOCATION_SUCCESS: {
      const activity = action.payload.activity === ' - '
        ? ''
        : action.payload.activity

      return {
        ...state,
        currentUser: action.payload.user,
        currentSession: {
          ...state.currentSession,
          session: action.payload.session,
          timeStamp: action.payload.sessionTimeStamp,
          scanTally: action.payload.scanTally
        },
        currentActivity: {
          ...state.currentActivity,
          activity: activity,
          timeStamp: action.payload.activityTimeStamp
        }
      }
    }

    case FETCH_CURRENT_LOCATION_ERROR: {
      return {
        ...state,
        currentUser: '',
        currentSession: {
          ...state.currentSession,
          session: '',
          timeStamp: '',
          scanTally: 0
        },
        currentActivity: {
          ...state.currentActivity,
          activity: '',
          timeStamp: ''
        }
      }
    }

    case MAX_SESSION_TIME_ELAPSED: {
      return {
        ...state,
        currentSession: {
          ...state.currentSession,
          session: '',
          timeStamp: '',
          scanTally: 0
        }
      }
    }

    case FETCH_ORDER_SEARCH_SUCCESS: {
      const currentAction : FetchOrderSearchSuccess = action

      return {
        ...state,
        orderSearch: currentAction.payload.data
      }
    }

    case CLEAR_RECENT_SEARCH: {
      return {
        ...state,
        orderSearch: null,
        sessionDetails: blankSessionDetails,
        employeeDetails: blankEmployeeDetails
      }
    }

    case FETCH_SESSION_TYPES_SUCCESS: {
      const { endOfWorkFlow, processingStages } = action.payload
      return {
        ...state,
        sessionTypes: [ ...processingStages, ...endOfWorkFlow ],
        endOfWorkFlowSessions: endOfWorkFlow
      }
    }

    case FETCH_EQUIPMENT_NAMES_SUCCESS: {
      const formatted = flattenListOfDictionaries(action.payload)
      return {
        ...state,
        equipmentTypes: formatted
      }
    }

    case FETCH_ACTIVITY_TYPES_SUCCESS: {
      const formatted = flattenListOfDictionaries(action.payload)
      return {
        ...state,
        activityTypes: formatted
      }
    }

    case FETCH_SESSION_SUCCESS: {
      return {
        ...state,
        sessionDetails: action.payload
      }
    }

    default:
      return state
  }
}

export default search
