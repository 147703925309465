// @flow

import { SCAN_TYPES } from '../data/scan'
import type {
  Action,
  OrderScanResult,
  ItemScanResult,
  SessionScanResult,
  EquipmentScanResult,
  BulkScanResult,
  ActivityScanResult
} from '../types'

export function mapScanSuccess (action: Action): string {
  if (action.payload) {
    switch (action.scanType) {
      case SCAN_TYPES.WORKSHEET: {
        const currentResult: OrderScanResult = action.payload
        const { orderItemIds, orderId, sessionName } = currentResult
        const orderIdsAsString = orderItemIds.join(', ')

        const numItems = orderItemIds.length

        return formatOrderScanMessage(orderIdsAsString, orderId, sessionName, numItems)
      }

      case SCAN_TYPES.ORDER_ITEM: {
        const currentResult: ItemScanResult = action.payload
        const { orderItemId, orderId, sessionName } = currentResult

        return formatOrderScanMessage(orderItemId.toString(), orderId, sessionName, 1)
      }

      case SCAN_TYPES.SESSION: {
        const currentResult: SessionScanResult = action.payload
        const { employeeName, sessionName } = currentResult

        return employeeName +
          ' has been scanned into ' + sessionName
      }

      case SCAN_TYPES.ACTIVITY: {
        const currentResult: ActivityScanResult = action.payload
        const { employeeName, sessionName, newSession, activityName } = currentResult

        return formatActivityMessage(employeeName, sessionName, newSession, activityName)
      }

      case SCAN_TYPES.EQUIPMENT: {
        const currentResult: EquipmentScanResult = action.payload
        const { equipmentName, activityName } = currentResult

        return 'Equipment: ' + equipmentName +
          ' has been scanned as status ' + activityName
      }

      case SCAN_TYPES.BULK : {
        const currentResult: BulkScanResult = action.payload
        const { employeeName, sessionName, newSession, activityName, total } = currentResult

        const bulkScan = 'A scan with ' + total +
          ' completed ' + formatForPlurals(total, 'item', 'items') +
          ' has been added.'

        return formatActivityMessage(employeeName, sessionName, newSession, activityName) +
          '. ' + bulkScan
      }

      default:
        return 'Scan added'
    }
  }

  return 'Scan added'
}

function formatOrderScanMessage (ids: string, orderId: number, session: string, numItems: number): string {
  return 'Order ' +
    formatForPlurals(numItems, 'item', 'items') +
    ' ' + ids + ' in order ' + orderId + ' ' +
    formatForPlurals(numItems, 'has', 'have') +
    ' been scanned into ' + session
}

function formatActivityMessage (employee: string, session: string, newSession: string, activity: string) {
  const sessionMessage = newSession !== ''
    ? 'session ' + newSession + ' and activity '
    : ''

  return employee +
    ' has been scanned into ' + sessionMessage +
    session + ' - ' + activity
}

function formatForPlurals (valueToCheck: number, singular: string, plural: string) {
  return valueToCheck > 1
    ? plural
    : singular
}
