// @flow

import type { AppState } from '../../types'
import { connect } from 'react-redux'
import Choose from './Choose'
import { getEndOfWorkFlowSessions } from '../../selectors/search'

function mapStateToProps (state: AppState) {
  return {
    endOfWorkFlowSessions: getEndOfWorkFlowSessions(state)
  }
}

export default connect(mapStateToProps, null)(Choose)
