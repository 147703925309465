// @flow
import { connect } from 'react-redux'
import type { Dictionary, EmployeeDetails, DispatchFunc, AppState, BarcodeFeedback } from '../../../../types'
import { removeEmployee, addEmployee } from '../../../../actions/employee'
import { getEmployeeFeedback } from '../../../../selectors/feedback'
import { getLabs } from '../../../../actions/splash'
import { getLabDetails } from '../../../../selectors/splash'
import EmployeeController from './EmployeeController'
import { resetFeedback } from '../../../../actions/global'

type StateProps = {|
  feedback: BarcodeFeedback,
  labs: Dictionary<string>
|}

function mapStateToProps (state: AppState): StateProps {
  return {
    feedback: getEmployeeFeedback(state),
    labs: getLabDetails(state)
  }
}

function mapDispatchToProps (dispatch: DispatchFunc) {
  return {
    removeEmployeeFromDatabase: (employeeBarcode: string) =>
      dispatch(removeEmployee(employeeBarcode)),
    addEmployeeToDatabase: (employee: EmployeeDetails) =>
      dispatch(addEmployee(employee)),
    getLabs: () =>
      dispatch(getLabs()),
    resetFeedback: () =>
      dispatch(resetFeedback())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeController)
