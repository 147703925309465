// @flow
import React, { Component } from 'react'
import type { BarcodeFeedback, AllActivities } from '../../../../types'
import styles from '../Manage.module.css'
import AddActivity from './components/AddActivity'
import Feedback from '../../../../components/layout/Feedback'

type Props = {|
  removeActivityFromDatabase: (activityName: string, activityType: string, typeName: string) => void,
  addActivityToDatabase: (activityName: string, related: string[], activityType: string, isBulkScan: boolean) => void,
  sessionNames: string[],
  equipmentNames: string[],
  feedback: BarcodeFeedback,
  allActivities: AllActivities,
  resetFeedback: () => void
|}

class ActivityView extends Component<Props> {
  render () {
    const { feedback, sessionNames, equipmentNames } = this.props
    return (
      <div>
        {feedback && feedback.message !== '' &&
        <div className={styles.content}>
          <Feedback message={feedback.message} hasScanError={feedback.hasError} barcodes={feedback.barcodes}/>
        </div>}

        {(sessionNames.length > 0 || equipmentNames.length > 0) && <AddActivity
          addActivityToDatabase={this.props.addActivityToDatabase}
          resetFeedback={this.props.resetFeedback}
          sessionNames={sessionNames}
          equipmentNames={equipmentNames} />}

        {/* <RemoveActivity
          removeActivityFromDatabase={this.props.removeActivityFromDatabase}
          resetFeedback={this.props.resetFeedback}
          allActivities={this.props.allActivities} /> */}
      </div>
    )
  }
}

export default ActivityView
