// @flow

import type { Node, ChildrenArray } from 'react'
import React from 'react'
import { SplitFactory } from '@splitsoftware/splitio-react'

import { getSplitIOConfig } from '../../split-io/config'

type Props = {|
  children: $ReadOnlyArray<ChildrenArray<*>>;
|}

export default function SplitWrapper ({ children }: Props): Node {
  return <SplitFactory config={getSplitIOConfig()}>{children}</SplitFactory>
}
