// @flow
import { RSAA } from 'redux-api-middleware'
import type {
  DispatchFunc,
  Dictionary,
  ThunkAsync,
  EquipmentStatus,
  OrderItemDetails,
  Overview
} from '../../types'
import { VISUAL_REFRESH_TIME } from '../../data/constants'
import { setRefreshRequired, addTimeoutById } from '.'
import { ABORT_IN_FLIGHT_REQUESTS } from '../../data/rsaa'
import { SPLASH_ACTION_IDS } from '../../data/splash'

export const FETCH_ORDER_ITEM_DETAILS = 'FETCH_ORDER_ITEM_DETAILS'
export const FETCH_ORDER_ITEM_DETAILS_SUCCESS = 'FETCH_ORDER_ITEM_DETAILS_SUCCESS'
export const FETCH_ORDER_ITEM_DETAILS_ERROR = 'FETCH_ORDER_ITEM_DETAILS_ERROR'
export const FETCH_EQUIPMENT_UPDATE = 'FETCH_EQUIPMENT_UPDATE'
export const FETCH_EQUIPMENT_UPDATE_SUCCESS = 'FETCH_EQUIPMENT_UPDATE_SUCCESS'
export const FETCH_EQUIPMENT_UPDATE_ERROR = 'FETCH_EQUIPMENT_UPDATE_ERROR'
export const FETCH_SESSION_ORDER_ITEMS = 'FETCH_SESSION_ORDER_ITEMS'
export const FETCH_SESSION_ORDER_ITEMS_SUCCESS = 'FETCH_SESSION_ORDER_ITEMS_SUCCESS'
export const FETCH_SESSION_ORDER_ITEMS_ERROR = 'FETCH_SESSION_ORDER_ITEMS_ERROR'
export const FETCH_LABS = 'FETCH_LABS'
export const FETCH_LABS_SUCCESS = 'FETCH_LABS_SUCCESS'
export const FETCH_LABS_ERROR = 'FETCH_LABS_ERROR'
export const FETCH_ITEM_SCAN_SUMMARY = 'FETCH_ITEM_SCAN_SUMMARY'
export const FETCH_ITEM_SCAN_SUMMARY_SUCCESS = 'FETCH_ITEM_SCAN_SUMMARY_SUCCESS'
export const FETCH_ITEM_SCAN_SUMMARY_ERROR = 'FETCH_ITEM_SCAN_SUMMARY_ERROR'

export function getOrderItemUpdate (labAlias: string): ThunkAsync<?OrderItemDetails> {
  return async (dispatch: DispatchFunc) => {
    const endpoint = process.env.REACT_APP_TRACKING_SERVICE || ''

    await dispatch({
      [RSAA]: {
        endpoint: `${endpoint}/overview/orderItemDetails?labAlias=${labAlias}`,
        method: 'GET',
        types: [
          { type: FETCH_ORDER_ITEM_DETAILS, meta: { enhancements: [ ABORT_IN_FLIGHT_REQUESTS ] } },
          FETCH_ORDER_ITEM_DETAILS_SUCCESS,
          FETCH_ORDER_ITEM_DETAILS_ERROR
        ]
      }
    })

    dispatch(createTimeout(SPLASH_ACTION_IDS.ORDER_ITEM_DETAILS))
  }
}

export function getItemScanSummary (labAlias: string) {
  return async (dispatch: DispatchFunc) => {
    const endpoint = process.env.REACT_APP_TRACKING_SERVICE || ''

    await dispatch({
      [RSAA]: {
        endpoint: `${endpoint}/items/summary?labAlias=${labAlias}`,
        method: 'GET',
        types: [
          { type: FETCH_ITEM_SCAN_SUMMARY, meta: { enhancements: [ ABORT_IN_FLIGHT_REQUESTS ] } },
          FETCH_ITEM_SCAN_SUMMARY_SUCCESS,
          FETCH_ITEM_SCAN_SUMMARY_ERROR
        ]}
    })

    dispatch(createTimeout(SPLASH_ACTION_IDS.ITEM_SCAN_SUMMARY))
  }
}

export function getEquipmentUpdate (labAlias: string): ThunkAsync<?EquipmentStatus[]> {
  return async (dispatch: DispatchFunc) => {
    const endpoint = process.env.REACT_APP_TRACKING_SERVICE || ''

    await dispatch({
      [RSAA]: {
        endpoint: `${endpoint}/overview/equipmentUpdate?labAlias=${labAlias}`,
        method: 'GET',
        types: [
          { type: FETCH_EQUIPMENT_UPDATE, meta: { enhancements: [ ABORT_IN_FLIGHT_REQUESTS ] } },
          FETCH_EQUIPMENT_UPDATE_SUCCESS,
          FETCH_EQUIPMENT_UPDATE_ERROR
        ]
      }
    })

    dispatch(createTimeout(SPLASH_ACTION_IDS.EQUIPMENT_DETAILS))
  }
}

export function getOrdersBySession (labAlias: string): ThunkAsync<?Dictionary<Overview>> {
  return async (dispatch: DispatchFunc) => {
    const endpoint = process.env.REACT_APP_TRACKING_SERVICE || ''

    await dispatch({
      [RSAA]: {
        endpoint: `${endpoint}/overview/sessionOverview?labAlias=${labAlias}`,
        method: 'GET',
        types: [
          { type: FETCH_SESSION_ORDER_ITEMS, meta: { enhancements: [ ABORT_IN_FLIGHT_REQUESTS ] } },
          FETCH_SESSION_ORDER_ITEMS_SUCCESS,
          FETCH_SESSION_ORDER_ITEMS_ERROR
        ]
      }
    })

    dispatch(createTimeout(SPLASH_ACTION_IDS.SESSION_ORDER_ITEMS))
  }
}

export function getLabs (): ThunkAsync<?Dictionary<string>> {
  return async (dispatch: DispatchFunc) => {
    const endpoint = process.env.REACT_APP_TRACKING_SERVICE || ''

    await dispatch({
      [RSAA]: {
        endpoint: `${endpoint}/search/labs`,
        method: 'GET',
        types: [ FETCH_LABS, FETCH_LABS_SUCCESS, FETCH_LABS_ERROR ]
      }
    })
  }
}

function createTimeout (id: string) {
  return async (dispatch: DispatchFunc) => {
    const timeoutId = setTimeout(() => {
      dispatch(setRefreshRequired(id))
    }, VISUAL_REFRESH_TIME)

    dispatch(addTimeoutById(id, timeoutId))
  }
}
