// @flow
import type { Action, AppState } from '../../types'
import { LOADING, ERROR, SUCCESS } from '../../data/rsaa'
import {
  ADD_SCAN_ERROR,
  ADD_SCAN_START,
  ADD_SCAN_SUCCESS,
  END_SESSION_ERROR,
  END_SESSION_START,
  END_SESSION_SUCCESS
} from '../../actions/scan'

export function scan (action: Action, state: AppState): ?string[] {
  switch (action.type) {
    case END_SESSION_START: {
      return ['endUserSession', LOADING]
    }

    case END_SESSION_SUCCESS: {
      return ['endUserSession', SUCCESS]
    }

    case END_SESSION_ERROR: {
      return ['endUserSession', ERROR]
    }

    case ADD_SCAN_START: {
      return ['addNewScan', LOADING]
    }

    case ADD_SCAN_SUCCESS: {
      return ['addNewScan', SUCCESS]
    }

    case ADD_SCAN_ERROR: {
      return ['addNewScan', ERROR]
    }

    default: {
      return null
    }
  }
}
