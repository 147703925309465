// @flow
import { connect } from 'react-redux'
import type { DispatchFunc, AppState, BarcodeFeedback, AllActivities } from '../../../../types'
import { removeActivity, addActivity } from '../../../../actions/activity'
import { getSessionTypes, getEquipmentTypes, getAllActivities } from '../../../../actions/search'
import { getSessionNames, getEquipmentNames, getSessionEquipmentActivities } from '../../../../selectors/search'
import { getActivityFeedback } from '../../../../selectors/feedback'
import ActivityController from './ActivityController'
import { resetFeedback } from '../../../../actions/global'

type StateProps = {|
  sessionNames: string[],
  equipmentNames: string[],
  feedback: BarcodeFeedback,
  allActivities: AllActivities
|}

function mapStateToProps (state: AppState): StateProps {
  return {
    sessionNames: getSessionNames(state),
    feedback: getActivityFeedback(state),
    equipmentNames: getEquipmentNames(state),
    allActivities: getSessionEquipmentActivities(state)
  }
}

function mapDispatchToProps (dispatch: DispatchFunc) {
  return {
    removeActivityFromDatabase: (activityName: string, activityType: string, typeName: string) =>
      dispatch(removeActivity(activityName, activityType, typeName)),
    addActivityToDatabase: (activityName: string, related: string[], activityType: string, isBulkScan: boolean) =>
      dispatch(addActivity(activityName, related, activityType, isBulkScan)),
    getSessionTypes: () => dispatch(getSessionTypes()),
    getEquipmentTypes: () => dispatch(getEquipmentTypes()),
    getAllActivities: () => dispatch(getAllActivities()),
    resetFeedback: () => dispatch(resetFeedback())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityController)
