// @flow
import React from 'react'
import Button from '../../../../../components/button'
import WhitePanel from '../../../../../components/layout/WhitePanel'
import styles from '../../Manage.module.css'
import TextField from '../../../../../components/input/TextField'
import RelatedActivityForm from '../../RelatedForm/RelatedActivityForm'

type Props = {|
  session: string,
  addSession: (sessionName: string, relatedActivities: string[], itemsPerHour: number, isEndOfWorkflow: boolean) => void,
  activityNames: string[],
  updateActivityChoice: (value: string) => void,
  updateAdd: (value: string) => void,
  addRelatedActivity: (e: Event) => void,
  activity: string,
  relatedActivities: string[],
  updateItemsPerHour: (value: number) => void,
  itemsPerHour: number,
  updateIsEndOfWorkflow: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  isEndOfWorkflow: boolean
|}

export default function AddSession (props: Props) {
  const disableButton = props.session === '' || props.relatedActivities.length === 0

  return (
    <div className={styles.addPadding}>
      <WhitePanel heading='Add Session'>
        <form id='add-form' onSubmit={props.addSession}>
          <div className={styles.content}>
            <div className={styles.inputGroup}>
              <header className={styles.inputGroupHeader}>
                <label>Session Name</label>
              </header>
              <TextField
                id='sessionName'
                type='text'
                pattern='^[/A-Za-z ]+$'
                title='A-Z, / and a-z only'
                value={props.session}
                onChange={(e) => props.updateAdd(e.target.value)}
                className={styles.searchBox}
                autoCorrect='off'
                autoCapitalize='off'
                required
              />
            </div>
            <div className={`${styles.inputGroup} ${styles.addSmallPadding} `}>
              <header className={styles.inputGroupHeader}>
                <label>Items per hour (AHT)</label>
              </header>
              <TextField
                id='itemsPerHour'
                type='number'
                pattern='^\\d+$'
                title='0-9 only'
                value={props.itemsPerHour}
                onChange={(e) => props.updateItemsPerHour(parseInt(e.target.value))}
                className={styles.searchBox}
                autoCorrect='off'
                autoCapitalize='off'
                min={0}
                required
              />
            </div>
            <div className={`${styles.checkboxGroup} ${styles.addSmallPadding} `}>
              <header className={styles.inputGroupHeader}>
                <label>End of workflow (completed session)</label>
              </header>
              <input
                id='completed'
                type='checkbox'
                className={styles.completed}
                value={props.isEndOfWorkflow}
                onChange={props.updateIsEndOfWorkflow}
                checked={props.isEndOfWorkflow} />
            </div>
            <RelatedActivityForm
              activityNames={props.activityNames}
              updateActivityChoice={props.updateActivityChoice}
              addRelatedActivity={props.addRelatedActivity}
              activity={props.activity}
              relatedActivities={props.relatedActivities} />
          </div>
        </form>
        <div>
          <Button primary type='submit' form='add-form' tabIndex='1' disabled={disableButton}>
            Add Session
          </Button>
        </div>
      </WhitePanel>
    </div>
  )
}
