// @flow
import React from 'react'
import WhitePanel from '../../components/layout/WhitePanel'
import FontAwesome from 'react-fontawesome'
import styles from './defaultDashboard.module.css'
import type { Dictionary, Overview } from '../../types'
import { getSessionIcon } from '../../helpers/scan'
import StatusIcon from '../../components/StatusIcon'

type Props = {|
  sessions: Dictionary<Overview>
|}

export default function SessionOverview ({ sessions }: Props) {
  return (
    <div>
      {Object.keys(sessions).length > 0 &&
      <div>
        <p className={styles.heading}>{'Order Items Completed In The Last Hour'}</p>
        <div className={styles.panelContainer}>
          {Object.keys(sessions).map(row =>
            <div key={row} className={`${styles.sessions}`}>
              <WhitePanel>
                <p>{row}</p>
                <div className={styles.middle}>
                  <FontAwesome name={getSessionIcon(row)} className={styles.cloud} />
                </div>
                <div>
                  <p>Completed: {sessions[row].completed}</p>
                  <p>
                    Efficiency: <StatusIcon efficiency={sessions[row].efficiency} />
                  </p>
                </div>
              </WhitePanel>
            </div>
          )}
        </div>
      </div>}
    </div>
  )
}
