// @flow
import React from 'react'
import WhitePanel from '../../../../../components/layout/WhitePanel'
import FontAwesome from 'react-fontawesome'
import styles from './itemScanSummary.module.css'
import type { ItemScanSummary as Summary } from '../../../../../types'
import StatusIcon from '../../../../../components/StatusIcon'

type Props = {|
  itemScanSummary: ?Summary,
|}

export default function ItemScanSummary ({ itemScanSummary }: Props) {
  if (!itemScanSummary) {
    return <div />
  }

  return (
    <div className={styles.container}>
      <p className={styles.heading}>Item Scan Summary for {itemScanSummary.date}</p>
      <WhitePanel>
        <div className={styles.middle}>
          <FontAwesome name={'sun-o'} className={styles.scanSummaryIcon} />
        </div>
        <div>
          <p className={styles.content}>Completed Items: {itemScanSummary.completed}</p>
          <p className={styles.content}>In Progress Items: {itemScanSummary.inProgress}</p>
          <p className={styles.efficiency}>Efficiency: <StatusIcon efficiency={itemScanSummary.efficiency} /></p>
        </div>
      </WhitePanel>
    </div>
  )
}
