// @flow

export function selectJwtIntervalInMinutes (): number {
  const intervalAsString = readRequiredString('REACT_APP_JWT_INTERVAL_IN_MINUTES')
  return parseInt(intervalAsString, 10)
}

export function selectAuthServiceUrl (): string {
  return readRequiredString('REACT_APP_AUTH_SERVICE')
}

export function selectPwintyApiSettings () {
  return {
    endpoint: readRequiredString('REACT_APP_ENDPOINT'),
    documentationUri: readRequiredString('REACT_APP_PWINTY_API_DOCUMENTATION_URI')
  }
}

export function selectEnvironmentName (): string {
  return readRequiredString('REACT_APP_ENV')
}

export function selectJwtIssuer (): string {
  return readRequiredString('REACT_APP_JWT_ISSUER')
}

function readOptionalString (settingName: string): ?string {
  return process.env[settingName]
}

function readRequiredString (settingName: string): string {
  const value = readOptionalString(settingName)

  if (!value) {
    throw new Error(`Setting ${settingName} has not been provided`)
  }

  return value
}
