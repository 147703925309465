// @flow
import React from 'react'
import styles from './Reprint.module.css'
import cx from 'classnames'

type Props = {|
  reprintIds: string[],
  className: string
|}

export default function ReprintMessage (props: Props) {
  const { reprintIds, className } = props

  const reprintIdsAsString = reprintIds.join(', ')

  const message = reprintIds.length === 1
    ? `Order item ${reprintIdsAsString} is a reprint`
    : `Order items ${reprintIdsAsString} are reprints`

  return (
    <div
      className={cx(styles.reprintContainer, className)}
      type={'success'}>
      <div>
        <p>{message}</p>
      </div>
    </div>
  )
}
