// @flow
import { saveJwtTokens } from './localStorage'
import { startJwtTimerIfNeeded } from './startJwtTimerIfNeeded'
import { STATUS_CODES } from '../../data/statusCodes'
import { getJwt, JWT_SUCCESS } from './getJwt'
import { getUserLab } from './getUserLab'
import type { DispatchFunc, GetStateFunc, ThunkAsync, AuthTokens, LogIn, LogInSuccess, LogInError } from '../../types'
import { getUserDetailsFromJwt } from '../../actions/auth'

export const LOG_IN = 'LOG_IN'
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS'
export const LOG_IN_ERROR = 'LOG_IN_ERROR'

const INVALID_CREDENTIALS_ERROR_MESSAGE = 'The user name or password is incorrect.'
const DEFAULT_ERROR_MESSAGE = 'Error communicating with server - please try again.'

export function logIn (username: string, password: string): ThunkAsync<void> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    dispatch(startLogIn())

    const jwtResult = await dispatch(getJwt(username, password))

    if (jwtResult.type === JWT_SUCCESS) {
      onSuccess(dispatch, jwtResult.payload)
    } else {
      dispatch(logInError(jwtResult.payload.status))
    }
  }
}

function onSuccess (dispatch: DispatchFunc, tokens: AuthTokens) {
  saveJwtTokens(tokens)
  dispatch(startJwtTimerIfNeeded(tokens.accessToken))
  dispatch(getUserDetailsFromJwt(tokens.accessToken))
  dispatch(getUserLab())
  dispatch(logInSuccess())
}

function startLogIn (): LogIn {
  return {
    type: LOG_IN
  }
}

function logInSuccess (): LogInSuccess {
  return {
    type: LOG_IN_SUCCESS
  }
}

function logInError (statusCode: number): LogInError {
  return {
    type: LOG_IN_ERROR,
    message: getLogInErrorMessage(statusCode),
    statusCode
  }
}

function getLogInErrorMessage (statusCode: number): string {
  if (statusCode === STATUS_CODES.UNAUTHORIZED) {
    return INVALID_CREDENTIALS_ERROR_MESSAGE
  }
  return DEFAULT_ERROR_MESSAGE
}
