// @flow
import React, { Component } from 'react'
import styles from './Splash.module.css'
import Page from '../../../components/layout/Page'
import EquipmentStatus from './components/equipmentStatus'
import ItemScanSummary from './components/ItemScanSummary'
import type { EquipmentStatus as Status, OrderItemDetails, ItemScanSummary as ScanSummary } from '../../../types'

type Props = {|
  equipment: Status[],
  scanSummary: ?ScanSummary,
  orderItems: OrderItemDetails
|}

class SplashView extends Component<Props> {
  render () {
    const { orderItems, equipment, scanSummary } = this.props

    return (
      <Page title='Splash Screen'>
        {scanSummary && <div className={styles.content}>
          <ItemScanSummary itemScanSummary={scanSummary} />
        </div>}

        {Object.keys(orderItems).length > 0 &&
        <div className={`${styles.content} ${styles.margin}`}>
          {orderItems.oldestOrderItem && <p>Oldest Scanned Order Item (not completed): {orderItems.oldestOrderItem.toString()}</p>}
          {orderItems.lastCompleted && <p>Most Recently Completed Order Item: {orderItems.lastCompleted.toString()}</p>}
        </div>}

        {equipment.length > 0 &&
        <div>
          <EquipmentStatus equipment={equipment}/>
        </div>}
      </Page>
    )
  }
}

export default SplashView
