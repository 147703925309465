// @flow
import type { Action, AppState } from '../../types'
import { LOADING, ERROR, SUCCESS } from '../../data/rsaa'
import {
  FETCH_ORDER_ITEM_DETAILS,
  FETCH_ORDER_ITEM_DETAILS_SUCCESS,
  FETCH_ORDER_ITEM_DETAILS_ERROR,
  FETCH_EQUIPMENT_UPDATE,
  FETCH_EQUIPMENT_UPDATE_SUCCESS,
  FETCH_EQUIPMENT_UPDATE_ERROR,
  FETCH_SESSION_ORDER_ITEMS,
  FETCH_SESSION_ORDER_ITEMS_SUCCESS,
  FETCH_SESSION_ORDER_ITEMS_ERROR,
  FETCH_ITEM_SCAN_SUMMARY,
  FETCH_ITEM_SCAN_SUMMARY_SUCCESS,
  FETCH_ITEM_SCAN_SUMMARY_ERROR
} from '../../actions/splash'

export function splash (action: Action, state: AppState): ?string[] {
  switch (action.type) {
    case FETCH_ORDER_ITEM_DETAILS: {
      return [`orderItemDetails`, LOADING]
    }

    case FETCH_ORDER_ITEM_DETAILS_SUCCESS: {
      return [`orderItemDetails`, SUCCESS]
    }

    case FETCH_ORDER_ITEM_DETAILS_ERROR: {
      return [`orderItemDetails`, ERROR]
    }

    case FETCH_EQUIPMENT_UPDATE: {
      return ['equipmentUpdate', LOADING]
    }

    case FETCH_EQUIPMENT_UPDATE_SUCCESS: {
      return ['equipmentUpdate', SUCCESS]
    }

    case FETCH_EQUIPMENT_UPDATE_ERROR: {
      return ['equipmentUpdate', ERROR]
    }

    case FETCH_SESSION_ORDER_ITEMS: {
      return ['ordersBySession', LOADING]
    }

    case FETCH_SESSION_ORDER_ITEMS_SUCCESS: {
      return ['ordersBySession', SUCCESS]
    }

    case FETCH_SESSION_ORDER_ITEMS_ERROR: {
      return ['ordersBySession', ERROR]
    }

    case FETCH_ITEM_SCAN_SUMMARY: {
      return ['itemScanSummary', LOADING]
    }

    case FETCH_ITEM_SCAN_SUMMARY_SUCCESS: {
      return ['itemScanSummary', SUCCESS]
    }

    case FETCH_ITEM_SCAN_SUMMARY_ERROR: {
      return ['itemScanSummary', ERROR]
    }

    default: {
      return null
    }
  }
}
