// @flow
import React from 'react'
import RelatedForm from './components/RelatedForm'

type Props = {|
  className?: string,
  equipmentNames: string[],
  updateEquipmentChoice: (value: string) => void,
  addRelatedEquipment: (e: Event) => void,
  equipment: string,
  relatedEquipment: string[]
|}

export default function RelatedEquipmentForm (props: Props) {
  return (
    <RelatedForm
      names={props.equipmentNames}
      updateChoice={props.updateEquipmentChoice}
      addRelated={props.addRelatedEquipment}
      selected={props.equipment}
      related={props.relatedEquipment}
      className={props.className}
      pattern='^[0-9A-Za-z ]+$'
      patternTitle={'A-Z, a-z and 0-9 only'}
      headerText={'Add Related Equipment'}
      buttonText={'Add Equipment'}
      listText={'Equipment name'}
      listHeaderText={'Related equipment'} />
  )
}
