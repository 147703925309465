// @flow
import React from 'react'
import Button from '../../../components/button'
import styles from './Modal.module.css'
import Modal from '../../../components/modal'
import type { SessionActivitySearch, OrderScanCheck } from '../../../types'
import { mapScanModalMessage } from './mapScanModalMessage'

type Props = {|
  currentSession: string,
  scanAllOrderItems: boolean,
  activitySession: SessionActivitySearch,
  activitySessionDontMatch: boolean,
  orderScanCheck: OrderScanCheck,
  confirmScan: () => void,
  cancelScan: () => void
|}

export default function CheckModal (props: Props) {
  const { currentSession, scanAllOrderItems } = props

  const message = mapScanModalMessage(
    currentSession,
    scanAllOrderItems,
    props.activitySessionDontMatch,
    props.orderScanCheck,
    props.activitySession
  )

  return (
    <Modal close={props.cancelScan}>
      <div className={styles.modalContainer}>
        <h1 className={styles.modalHeader}>Scan check</h1>

        <div>
          {currentSession === ''
            ? <p>You are not scanned into a session.</p>
            : <p>You are currently scanned into {currentSession}.</p>}
          {message}
        </div>

        <div className={styles.modalButtonContainer}>
          <Button primary onClick={props.cancelScan} className={styles.modalButton}>No</Button>
          <Button primary onClick={props.confirmScan}>
            {scanAllOrderItems ? 'Yes, please continue' : 'Yes, please add this scan'}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
