// @flow
import React from 'react'
import type { ItemHistory } from '../../../../../../types'
import styles from './OrderHistory.module.css'
import { getDateTimeInLocalFromTimeStamp } from '../../../../../../helpers/date'
import cx from 'classnames'

type Props = {|
  history: ItemHistory,
  keyValue: number
|}

export default function OrderItemHistoryRow ({ history, keyValue }: Props) {
  const formatted = getDateTimeInLocalFromTimeStamp(history.timeStamp)

  return (
    <tr className={styles.row} key={keyValue}>
      <td className={cx(styles.data, styles.fixedWidth)}>{history.sessionName}</td>
      <td className={styles.data}>{formatted.time}</td>
      <td className={styles.data}>{formatted.date}</td>
      <td className={cx(styles.data, styles.name)}>{history.employeeName}</td>
      <td className={cx(styles.data, styles.fixedWidth)}>{history.note}</td>
    </tr>
  )
}
