// @flow
import { RSAA_ACTION_NAME } from '../data/rsaa'
import type { MiddlewareAPI, Dispatch } from 'redux'
import type { AppState, Action } from '../types'
import { getAccessToken } from '../actions/auth'
import { AUTH_HEADER_PREFIX } from '../data/constants'

export default function (store: MiddlewareAPI<AppState, Action>) {
  return function (next: Dispatch<Action>) {
    return async function (action: Action) {
      const rsaaAction = action[RSAA_ACTION_NAME]

      if (rsaaAction) {
        const token = getAccessToken()

        if (!token) {
          return next(action)
        }

        rsaaAction.headers = {
          ...rsaaAction.headers,
          Authorization: AUTH_HEADER_PREFIX + token || ''
        }
      }

      return next(action)
    }
  }
}
