// @flow
import { abortRequest } from '../abort'
import type { DispatchFunc, GetStateFunc } from '../../types'
import { clearTimeoutByIds } from './timeout'
import { SPLASH_ACTION_IDS } from '../../data/splash'

export function abortSplashRequests () {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const timeoutIds = [
      SPLASH_ACTION_IDS.ORDER_ITEM_DETAILS,
      SPLASH_ACTION_IDS.EQUIPMENT_DETAILS,
      SPLASH_ACTION_IDS.ITEM_SCAN_SUMMARY
    ]

    await dispatch(abortRequest('equipmentUpdate'))
    await dispatch(abortRequest('orderItemDetails'))
    await dispatch(abortRequest('itemScanSummary'))
    await dispatch(clearTimeoutByIds(timeoutIds))
  }
}
