// @flow

import React from 'react'
import LoginView from './LoginView'

type Props = {|
  onLogin: (username: string, password: string) => void,
  isUserLoggedIn: boolean,
  loading: boolean,
  errorMessage: string,
  redirect: (address: string) => void
|}

type State = {|
  username: string,
  password: string
|}

class LoginController extends React.Component<Props, State> {
  state = {
    username: '',
    password: ''
  }

  get redirectPath (): string {
    const params = window && new window.URLSearchParams(window.location.search)
    return (params && params.get('redirect')) || '/'
  }

  onSubmitLogin = (e: SyntheticInputEvent<HTMLInputElement>) => {
    e.preventDefault()
    this.props.onLogin(this.state.username, this.state.password)
  }

  updateUsername = (username: string) => this.setState({ username })

  updatePassword = (password: string) => this.setState({ password })

  render () {
    return (
      <LoginView
        isUserLoggedIn={this.props.isUserLoggedIn}
        loading={this.props.loading}
        errorMessage={this.props.errorMessage}
        redirectPath={this.redirectPath}
        onSubmitLogin={this.onSubmitLogin}
        updateUsername={this.updateUsername}
        username={this.state.username}
        updatePassword={this.updatePassword}
        password={this.state.password} />
    )
  }
}

export default LoginController
