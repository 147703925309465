// @flow
import { RSAA_ACTION_NAME } from '../data/rsaa'
import { STATUS_CODES } from '../data/statusCodes'
import { sessionExpired, REFRESH_ACCESS_TOKEN } from '../actions/auth'
import type { MiddlewareAPI, Dispatch } from 'redux'
import type { Response, AppState, Action, RequestOptions, DispatchFunc, FetchFunc } from '../types'

export default function (store: MiddlewareAPI<AppState, Action>) {
  return function (next: Dispatch<Action>) {
    return async function (action: Action) {
      const rsaaAction = action[RSAA_ACTION_NAME]

      if (rsaaAction && isRefreshAccessTokenAction(rsaaAction.types)) {
        rsaaAction.fetch = fetchWithSessionValidation(rsaaAction.fetch || window.fetch, store.dispatch)
      }

      return next(action)
    }
  }
}

function fetchWithSessionValidation (fetchFunc: FetchFunc<*>, dispatch: DispatchFunc) {
  return async (url: string, options: RequestOptions) => {
    const result = await fetchFunc(url, options)

    if (isUnauthorisedRequest(result) || isGone(result)) {
      return dispatch(sessionExpired())
    }

    return result
  }
}

function isRefreshAccessTokenAction (actionTypes: string[]): boolean {
  return actionTypes[0] === REFRESH_ACCESS_TOKEN
}

function isUnauthorisedRequest (result: Response<*>): boolean {
  return result && result.status === STATUS_CODES.UNAUTHORIZED
}

function isGone (result: Response<*>): boolean {
  return result && result.status === STATUS_CODES.GONE
}
