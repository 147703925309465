// @flow
import React, { Component } from 'react'
import type { Dictionary, BarcodeFeedback } from '../../../../types'
import styles from '../Manage.module.css'
import AddEquipment from './AddEquipment'
import Feedback from '../../../../components/layout/Feedback'

type Props = {|
  labs: Dictionary<string>,
  activityNames: string[],
  equipmentNames: string[],
  updateAdd: (value: string) => void,
  updateRemove: (value: string) => void,
  updateActivityChoice: (value: string) => void,
  updateLabAlias: (value: string) => void,
  addEquipmentToDatabase: (e: any) => void,
  removeEquipmentFromDatabase: (e: any) => void,
  addRelatedActivity: (e: any) => void,
  removeEquipment: string,
  addEquipment: string,
  feedback: BarcodeFeedback,
  activity: string,
  relatedActivities: string[],
  labAlias: string
|}

class EquipmentView extends Component<Props> {
  render () {
    const { feedback } = this.props
    return (
      <div>
        {feedback && feedback.message !== '' &&
        <div className={styles.content}>
          <Feedback message={feedback.message} hasScanError={feedback.hasError} barcodes={feedback.barcodes}/>
        </div>}

        <AddEquipment
          labs={this.props.labs}
          equipment={this.props.addEquipment}
          addEquipment={this.props.addEquipmentToDatabase}
          updateAdd={this.props.updateAdd}
          activityNames={this.props.activityNames}
          updateActivityChoice={this.props.updateActivityChoice}
          addRelatedActivity={this.props.addRelatedActivity}
          activity={this.props.activity}
          relatedActivities={this.props.relatedActivities}
          updateLabAlias={this.props.updateLabAlias}
          labAlias={this.props.labAlias} />

        {/* <RemoveForm
          removeFromDatabase={this.props.removeEquipmentFromDatabase}
          removeValue={this.props.removeEquipment}
          updateRemove={this.props.updateRemove}
          names={this.props.equipmentNames}
          pattern='^[0-9A-Za-z ]+$'
          title={'{A-Z, a-z and 0-9 only'}
          heading={'Remove Equipment'}
          label={'Equipment Name'} /> */}
      </div>
    )
  }
}

export default EquipmentView
