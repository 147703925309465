// @flow
import React from 'react'
import styles from '../../Manage.module.css'
import SelectField from '../../../../../components/input/SelectField'
import { EQUIPMENT, SESSION } from '../../../../../data/scan'

type Props = {|
  updateType: (e: Event) => void,
  hasSessionNames?: boolean,
  hasEquipmentNames?: boolean,
  type: string
|}

export default function SelectType ({ type, updateType, hasSessionNames = true, hasEquipmentNames = true }: Props) {
  return (
    <div className={`${styles.inputGroup} ${styles.typePadding} `}>
      <header className={styles.inputGroupHeader}>
        <label>Type</label>
      </header>
      <SelectField value={type} onChange={updateType}>
        {hasSessionNames && <option value={SESSION}>Session</option>}
        {hasEquipmentNames && <option value={EQUIPMENT}>Equipment</option>}
      </SelectField>
    </div>
  )
}
