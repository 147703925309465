// @flow

import * as React from 'react'
import cx from 'classnames'
import NavigationItem from './NavigationItem'
import styles from './Navigation.module.css'
import NavigationHeader from './NavigationHeader'

type Props = {|
  isMobileExpanded: boolean,
  onClickMobileToggle: () => void,
  onClickSignOut: () => void,
  onShouldCollapseMobile: (name: string) => void,
  children: React.Node,
  activeLink: string,
  isAdmin: boolean
|}

function NavigationLayout (props: Props) {
  return (
    <div className={styles.container}>
      <nav className={cx(styles.panel, {
        [styles.panelMobileExpanded]: props.isMobileExpanded
      })}>
        <NavigationHeader
          onClickMobileToggle={props.onClickMobileToggle}
          onShouldCollapseMobile={props.onShouldCollapseMobile}
          isMobileExpanded={props.isMobileExpanded}
          isAdmin={props.isAdmin}
        />

        <ul className={styles.list}>
          {props.isAdmin &&
            <React.Fragment>
              <li>
                <NavigationItem title='Splash Screen' iconName='home' path='/splash' onClick={() => props.onShouldCollapseMobile('Splash')} />
              </li>
              <li>
                <NavigationItem title='Search' iconName='search' path='/search' onClick={() => props.onShouldCollapseMobile('Search')} />
              </li>
              <li>
                <NavigationItem title='Manage Database' iconName='cog' path='/manage' onClick={() => props.onShouldCollapseMobile('Manage')} />
              </li>
              {!props.isMobileExpanded && <div className={styles.subDivider} />}
            </React.Fragment>}
          <li>
            <NavigationItem title='Scan' iconName='barcode' path='/scan' onClick={() => props.onShouldCollapseMobile('Scan')} />
          </li>
          <li>
            <NavigationItem title='Dashboard' iconName='tv' path='/dashboard' onClick={() => props.onShouldCollapseMobile('Dashboard')} />
          </li>

          <div className={styles.divider} />
          <li>
            <NavigationItem title='Sign out' iconName='sign-out' path='/login' onClick={props.onClickSignOut} />
          </li>
        </ul>
      </nav>

      <div className={
        cx(styles.content, {
          [styles.contentHidden]: props.isMobileExpanded
        })
      }>
        {props.children}
      </div>
    </div>
  )
}

export default NavigationLayout
