// @flow

export const RESET_APP_STATE = 'RESET_APP_STATE'
export const RESET_FEEDBACK = 'RESET_FEEDBACK'

export function resetAppState () {
  return {
    type: RESET_APP_STATE
  }
}

export function resetFeedback () {
  return {
    type: RESET_FEEDBACK
  }
}
