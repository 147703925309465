// @flow
import React from 'react'
import RelatedForm from './components/RelatedForm'

type Props = {|
  activityNames: string[],
  updateActivityChoice: (value: string) => void,
  addRelatedActivity: (e: Event) => void,
  activity: string,
  relatedActivities: string[]
|}

export default function RelatedActivityForm (props: Props) {
  return (
    <RelatedForm
      names={props.activityNames}
      updateChoice={props.updateActivityChoice}
      addRelated={props.addRelatedActivity}
      selected={props.activity}
      related={props.relatedActivities}
      pattern='^[A-Za-z /]+$'
      patternTitle='A-Z, a-z and / only'
      headerText={'Add Related Activities'}
      buttonText={'Add Activity'}
      listText={'Activity name'}
      listHeaderText={'Related activities'} />
  )
}
